/* eslint-disable @typescript-eslint/no-explicit-any */
import { v4 as uuidv4 } from 'uuid'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useAuth } from '@/core/context'
import { useApi, useTokenReset } from '@/core/hooks'
import { EEndpoint, IServices } from '@/core/api'

import { Icon } from '@/components/ui'
import { DataLoading, NoDataAvailable } from '@/components/smart'

import './style.scss'

export const Services = () => {
  const servicesRef = useRef<HTMLUListElement>(null)
  const navigate = useNavigate()
  const { apiRequest } = useApi()
  const { user }: any = useAuth()
  const { resetToken, resettingToken } = useTokenReset()

  const [services, setServices] = useState<IServices[]>([])
  const [filterableServices, setFilterableServices] = useState<IServices[]>([])
  const [isLoading, setLoading] = useState<boolean>(false)
  const [inputSearchName, setInputValue] = useState('')

  const onFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setInputValue(value)
    setFilterableServices(
      services.filter(
        (service) =>
          service.serviceName.toLowerCase().indexOf(value.toLowerCase()) > -1,
      ),
    )
  }

  const getServicesCall = async () => {
    try {
      setLoading(true)
      const response = await apiRequest({
        endpoint: EEndpoint.services,
        options: {
          headers: {
            'transaction-id': uuidv4(),
            Authorization: `${user.TokenType} ${user.AccessToken}`,
          },
          method: 'GET',
        },
      })
      console.log(response)
      setServices(response)
    } catch (error: any) {
      if (error.message.match(/403|User is not authorized/gm)) resetToken()
      else throw new Error(error.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getServicesCall()
  }, [resettingToken])

  useEffect(() => {
    if (!servicesRef.current) {
      return
    }

    if (servicesRef.current.scrollHeight > servicesRef.current.clientHeight) {
      servicesRef.current.classList.add('has-scroll')
    } else {
      servicesRef.current.classList.remove('has-scroll')
    }
  }, [services.length])

  const ServiceItem = ({ services }: { services: IServices[] }) => {
    return (
      <>
        {services.map((service) => (
          <li
            className="services-list-item"
            key={service.serviceId}
            onClick={() =>
              navigate(`./${service.serviceName}`, {
                state: {
                  service: {
                    ...services.find(
                      (item) => item.serviceName === service.serviceName,
                    ),
                  },
                },
              })
            }
          >
            <Icon icon={'services'} size="60px" />
            <div className="title">{service.serviceName}</div>
            <div className="description" title={service.description}>
              {service.description}
            </div>
          </li>
        ))}
      </>
    )
  }

  return (
    <div className="services">
      <div className="services-gradient-block">
        <div className="title">What service do you need?</div>
        <div className="search-input-group">
          <Icon
            viewBox="0 0 20 20"
            icon={'search'}
            size="20px"
            className={'icon'}
          />
          <input
            type="search"
            placeholder="Search"
            value={inputSearchName}
            onChange={onFilterChange}
          />
        </div>
      </div>
      <ul className="services-list" ref={servicesRef}>
        <NoDataAvailable
          isConditional={!isLoading && !services.length}
          text={'No results found'}
        />
        <DataLoading isLoading={isLoading} />

        {services.length && inputSearchName !== '' ? (
          <ServiceItem services={filterableServices} />
        ) : (
          <ServiceItem services={services} />
        )}
      </ul>
    </div>
  )
}
