import { useState } from 'react'

import { Icon } from '@/components/ui'

import './style.scss'

interface IPopoverProps {
  triggerIcon?: JSX.Element
  triggerText: string

  headerText?: string
  children: JSX.Element | string
}
export const Popover = ({
  children,
  triggerIcon,
  triggerText,
  headerText,
}: IPopoverProps) => {
  const [isOpen, setOpen] = useState(false)
  const popoverOpenHandler = () => {
    setOpen(true)
  }
  const popoverCloseHandler = () => {
    setOpen(false)
  }

  return (
    <div className="popover">
      <button
        type="button"
        className="popover-trigger"
        onClick={popoverOpenHandler}
      >
        {triggerIcon ? triggerIcon : null}
        {triggerText}
      </button>
      {isOpen && (
        <div className="popover-content">
          <button className="popover__close" onClick={popoverCloseHandler}>
            <Icon viewBox="0 0 24 24" size={'16px'} icon={'close'} />
          </button>
          {headerText && <div className="popover-header">{headerText}</div>}
          <div className="popover-body">{children}</div>
        </div>
      )}
    </div>
  )
}
