/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  Table,
  TableOptions,
  useReactTable,
} from '@tanstack/react-table'

import { useAuth } from '@/core/context'
import { useApi, useTokenReset } from '@/core/hooks'
import {
  EEndpoint,
  EAnalyticsApiPathType,
  TAnalyticsGeneralServicesResponse,
} from '@/core/api'

import { Dropdown, IDropdownData, Icon } from '@/components/ui'
import { Filter } from './Filter'

import './style.scss'

type useReactTable = <TData extends TAnalyticsGeneralServicesResponse[]>(
  options: TableOptions<TData | []>,
) => Table<TData>

const servicesDropdownData = [
  {
    text: 'Last 30 days',
    value: 'month',
  },
  {
    text: 'Last week',
    value: 'week',
  },
  {
    text: 'Yesterday',
    value: 'day',
  },
]

export const RegisteredServices = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { user }: any = useAuth()
  const { apiRequest } = useApi()
  const { resetToken, resettingToken } = useTokenReset()

  const [isLoading, setLoading] = useState<boolean>(false)
  const [servicesDropdownValue, setServicesDropdownValue] = useState(
    servicesDropdownData[0],
  )
  const [servicesData, setServicesData] = useState<
    TAnalyticsGeneralServicesResponse[] | null
  >([])
  const [columnFilters, setColumnFilters] = useState<
    { id: string; value: string }[]
  >([])

  const columns: ColumnDef<TAnalyticsGeneralServicesResponse>[] = [
    {
      accessorKey: 'serviceName',
      header: 'Service Name',
      cell: (props: any) => {
        return (
          <>
            <div className="service">
              <div className="service__asset">
                <img
                  src={require('@/assets/images/dashboard/service-1.png')}
                  alt="service"
                />
              </div>
              <div className="service__content">
                <div className="service__title">
                  {props.row.original.serviceName}
                </div>
                <div className="service__desc">
                  {props.row.original.desc.split('. ')[0]}
                </div>
              </div>
            </div>
          </>
        )
      },
    },
    {
      accessorKey: 'stats.totalNumberOfRequest',
      header: 'Total',
      size: 100,
      cell: (props: any) => {
        return <p>{props.getValue()}</p>
      },
    },
    {
      accessorKey: 'stats.failedRequests',
      header: 'Failed',
      size: 100,
      cell: (props: any) => <p>{props.getValue()}</p>,
    },
    {
      accessorKey: 'stats.succsesRequests',
      size: 100,
      header: 'Success',
      cell: (props: any) => <p>{props.getValue()}</p>,
    },
  ]

  const table = useReactTable({
    data: servicesData as TAnalyticsGeneralServicesResponse[],
    columns,
    state: { columnFilters },
    getFilteredRowModel: getFilteredRowModel(),
    getCoreRowModel: getCoreRowModel(),
  })

  const getGeneralServicesHandler = async () => {
    try {
      setLoading(true)
      const response = (await apiRequest({
        endpoint: EEndpoint.analytics,
        path: EAnalyticsApiPathType.generalServices,
        options: {
          headers: {
            'transaction-id': uuidv4(),
            Authorization: `${user.TokenType} ${user.AccessToken}`,
          },
          method: 'GET',
        },
      })) as TAnalyticsGeneralServicesResponse[]
      if (response) {
        console.log('response: ', response)
        setServicesData(response)
        // setServicesData(null)
        return response
      }
    } catch (error: any) {
      if (error.message.match(/403|User is not authorized/gm)) resetToken()
      else console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const dropdownClickHandler = (item: IDropdownData) => {
    setServicesDropdownValue(item)
  }

  useEffect(() => {
    getGeneralServicesHandler()
  }, [resettingToken])

  // console.log('columnFilters state: ', columnFilters)

  return (
    <div className="registered-services">
      <div className="registered-services-header">
        <div className="registered-services__title">Registered Services</div>
        <div className="registered-services__filter">
          <Filter
            columnFilters={columnFilters}
            setColumnFilters={setColumnFilters}
          />

          <Dropdown
            header={servicesDropdownValue.text}
            data={servicesDropdownData}
            onClick={dropdownClickHandler}
            hasAngle
            isSelect
          />

          {/* <select name="" id="">
            <option value="days">test</option>
          </select> */}
        </div>
      </div>
      <div className="registered-services-content">
        {!servicesData ? (
          <div className="transaction-activity-nodata">
            <Icon viewBox="0 0 24 24" icon={'cloudOff'} size="90px" />
            <span>No data available</span>
          </div>
        ) : (
          <div className="table-wrapper">
            {/* style={{ width: table.getTotalSize() }} */}
            <div className="table">
              {table.getHeaderGroups().map((headerGroup) => (
                <div className="tr" key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <div
                      className="th"
                      key={header.id}
                      style={{ width: header.getSize() }}
                    >
                      {header.column.columnDef.header as string}
                    </div>
                  ))}
                </div>
              ))}
            </div>

            {isLoading ? (
              <div className="transaction-activity-loading overview-component-loading">
                <Icon viewBox="0 0 24 24" icon={'cached'} size="90px" />
                <span>Data Loading</span>
              </div>
            ) : (
              <div className="table-body">
                {table.getRowModel().rows.map((row) => (
                  <div className="tr" key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <div
                        className="td"
                        key={cell.id}
                        style={{ width: cell.column.getSize() }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
