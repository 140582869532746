/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { v4 as uuidv4 } from 'uuid'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ColumnDef } from '@tanstack/react-table'

import { useApi, useTokenReset } from '@/core/hooks'
import { useAuth, useModal } from '@/core/context'
import { EEndpoint, EUserApiPathType, IRole, IUser } from '@/core/api'
import { convertCamelCaseToWords } from '@/core/utils'

import { Button, Icon } from '@/components/ui'
import { BackArrow, IndeterminateCheckbox } from '@/components/simple'
import { ConfirmationModal, EConfirmationModalType } from '@/components/smart'

import { TanstackTable } from './TanstackTable'
import { RegisterNewUser } from './RegisterNewUser'
import { AssignUserRole } from './AssignUserRole'

import './style.scss'

export const UserManagement = () => {
  const navigate = useNavigate()
  const { user }: any = useAuth()
  const { apiRequest } = useApi()
  const { setModal } = useModal()
  const { resetToken, resettingToken } = useTokenReset()

  const [isUsersChanged, setUsersChange] = useState<boolean>(false)
  const [isLoading, setLoading] = useState<boolean>(false)
  const [usersList, setUserList] = useState<IUser[]>([])
  const [columns, setColumns] = useState<ColumnDef<IUser>[]>([])
  const [roles, setRoles] = useState<IRole[]>([])

  const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({})

  const getUsersApiCall = async () => {
    try {
      setLoading(true)
      const response = (await apiRequest({
        getBaseUrl: true,
        endpoint: EEndpoint.user,
        path: EUserApiPathType.users,
        options: {
          headers: {
            'transaction-id': uuidv4(),
            Authorization: `${user.TokenType} ${user.AccessToken}`,
          },
          method: 'GET',
        },
      })) as { users: IUser[] }
      if (response) {
        console.log(response)
        const { users } = response

        const _users = [...users].map((item) => ({
          ...item,
          status:
            item.email_verified === 'true' ? 'Registered' : 'Not Registered',
        }))

        setUserList([..._users])
        setColumns([
          {
            id: 'select',
            header: ({ table }) => {
              return (
                <IndeterminateCheckbox
                  {...{
                    checked: table.getIsAllPageRowsSelected(),
                    indeterminate: table.getIsSomePageRowsSelected(),
                    onChange: table.getToggleAllPageRowsSelectedHandler(),
                    name: 'select',
                  }}
                />
              )
            },
            cell: ({ row }) => {
              return (
                <IndeterminateCheckbox
                  {...{
                    checked: row.getIsSelected(),
                    disabled: !row.getCanSelect(),
                    indeterminate: row.getIsSomeSelected(),
                    onChange: row.getToggleSelectedHandler(),
                  }}
                />
              )
            },
          },
          ...Object.keys([..._users][0]).map((item) => {
            if (item !== 'status') {
              return {
                accessorKey: item,
                header: convertCamelCaseToWords(item),
                cell: (props: any) => {
                  return <>{props.getValue()}</>
                },
              }
            } else {
              return {
                accessorKey: item,
                header: 'Status',
                cell: (props: any) => {
                  switch (props.getValue()) {
                    case 'Not Registered':
                      return (
                        <div className={'info-status info-status--pending'}>
                          Not Registered
                        </div>
                      )
                    case 'Registered':
                      return (
                        <div className={'info-status info-status--ready'}>
                          Registered
                        </div>
                      )
                    default:
                      return <>{props.getValue()}</>
                  }
                },
              }
            }
          }),
        ])
      }
    } catch (error: any) {
      if (error.message.match(/403|User is not authorized/gm)) resetToken()
      else console.log(error.message)
    } finally {
      setLoading(false)
    }
  }

  const getUserRoles = async () => {
    try {
      const response = await apiRequest({
        getBaseUrl: true,
        endpoint: EEndpoint.user,
        path: EUserApiPathType.roles,
        options: {
          headers: {
            'transaction-id': uuidv4(),
            Authorization: `${user.TokenType} ${user.AccessToken}`,
          },
          method: 'GET',
        },
      })
      if (response) {
        const { roles } = response
        setRoles(roles)
      }
    } catch (error: any) {
      if (error.message.match(/403|User is not authorized/gm)) resetToken()
      else console.log(error.message)
    }
  }

  useEffect(() => {
    getUserRoles()
    getUsersApiCall()
  }, [resettingToken])

  useEffect(() => {
    if (!isUsersChanged) return
    getUsersApiCall()
    setUsersChange(false)
    setRowSelection({})
  }, [isUsersChanged])

  useEffect(() => {
    console.log(getRowSelectionData(rowSelection))
  }, [rowSelection])

  const getRowSelectionData = (rowSelection: Record<string, boolean>) => {
    return Object.keys(rowSelection).map(
      (item) => usersList![parseInt(item)],
    )[0]
  }

  const addUserClickHandler = () => {
    setModal(
      <RegisterNewUser
        setUsersChange={setUsersChange}
        users={usersList.map((item) => ({
          username: item.Username,
          email: item.email,
          phone_number: item.phone_number,
        }))}
      />,
    )
  }

  const deleteUserClickHandler = () => {
    setModal(
      <ConfirmationModal
        icon={
          <Icon
            icon={'consfirmationModalManagement'}
            viewBox="0 0 71 89"
            size={'71px'}
          />
        }
        type={EConfirmationModalType.delete}
        title={'Are you sure you wanna delete this user?'}
        description={'This user will be remove'}
        actionHandler={deleteUserHandler}
      />,
    )
  }

  const assignClickHandler = () => {
    setModal(
      <AssignUserRole
        user={getRowSelectionData(rowSelection)}
        roles={roles}
        setUsersChange={setUsersChange}
      />,
    )
  }

  const deleteUserHandler = async () => {
    const { sub } = getRowSelectionData(rowSelection)
    try {
      await apiRequest({
        endpoint: EEndpoint.user,
        path: `${sub}`,
        options: {
          headers: {
            'transaction-id': uuidv4(),
            Authorization: `${user.TokenType} ${user.AccessToken}`,
          },
          method: 'DELETE',
        },
      })
      setUsersChange(true)
    } catch (error: any) {
      console.log(error.message)
    }
  }

  return (
    <div className="user-management">
      <div className="user-management__back">
        <Button onClick={() => navigate(-1)}>
          <BackArrow />
        </Button>
      </div>

      <div className="user-management__title">User Listing</div>

      <div className="user-management-controls">
        <Button onClick={addUserClickHandler} disabled={isLoading}>
          +
        </Button>
        <Button
          onClick={deleteUserClickHandler}
          disabled={!Object.keys(rowSelection).length}
        >
          Delete
        </Button>
        <Button
          disabled={!Object.keys(rowSelection).length}
          onClick={assignClickHandler}
        >
          Assign
        </Button>
        <Button disabled={!Object.keys(rowSelection).length}>Unregister</Button>
      </div>

      <TanstackTable
        isLoading={isLoading}
        tableData={usersList}
        columns={columns}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
      />
    </div>
  )
}
