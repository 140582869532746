import { SyntheticEvent } from 'react'
import { icons } from './icons'

export enum EIconSize {
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
}
export enum EIconGroup {
  icon1 = 'icon-1',
  icon2 = 'icon-2',
  icon3 = 'icon-3',
  icon4 = 'icon-4',
}
export enum EIconType {
  stroke = 'stroke',
  fill = 'fill',
  fillAndStroke = 'fillAndStroke',
}

export type TIcon = {
  icon: keyof typeof icons
  viewBox?: string
  size?: string
  color?: string
  onClick?: (e: SyntheticEvent<SVGSVGElement>) => void
  className?: string
}
