import { ChangeEvent, useState } from 'react'

import { useCreateToken } from '@/core/context'

import { Button } from '@/components/ui'

import './style.scss'
import { IToken } from '@/core/api'

export const SetDescription = () => {
  const { setCreationStep, setCreateTokenData, creationStep, createTokenData } =
    useCreateToken()

  const [description, setDescription] = useState<string>(
    createTokenData.description ? createTokenData.description : '',
  )

  const onTextareaChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setDescription(event.target.value)
  }

  const setTokenDescriptionHandler = () => {
    setCreateTokenData((prev: IToken) => ({
      ...prev,
      description,
    }))
    setCreationStep(creationStep + 1)
  }

  return (
    <>
      <div className="token-create-data-container-header">
        <div className="token-create-data-container-header__title">
          Choose description
        </div>
        <div className="token-create-data-container-header__desc">
          to create new token
        </div>
      </div>
      <div className="token-create-data-container-main">
        <div className="token-create-step token-create-step--description">
          <form>
            <fieldset>
              <legend>Type description</legend>
              <textarea
                name="proposition"
                id="proposition"
                placeholder=""
                value={description}
                onChange={onTextareaChange}
              ></textarea>
            </fieldset>
          </form>
        </div>
      </div>
      <div className="token-create-data-container-footer">
        <Button
          disabled={!description.length}
          onClick={setTokenDescriptionHandler}
        >
          Next
        </Button>
      </div>
    </>
  )
}
