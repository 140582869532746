/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ColumnDef,
  SortingState,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { useState } from 'react'

import { IUser } from '@/core/api'

import { DataLoading } from '@/components/smart'

import './style.scss'

interface ITanstackTableProps {
  isLoading: boolean
  tableData: IUser[]
  columns: ColumnDef<IUser>[]
  rowSelection: any
  setRowSelection: any
}

export const TanstackTable = ({
  isLoading,
  tableData,
  columns,
  rowSelection,
  setRowSelection,
}: ITanstackTableProps) => {
  const [sorting, setSorting] = useState<SortingState>([])

  const table = useReactTable({
    data: tableData ? (tableData as IUser[]) : [],
    columns,
    state: {
      sorting,
      rowSelection,
    },
    getCoreRowModel: getCoreRowModel(),

    enableSorting: true,
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),

    enableRowSelection: true,
    enableMultiRowSelection: false,
    onRowSelectionChange: setRowSelection,

    initialState: {
      pagination: {
        pageSize: 10,
      },
    },
    getPaginationRowModel: getPaginationRowModel(),
  })

  return (
    <>
      <div className="table-wrapper">
        {!!tableData?.length && (
          <table>
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <th
                        className={`${header.id}`}
                        key={header.id}
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        {flexRender(
                          header.column.columnDef.header as string,
                          header.getContext(),
                        )}
                        {{
                          asc: ' ↑',
                          desc: ' ↓',
                        }[header.column.getIsSorted() as string] ?? null}
                      </th>
                    )
                  })}
                </tr>
              ))}
            </thead>
            <tbody className={`${isLoading ? 'table-body--loading' : ''}`}>
              {table.getRowModel().rows.map((row) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td
                      className={`${cell.id.slice(2)}`}
                      key={cell.id}
                      style={{ width: cell.column.getSize() }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        )}

        <DataLoading isLoading={isLoading} />
      </div>
      <div className="table-pagination">
        <button
          aria-label="Go to first page"
          onClick={() => table.setPageIndex(0)}
          disabled={!table.getCanPreviousPage()}
        >
          {'<<'}
        </button>
        <button
          aria-label="Go to previous page"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          {'<'}
        </button>
        <button
          aria-label="Go to next page"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          {'>'}
        </button>
        <button
          aria-label="Go to last page"
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          disabled={!table.getCanNextPage()}
        >
          {'>>'}
        </button>
      </div>
    </>
  )
}
