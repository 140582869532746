import { Icon } from '@/components/ui'
import './style.scss'
interface INoDataAvailableProps {
  isConditional: boolean
  icon?: JSX.Element | null
  text?: JSX.Element | string
}

export const NoDataAvailable = ({
  isConditional,
  icon = (
    <>
      <Icon viewBox="0 0 24 24" icon={'cloudOff'} size="90px" />
    </>
  ),
  text = 'No data available',
}: INoDataAvailableProps) => {
  return (
    <>
      {isConditional && (
        <div className="is-nodata-available">
          {icon && icon}
          <span>{text}</span>
        </div>
      )}
    </>
  )
}
