import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

import {
  AuthProvider,
  CreateTokenProvider,
  ModalProvider,
} from '@/core/context'

import App from '@/App'

import './assets/styles/index.scss'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <BrowserRouter>
    <AuthProvider>
      <ModalProvider>
        <CreateTokenProvider>
          <App />
        </CreateTokenProvider>
      </ModalProvider>
    </AuthProvider>
  </BrowserRouter>,
)
