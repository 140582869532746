import { Navigate, Outlet, useMatch, useNavigate } from 'react-router-dom'
import { useAuth } from '@/core/context'
import { ChatAI, Main, Menu } from '@/pages/Dashboard'

import './style.scss'
import { useEffect, useState } from 'react'
// import { Overview, Tokens } from '@/pages/Dashboard/components/Main/screens'
export const DashboardLayout = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { user }: any = useAuth()
  const navigate = useNavigate()
  // const location = useLocation()
  const isDashboardOverview = useMatch('/dashboard/overview')
  const isDashboardManagement = useMatch('/dashboard/management')
  const isDashboardTokens = useMatch('/dashboard/tokens')
  const isDashboardTokensList = useMatch('/dashboard/tokens/list')
  const isDashboardTokenCreate = useMatch('/dashboard/tokens/list/create')
  const isDashboardServices = useMatch('/dashboard/services/*')
  // const outlet = useOutlet()

  if (!user) {
    return <Navigate to="/login" />
  }

  const [activeMenuIndex, setActiveMenuIndex] = useState(0)

  const menuChangeHandler = (index: number) => {
    setActiveMenuIndex(index)
    switch (index) {
      case 0:
        navigate('/dashboard/overview')
        break
      case 1:
        navigate('/dashboard/management')
        break
      case 2:
        navigate('/dashboard/tokens')
        break
      case 3:
        navigate('/dashboard/services')
        break
      default:
        navigate('/dashboard/overview')
    }
  }

  useEffect(() => {
    if (isDashboardOverview) {
      setActiveMenuIndex(0)
    }
    if (isDashboardManagement) {
      setActiveMenuIndex(1)
    }
    if (isDashboardTokens || isDashboardTokensList || isDashboardTokenCreate) {
      setActiveMenuIndex(2)
    }
    if (isDashboardServices) {
      setActiveMenuIndex(3)
    }
  }, [])

  // const mainContentRender = () => {
  //   switch (activeMenuIndex) {
  //     case 0:
  //       return <Overview />
  //     case 1:
  //       return <>User Management</>
  //     case 2:
  //       return <Tokens/>
  //     case 3:
  //       return <>Services</>
  //   }
  // }

  return (
    <div className="dashboard">
      <Menu activeIndex={activeMenuIndex} onChangeMenu={menuChangeHandler} />
      {/* <Main userName={user.UserName}>{mainContentRender()}</Main> */}
      <Main userName={user.UserName}>
        <Outlet context={activeMenuIndex} />
      </Main>
      <ChatAI />
    </div>
  )
}
