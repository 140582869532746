/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { useAuth } from '@/core/context'
import { useApi, useTokenReset } from '@/core/hooks'
import {
  EEndpoint,
  EAnalyticsApiPathType,
  TAnalyticsGeneralTransactionsResponse,
} from '@/core/api'
import { getFormatDate } from '@/core/utils'

import { Dropdown, IDropdownData, Icon } from '@/components/ui'

import { BarChart } from '../BarChart'
import './style.scss'

// import { barData } from '../mock'

enum EFilterKey {
  dailyStats = 'dailyStats',
  weeklyStats = 'weeklyStats',
  monthlyStats = 'monthlyStats',
}

interface IChartData {
  labels: string[]
  data: number[]
}

const transactionDropdownData = [
  {
    text: 'Daily',
    value: EFilterKey.dailyStats,
  },
  {
    text: 'Weekly',
    value: EFilterKey.weeklyStats,
  },
  {
    text: 'Monthly',
    value: EFilterKey.monthlyStats,
  },
]

export const TransactionsActivity = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { user }: any = useAuth()
  const { apiRequest } = useApi()
  const { resetToken, resettingToken } = useTokenReset()

  const [isLoading, setLoading] = useState<boolean>(false)
  const [transactionData, setTransactionData] =
    useState<TAnalyticsGeneralTransactionsResponse | null>(null)

  const [selectValue, setSelectValue] = useState<IDropdownData>(
    transactionDropdownData[0],
  )

  const [chartData, setChartData] = useState<IChartData>({
    labels: [],
    data: [],
  })

  useEffect(() => {
    if (!transactionData) return

    let format: string

    switch (selectValue.value) {
      case EFilterKey.dailyStats:
        format = 'd.MM.yy'
        break
      case EFilterKey.weeklyStats:
        format = 'wo yy'
        break
      case EFilterKey.monthlyStats:
        format = 'MMM yy'
        break

      default:
        format = 'd.MM.yy'
        break
    }

    const currentData = [
      ...transactionData[selectValue.value as EFilterKey],
    ].map((item) => ({
      date: getFormatDate(new Date(item.date), format),
      count: item.count,
    }))

    console.log('currentData: ', currentData)

    setChartData({
      labels: currentData.map((item) => item.date),
      data: currentData.map((item) => item.count),
    })
  }, [transactionData, selectValue.value])

  const getGeneralTransactionHandler = async () => {
    try {
      setLoading(true)
      const response = (await apiRequest({
        endpoint: EEndpoint.analytics,
        path: EAnalyticsApiPathType.generalTransactions,
        options: {
          headers: {
            'transaction-id': uuidv4(),
            Authorization: `${user.TokenType} ${user.AccessToken}`,
          },
          method: 'GET',
        },
      })) as TAnalyticsGeneralTransactionsResponse
      if (response) {
        console.log('response: ', response)
        setTransactionData(response)
        return response
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.message.match(/403|User is not authorized/gm)) resetToken()
      else console.log(error)
    } finally {
      setLoading(false)
    }
  }

  console.log(transactionData)

  useEffect(() => {
    getGeneralTransactionHandler()
  }, [resettingToken])

  const dropdownClickHandler = (item: IDropdownData) => {
    setSelectValue(item)
  }

  return (
    <div className="transaction-activity">
      <div className="transaction-activity-header">
        <div className="transaction-activity__title">
          Overall Transactions Activity
        </div>
        <div className="transaction-activity__filter">
          <Dropdown
            header={selectValue.text}
            data={transactionDropdownData}
            onClick={dropdownClickHandler}
            hasAngle
            isSelect
          />
        </div>
      </div>
      <div className="transaction-activity-content">
        {isLoading ? (
          <div className="transaction-activity-loading overview-component-loading">
            <Icon viewBox="0 0 24 24" icon={'cached'} size="90px" />
            <span>Data Loading</span>
          </div>
        ) : !transactionData ? (
          <div className="transaction-activity-nodata">
            <Icon viewBox="0 0 24 24" icon={'cloudOff'} size="90px" />
            <span>No data available</span>
          </div>
        ) : (
          <BarChart labels={chartData.labels} data={chartData.data} />
        )}
      </div>
    </div>
  )
}
