/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback } from 'react'
import { apiEndpoints, TUserApiPayload } from '../api'

export const useApi = () => {
  const apiRequest = useCallback(
    async ({
      endpoint,
      path,
      options,
      params,
      getBaseUrl,
    }: TUserApiPayload) => {
      const _endpoint = getBaseUrl
        ? apiEndpoints.find((item) => item.key === endpoint)?.baseUrl
        : apiEndpoints.find((item) => item.key === endpoint)?.getUrl()

      let url = ''

      if (!endpoint) {
        throw new Error('endpoint is not defined!')
      }

      url = `${_endpoint}/${path ? path : ''}${
        params ? `?${new URLSearchParams(params).toString()}` : ''
      }`
      console.log(url)

      try {
        const response = await fetch(url, options)

        if (response && response.status === 200) {
          return await response.json()
        } else {
          const { message, status } = await response.json()

          throw new Error(`${response.status}: ${message ? message : status}`)
        }
      } catch (error: any) {
        throw new Error(error.message)
      }
    },
    [],
  )

  return { apiRequest }
}
