import { Routes, Route } from 'react-router-dom'

import { CreateServiceProvider } from '@/core/context'

import { ErrorPage } from '@/pages/Error'
import { LoginPage } from '@/pages/Login'
import { VerifyPage } from '@/pages/Verify'
import { VerificationPage } from '@/pages/Verification'

import { DashboardLayout, HomeLayout } from '@/layouts'
import {
  Overview,
  Tokens,
  Management,
  Services,
  TokensList,
  TokenCreate,
} from '@/pages/Dashboard/components/Main/screens'
import { ServicesInstance } from '@/pages/Dashboard/components/Main/screens/Services/Instances'
import { ServiceInstanceCreate } from '@/pages/Dashboard/components/Main/screens/Services/Instances/Create'
import { ServiceInstanceTest } from './pages/Dashboard/components/Main/screens/Services/Instances/Test'
import {
  UserManagement,
  RolesManagement,
} from './pages/Dashboard/components/Main/screens/Management'

function App() {
  return (
    <Routes>
      <Route element={<HomeLayout />}>
        <Route path="/" element={<LoginPage />} />
        <Route path="/verify" element={<VerifyPage />} />
        <Route path="/verification" element={<VerificationPage />} />
        <Route path="/login" element={<LoginPage />} />
      </Route>

      <Route path="dashboard" element={<DashboardLayout />}>
        <Route index element={<Overview />} />
        <Route path="overview" element={<Overview />} />

        <Route path="management">
          <Route index element={<Management />} />
          <Route path="user">
            <Route index element={<UserManagement />} />
          </Route>
          <Route path="roles">
            <Route index element={<RolesManagement />} />
          </Route>
          <Route path="account">
            <Route index element={<>Account Management</>} />
          </Route>
        </Route>

        <Route path="tokens" element={<Tokens />}>
          <Route path="list" element={<TokensList />}>
            <Route path="create" element={<TokenCreate />} />
          </Route>
        </Route>

        <Route path="services">
          <Route index element={<Services />} />
          <Route path=":service">
            <Route index element={<ServicesInstance />} />
            <Route path="test" element={<ServiceInstanceTest />} />
            <Route
              path="create"
              element={
                <CreateServiceProvider>
                  <ServiceInstanceCreate />
                </CreateServiceProvider>
              }
            />
          </Route>
        </Route>
      </Route>

      <Route path="/404" element={<ErrorPage />} />
    </Routes>
  )
}

export default App
