import { Bar } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'

interface IBarChartProps {
  labels: string[]
  data: number[]
}

export const BarChart = ({ labels, data }: IBarChartProps) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  )

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    // scales: {
    //   x: {
    //     grid: {
    //       display: false,
    //     },
    //   },
    //   y: {
    //     grid: {
    //       display: false,
    //     },
    //   },
    // },
    layout: {
      padding: {
        top: 40,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  }

  const barData = {
    labels,
    datasets: [
      {
        label: '',
        data,
        // borderRadius: Number.MAX_VALUE,
        borderSkipped: false,
      },
    ],
  }

  return (
    <Bar
      options={options}
      data={barData}
      plugins={[
        {
          id: 'chartjs-plugin-gradient',
          beforeRender: (chart) => {
            const { ctx, scales, data, chartArea } = chart
            if (!ctx || !scales) return

            const dataset = data.datasets?.[0]
            if (!dataset) return

            const gradient = ctx.createLinearGradient(
              0,
              chartArea.bottom,
              0,
              chartArea.top,
            )
            gradient.addColorStop(0, 'rgba(148,117,192,1)') // Конечный цвет градиента
            gradient.addColorStop(1, 'rgba(96,113,255,1)') // Начальный цвет градиента
            dataset.backgroundColor = gradient
          },
        },
      ]}
    />
  )
}
