/* eslint-disable react/display-name */
import { InputHTMLAttributes, forwardRef } from 'react'

interface IRadioButtonProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string
  id: string
  value: string
  error?: boolean
  disabled?: boolean
}
export const RadioButton = forwardRef<HTMLDivElement, IRadioButtonProps>(
  ({ id, label, value, disabled, ...rest }, ref) => {
    return (
      <div className="radio" {...{ ...rest, ref }}>
        <input
          id={id}
          value={value}
          type="radio"
          disabled={disabled}
          {...rest}
          className="radio__input"
        />
        <label htmlFor={id} className="radio__label">
          {label}
        </label>
      </div>
    )
  },
)
