/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ChangeEvent,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { isMobile } from 'react-device-detect'

import { useApi, useMobileViewResolution } from '@/core/hooks'
import { EEndpoint, EUserApiPathType } from '@/core/api'
import { hashByCssVariable } from '@/core/utils'

import { Button, EButtonType, Icon, Input } from '@/components/ui'
import { VerifyStatusInfo } from '@/components/simple'
import { Timer, Stepper, ErrorNotification, OTPField } from '@/components/smart'

import './style.scss'

type TForgetPasswordHandlerPayload = {
  username: string
}
type TConfirmForgotPasswordHandlerPayload = {
  username: string
  code: string
  newPassword: string
}

export const VerificationPage = () => {
  const navigate = useNavigate()
  const { apiRequest } = useApi()

  const { isMobileResolution } = useMobileViewResolution()
  const [_username, setUserName] = useState<string>('')
  const [_prevStepHasError, setPrevStepHasError] = useState<boolean>(false)
  const [currentStep, setCurrentStep] = useState<number>(1)
  const [codeError, setCodeError] = useState<boolean>(false)

  console.log('history.state: ', _username)
  console.log('history.state: ', _prevStepHasError)

  useEffect(() => {
    if (Object.prototype.hasOwnProperty.call(localStorage, 'username')) {
      setUserName(localStorage.getItem('username') as string)
    } else if (history.state?.usr['username'] !== undefined) {
      setUserName(history.state?.usr['username'])
    }

    if (Object.prototype.hasOwnProperty.call(localStorage, 'stepWithError')) {
      setPrevStepHasError(
        Boolean(parseInt(localStorage.getItem('stepWithError')!, 10)),
      )
    } else if (history.state?.usr['stepWithError'] !== undefined) {
      setPrevStepHasError(Boolean(history.state?.usr['stepWithError']))
    }
  }, [])

  const forgetPasswordHandler = async ({
    username,
  }: TForgetPasswordHandlerPayload) => {
    try {
      await apiRequest({
        endpoint: EEndpoint.user,
        path: EUserApiPathType.forgetPassword,
        options: {
          method: 'POST',
          body: JSON.stringify({ username }),
        },
      })
      setCurrentStep(2)
    } catch (error) {
      console.log(error)
      alert(error)
    }
  }

  const confirmForgotPasswordHandler = async ({
    username,
    code,
    newPassword,
  }: TConfirmForgotPasswordHandlerPayload) => {
    try {
      await apiRequest({
        endpoint: EEndpoint.user,
        path: EUserApiPathType.confirmForgotPassword,
        options: {
          method: 'POST',
          body: JSON.stringify({ username, code, newPassword }),
        },
      })
      setCurrentStep(3)
    } catch (error) {
      setCodeError(true)
    }
  }

  const isMobileRender = () => {
    return isMobile || isMobileResolution
  }

  const RenderScreen = (props: { currentStep: number }) => {
    const { currentStep } = props
    switch (currentStep) {
      case 1:
        return <ScreenFirst />
      case 2:
        return <ScreenSecond />
      case 3:
        return <ScreenCongratulation />
      default:
        return <ScreenFirst />
    }
  }

  const ScreenFirst = () => {
    const [hasError] = useState<boolean>(_prevStepHasError)

    return (
      <>
        {hasError ? (
          <VerifyStatusInfo
            hasError
            onSendNewCode={() => forgetPasswordHandler({ username: _username })}
          />
        ) : (
          <>
            <VerifyStatusInfo />

            <div className="verify-step__btn">
              <Button
                type={EButtonType.button}
                onClick={() => forgetPasswordHandler({ username: _username })}
              >
                Thank you
              </Button>
            </div>
          </>
        )}
      </>
    )
  }

  const ScreenSecond = () => {
    const [input, setInput] = useState({
      password: '',
      confirmPassword: '',
    })

    const [timerError, setTimerError] = useState<boolean>(false)
    const [currentCode, setCurrentCode] = useState<string[]>()

    const [validLength, setValidLength] = useState(false)
    const [hasNumber, setHasNumber] = useState(false)
    const [upperCase, setUpperCase] = useState(false)
    const [lowerCase, setLowerCase] = useState(false)
    const [specialChar, setSpecialChar] = useState(false)
    const [match, setMatch] = useState(false)
    const [requiredLength] = useState(8)

    const [isCodeFilled, setCodeFilled] = useState(false)

    useEffect(() => {
      setValidLength(input.password.length >= requiredLength ? true : false)
      setUpperCase(input.password.toLowerCase() !== input.password)
      setLowerCase(input.password.toUpperCase() !== input.password)
      setHasNumber(/\d/.test(input.password))
      setSpecialChar(
        /[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/.test(input.password),
      )
      setMatch(!!input.password && input.password === input.confirmPassword)
    }, [input, requiredLength])

    useEffect(() => {
      setCodeFilled(!!currentCode && currentCode.every((item) => item !== ''))
    }, [currentCode])

    const handleCurrentCodeChange = useCallback((value: string[]) => {
      setCurrentCode(value)
      setCodeError(false)
    }, [])

    const stopTimerHandler = useCallback(() => {
      setTimerError(true)
    }, [])

    const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target
      setInput((prev) => ({
        ...prev,
        [name]: value,
      }))
    }

    const [_, forceUpdate] = useReducer((x) => x + 1, 0)

    const handleTimerReset = useCallback(() => {
      console.log('handleTimerReset: ', _)
      forceUpdate()
    }, [_])

    const sendNewCodeHandler = useCallback(
      async (username: string) => {
        try {
          await forgetPasswordHandler({ username })
          setTimerError(false)
          handleTimerReset()
        } catch (error: any) {
          alert(error.message)
        }
      },
      [timerError],
    )
    const checkList = [
      { text: 'At least 8 characters', isValid: validLength },
      { text: 'Contains at least 1 lowercase letter', isValid: lowerCase },
      { text: 'Contains at least 1 uppercase letter', isValid: upperCase },
      { text: 'Contains at least 1 number', isValid: hasNumber },
      { text: 'Contains at least 1 special character', isValid: specialChar },
    ]

    return (
      <div className="verify-pwd">
        <h2 className="verify-pwd__title">Set password</h2>
        <div className="verify-pwd__desc">
          Please choose a password and enter the additional verification code
          sent to your email
        </div>

        <div className="verify-pwd__password verify-pwd-password">
          <OTPField onChange={handleCurrentCodeChange} />
          <div className="verify-pwd-password__controller">
            <div className="verify-pwd__timer">
              time remaining:&nbsp;
              <Timer onReset={handleTimerReset} onEndTimer={stopTimerHandler} />
            </div>
            <Button
              isLinkView
              disabled={!timerError}
              onClick={() => sendNewCodeHandler(_username)}
            >
              Send new code
            </Button>
          </div>
          {timerError || codeError ? (
            <div className="verify-pwd-password__error">
              <ErrorNotification
                errorText={
                  timerError ? 'the code was expired' : 'the code is invalid'
                }
              />
            </div>
          ) : null}
        </div>

        <form className="form">
          <fieldset>
            <Input
              type="password"
              name="password"
              placeholder="New password"
              value={input.password}
              onChange={onInputChange}
            />
            <Input
              type="password"
              name="confirmPassword"
              placeholder="Confirm password"
              value={input.confirmPassword}
              onChange={onInputChange}
            />
            {input.password.length > 0 &&
              input.confirmPassword.length > 0 &&
              !match && (
                <span className="error">
                  Password and Confirm Password does not match.
                </span>
              )}
          </fieldset>

          <ul>
            {checkList.map((list, index) => (
              <li
                style={{
                  color: list.isValid
                    ? hashByCssVariable('--status-success')
                    : hashByCssVariable('--status-error'),
                }}
                key={index}
              >
                {list.isValid ? (
                  <Icon viewBox="0 0 24 24" size={'24px'} icon={'check'} />
                ) : (
                  <Icon viewBox="0 0 24 24" size={'24px'} icon={'close'} />
                )}
                <span>{list.text}</span>
              </li>
            ))}
          </ul>

          <Button
            type={EButtonType.button}
            disabled={
              timerError ||
              !isCodeFilled ||
              !specialChar ||
              !hasNumber ||
              !upperCase ||
              !lowerCase ||
              !validLength ||
              !match
            }
            onClick={() =>
              confirmForgotPasswordHandler({
                username: _username,
                code: (currentCode && currentCode.join('')) || '',
                newPassword: input.password || input.confirmPassword,
              })
            }
          >
            Set password
          </Button>
        </form>
      </div>
    )
  }

  const ScreenCongratulation = () => {
    return (
      <div className="verify-congratulation">
        <img
          src="https://s3-alpha-sig.figma.com/img/9122/4305/419f37b05edc6fcf3b917908db561fbb?Expires=1698624000&Signature=iW3jjnmHvdeEmT~iY5BkdjiHUGx9NC-DvpfFeBwzGbKcpM4kmGot6K3G9g0ZYN1-xBgZw-J8~52AT3yaZURns74wRfn5KTbNub6YtPQuRCVhYCo2oJOSMaylfQPz4OGA1PTaVe2JxrANhkGrKU13rfxrWgpY5BAay-O7cPRBBHESkQH-Yr-ZovqFK1j9FQj968q3d8c9JJqXKal7tqEIplTlWRccwL2PVs24e8r9vwSvSJm3TCZF9V5ODHRGpOyZiZZnSdgKcOqhcr40bo-wwUY00lRE7OVVinBrD7ThlxNy4VpGXJPzYx0GCi-yoOMgfjzlzhrxxOmH3Ds5pAF3~Q__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4"
          alt=""
        />
        <div className="verify-congratulation__title">Congratulations!</div>
        <div className="verify-congratulation__subtitle">
          Sign up was successful now you can log in to your account with the
          password you choose.
        </div>
        <Button
          type={EButtonType.button}
          onClick={() => navigate('/login', { state: { username: _username } })}
        >
          Log in
        </Button>
      </div>
    )
  }

  return (
    <div
      className={`verification-page ${
        currentStep === 3 ? 'verification-page--congratulations' : ''
      }`}
    >
      <div className="verification-page__layer">
        <div className="verification-page__content">
          <div className="verification-page__logo">
            <Icon viewBox="0 0 387 40" icon={'w3PlatformGradient'} />
          </div>
          {/* <img
            className=""
            src="https://s3-alpha-sig.figma.com/img/ede3/91f1/1ddd71c9bcf630c07d1961e7a837f9be?Expires=1698019200&Signature=gaKLrCocECotn4x75COnwygf7ETuE4OndUYMAJ0QIK6YZoxvjM8AY~6r5tcLLEQ4kME-2Oynq7IXfC4k9rj9~rIco8rA4p1xHr0ljM4PBSDSVx6ZI1RQhjlvxqxRaiGbkFf-BUsNvoCJ9cv8s17ntj4xcyDaqNHrEw6VDh3B2AjnsrmQWijQ7QXG5XVYRQb-rNjPPdaeXVRB61Eh19uiyV085StpOQ~y6Zpm4BHomU5guWdNZiXG~d-uY2FxlBZtpV~QU7Y3NR5HpfgyBUeymHgB0cHk53jhyr-wfv07w7XlfbDtWGHJfdhEZyqStycsNSo8P-EwlVxNshmG79lADA__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4"
            alt="w3"
          /> */}
          <Stepper
            className="verification-page__stepper"
            currentStep={currentStep}
          />

          <div className="container">
            <div className={`verify-step verify-step--${currentStep}`}>
              <RenderScreen currentStep={currentStep} />
            </div>
          </div>

          {isMobileRender() && (
            <Link to="/" className="verification-page__sponsor">
              <Icon viewBox="0 0 321 93" icon={'logo'} />
            </Link>
          )}
        </div>
        {!isMobileRender() && currentStep !== 3 && (
          <div className="verification-page__aside">
            {currentStep !== 3 && (
              <div className="aside-wrapper">
                <img
                  src={require(
                    `@/assets/images/verification/verification-step${currentStep}.png`,
                  )}
                  alt="sb2"
                />
                <Icon viewBox="0 0 119 63" icon={'logoSmall'} />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
