/* eslint-disable @typescript-eslint/no-explicit-any */
import { v4 as uuidv4 } from 'uuid'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useAuth, useModal } from '@/core/context'
import { useApi, useDebounce } from '@/core/hooks'
import { EEndpoint, EServicesApiPathType, IServiceCreate } from '@/core/api'

import { Button } from '@/components/ui'
import { DataLoading } from '@/components/smart'

enum ECreationStatus {
  pending = 'pending',
  success = 'success',
}

interface ICreationProcessProps {
  serviceId: string
  description: string
  createServiceData: any
}

export const CreationProcess = ({
  serviceId,
  description,
  createServiceData,
}: ICreationProcessProps) => {
  const { user }: any = useAuth()
  const { setModal } = useModal()
  const { apiRequest } = useApi()
  const navigate = useNavigate()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [creationStatus, setStatus] = useState(ECreationStatus.pending)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [counter, setCounter] = useState<number>(0)
  const debouncedValue = useDebounce<number>(counter, 10000)

  const createServiceHandler = async () => {
    delete createServiceData.description

    const payload: IServiceCreate = {
      serviceId,
      description,
      serviceRequest: createServiceData,
    }

    try {
      const response = await apiRequest({
        endpoint: EEndpoint.services,
        path: EServicesApiPathType.runServiceCreation,
        options: {
          headers: {
            'transaction-id': uuidv4(),
            Authorization: `${user.TokenType} ${user.AccessToken}`,
          },
          method: 'POST',
          body: JSON.stringify(payload),
        },
      })

      if (response) {
        navigate('dashboard/services')
        setModal(null)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    createServiceHandler()
    console.log(creationStatus)
  }, [debouncedValue])

  return (
    <div className="creation-process-modal">
      {creationStatus === ECreationStatus.pending && (
        <>
          <DataLoading
            isLoading={true}
            text={'This process can take up to 2 min.'}
          />
        </>
      )}
      {creationStatus === ECreationStatus.success && (
        <>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="95"
            height="88"
            viewBox="0 0 95 88"
            fill="none"
          >
            <path
              d="M33.1139 57.3911L30.9574 54.1883C30.3692 53.3151 29.5755 52.5998 28.646 52.1051C27.7166 51.6105 26.6799 51.3517 25.6271 51.3516C24.5742 51.3514 23.5375 51.6099 22.6079 52.1042C21.6783 52.5986 20.8844 53.3137 20.2959 54.1867L0.46004 83.6201C0.186984 84.0251 0.029233 84.4967 0.00368598 84.9844C-0.021861 85.4721 0.0857588 85.9576 0.315016 86.3888C0.544274 86.8201 0.886532 87.1808 1.30513 87.4324C1.72372 87.684 2.20288 87.817 2.69127 87.8172L17.839 87.8256C17.1423 86.5105 16.8162 85.0306 16.8957 83.5444C16.9752 82.0582 17.4572 80.6216 18.2903 79.3882L33.1139 57.3911Z"
              fill="#6071FF"
            />
            <path
              d="M93.3782 81.8851L65.2245 40.0785C64.0994 38.3968 62.4406 37.1431 60.5157 36.5196V21.1792C63.3662 20.4574 66.3815 20.7886 69.0075 22.1119C73.6484 24.587 82.5531 23.863 86.4675 23.3887C87.0003 23.3235 87.4906 23.0652 87.8457 22.6627C88.2007 22.2602 88.3958 21.7415 88.394 21.2048V4.92247C88.3953 4.5846 88.3184 4.25101 88.1694 3.94775C88.0204 3.6445 87.8033 3.37979 87.5351 3.1743C87.2669 2.9688 86.9549 2.82809 86.6233 2.76314C86.2917 2.69819 85.9496 2.71077 85.6237 2.7999C82.1393 3.75171 76.0539 4.74039 71.4312 1.88528C64.5641 -2.3566 56.3301 1.88528 56.3301 1.88528V1.90323C55.8712 2.14341 55.4867 2.5044 55.218 2.9472C54.9493 3.38999 54.8066 3.89778 54.8055 4.41573V36.5175C52.8798 37.1404 51.22 38.3937 50.0937 40.0753L42.7158 51.0226C44.6636 50.847 46.6265 51.0839 48.4766 51.7181C50.3267 52.3522 52.0223 53.3692 53.4528 54.7027C55.9932 56.8814 59.2107 58.1112 62.5565 58.1824C63.0872 58.2117 63.5855 58.447 63.9453 58.8382C64.3051 59.2294 64.4979 59.7456 64.4827 60.2769C64.4676 60.8081 64.2456 61.3125 63.8641 61.6825C63.4826 62.0526 62.9717 62.2591 62.4402 62.258C62.4004 62.258 62.3606 62.2564 62.3208 62.2548C58.101 62.115 54.052 60.5543 50.8302 57.8256C45.2028 53.0992 40.6497 55.4549 38.7783 56.8656L21.9431 81.8475C21.5558 82.4222 21.3321 83.0915 21.2959 83.7836C21.2597 84.4757 21.4125 85.1646 21.7378 85.7766C22.0631 86.3885 22.5488 86.9005 23.1428 87.2576C23.7367 87.6147 24.4166 87.8036 25.1097 87.8039L90.2063 87.8407C90.8996 87.8411 91.58 87.6528 92.1745 87.2961C92.7691 86.9394 93.2553 86.4277 93.5813 85.8157C93.9072 85.2037 94.0605 84.5146 94.0247 83.8222C93.989 83.1298 93.7655 82.4602 93.3782 81.8851Z"
              fill="#6071FF"
            />
          </svg>
          <div className="creation-process-modal__title">
            {'Set up successful for service!'}
          </div>
          <div className="creation-process-modal__description">
            {'now you can generate synthetic data'}
          </div>
          <div className="creation-process-modal-footer">
            <Button onClick={() => setModal(null)}>Test Service</Button>
          </div>
        </>
      )}
    </div>
  )
}
