/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ChangeEvent, useCallback, useRef, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useLocation } from 'react-router-dom';

import {
  EEndpoint,
  EServicesApiPathType,
  IServicesDocApiCallServicePayload,
} from '@/core/api'
import { useApi, useTokenReset } from '@/core/hooks'
import { useAuth } from '@/core/context'
import { Icon, Input } from '@/components/ui'
import {
  EMessagePosition,
  EMessageType,
  IMessage,
  MessagesList,
} from './MessagesList'

import './style.scss'

interface ChatAIProps {
  aiName?: string
}

const Divider = () => {
  return <div className="chat-ai-divider"></div>
}

export const ChatAI = ({ aiName = 'AI Name' }: ChatAIProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const refAI = useRef(null)
  const { user }: any = useAuth()
  const { resetToken } = useTokenReset()
  const location = useLocation();

  const { apiRequest } = useApi()

  const [messagesList, setMessageList] = useState<IMessage[]>([])
  const [isServerLoading, setServerLoading] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState('What is token?')
  const [isOpen, setOpenState] = useState(true)
  const getCodeFromPath = (path: string): string => {
    if (path.includes('token')) {
      return 'TOKENS';
    } else if (path.includes('overview')) {
      return 'DASHBOARD';
    } else {
      return 'DEFAULT';
    }
  };
  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setInputValue(value)
  }
    

  const messageCreator = useCallback(
    ({ position, text }: { position: EMessagePosition; text: string }) => {
      setMessageList((prev) => {
        return [
          ...prev,
          {
            id: uuidv4(),
            position,
            type: EMessageType.text,
            text,
            date: new Date(),
          },
        ]
      })
    },
    [],
  )

  const getChatBotMessageDescription = async (tokenId: string) => {
    try {
      setServerLoading(true)
      const { fileContent } = await retryGetChatBotMessage(tokenId)
      return fileContent
    } catch (error) {
      console.error(error)
    } finally {
      setServerLoading(false)
    }
  }

  const retryGetChatBotMessage = async (
    tokenId: string,
    retries = 20,
  ): Promise<any> => {
    try {
      const response = await apiRequest({
        endpoint: EEndpoint.secureStorage,
        path: tokenId,
        options: {
          headers: {
            'transaction-id': uuidv4(),
            Authorization: `${user.TokenType} ${user.AccessToken}`,
          },
          method: 'GET',
        },
      })
      return response
    } catch (error: any) {
      if (error.message.match(/403|User is not authorized/gm)) {
        resetToken()
      } else if (retries > 0) {
        return new Promise((resolve, reject) => {
          setTimeout(async () => {
            try {
              const result = await retryGetChatBotMessage(tokenId, retries - 1)
              resolve(result)
            } catch (innerError) {
              reject(innerError)
            }
          }, 5000)
        })
      } else {
        throw error
      }
    }
  }

  const getTokenId = async (payload: IServicesDocApiCallServicePayload) => {
    try {
      setServerLoading(true)
      const { response } = await apiRequest({
        endpoint: EEndpoint.services,
        path: EServicesApiPathType.callService,
        options: {
          headers: {
            'transaction-id': uuidv4(),
            Authorization: `${user.TokenType} ${user.AccessToken}`,
          },
          method: 'POST',
          body: JSON.stringify({ ...payload }),
        },
      })

      const { tokenId } = response

      if (tokenId) {
        return tokenId
      } else if (response === 'POLICY_VALIDATION') {
        // return '16988652645155336'
        messageCreator({
          position: EMessagePosition.left,
          text: 'Daily query limits was reached!',
        })
      }
    } catch (error: any) {
      if (error.message.match(/403|User is not authorized/gm)) resetToken()
      else throw new Error(error.message)
    } finally {
      setServerLoading(false)
    }
  }
  const clickHandler = useCallback(async (inputValue: string) => {
    messageCreator({
      position: EMessagePosition.right,
      text: inputValue,
    })
    try {
      const code = getCodeFromPath(location.pathname)
    const payload = {
      serviceId: 'd45238a3-1afe-4621-a2d9-f0346b6efcf2',
      serviceRequest: {
        code: code,
        query: inputValue,
        history: '',
      },
    }
      const tokenId = await getTokenId(payload)
      const chatBotDescription =
        tokenId && (await getChatBotMessageDescription(tokenId))

      if (chatBotDescription) {
        messageCreator({
          position: EMessagePosition.left,
          text: JSON.parse(chatBotDescription).answer,
        })
      }
    } catch (error: any) {
      if (error.message === 'POLICY_VALIDATION') {
        messageCreator({
          position: EMessagePosition.left,
          text: 'Daily query limits was reached!',
        })
      }
    }
  }, [])

  const toggleHandler = () => {
    const dashboardMainElement =
      refAI.current &&
      (refAI.current as HTMLButtonElement).parentNode?.querySelector(
        '.dashboard-main',
      )

    setOpenState(!isOpen)

    refAI.current &&
      (refAI.current as HTMLButtonElement).classList.toggle('chat-ai--closed')

    dashboardMainElement &&
      (dashboardMainElement as HTMLDivElement).classList.toggle(
        'dashboard-main--wide',
      )
  }

  return (
    <div className="chat-ai" ref={refAI}>
      <button className="chat-ai-show-handler" onClick={toggleHandler}>
        {isOpen ? 'Close AI' : 'Open AI'}
      </button>
      <div className="chat-ai-header">
        <div className="chat-ai-header__name">{aiName}</div>
      </div>
      <Divider />
      <div className="chat-ai-helper">
        <div className="chat-ai-helper__item">
          Hi, here the main dashboard for your organization, here you can help:
          <br />
          1. Data about the
          <br />
          2. Services listed
        </div>
      </div>
      <Divider />

      <MessagesList
        dataSource={messagesList}
        isServerLoading={isServerLoading}
      />

      <div className="chat-ai-message-sender">
        <Input
          type="text"
          name="query"
          placeholder="What can I help you with ..."
          value={inputValue}
          onChange={onInputChange}
        />
        <button
          type="button"
          disabled={inputValue === '' || inputValue === undefined}
          onClick={() => clickHandler(inputValue)}
        >
          <Icon viewBox="0 0 33 33" size={'34px'} icon={'send'} />
        </button>
      </div>
    </div>
  )
}
