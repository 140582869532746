/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactNode } from 'react'

import { Dropdown, Icon } from '@/components/ui'

import './style.scss'
import { useAuth } from '@/core/context'

interface IMainProps {
  userName: string
  children: ReactNode
}

export const Main = ({ userName, children }: IMainProps) => {
  const { logout }: any = useAuth()

  const Profile = () => {
    return (
      <div className="dashboard-main-header__profile">
        <Icon viewBox="0 0 24 24" size={'30px'} icon={'accountCircle'} />
        <span>{userName}</span>
      </div>
    )
  }

  const dropdownMenu = [
    {
      text: 'Logout',
      value: 'logout',
      clickHandler: () => logout(),
    },
  ]

  return (
    <div className="dashboard-main">
      <div className="dashboard-main-header">
        <Dropdown header={<Profile />} data={dropdownMenu} />
      </div>
      <div className="dashboard-main-content">{children}</div>
    </div>
  )
}
