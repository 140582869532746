/* eslint-disable react/no-unescaped-entities */
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import hljs from 'highlight.js'
import 'highlight.js/scss/github.scss'

import { useCreateToken } from '@/core/context'

import { Button } from '@/components/ui'

import './style.scss'

export const ReviewInformation = () => {
  const navigate = useNavigate()
  const { createTokenData } = useCreateToken()

  useEffect(() => {
    hljs.highlightAll()
  }, [])
  return (
    <>
      <div className="token-create-data-container-header">
        <div className="token-create-data-container-header__title">
          Review information
        </div>
        <div className="token-create-data-container-header__desc">
          of token created
        </div>
      </div>
      <div className="token-create-data-container-main">
        <div className="token-create-step token-create-step--review">
          <pre>
            <code className="!whitespace-pre hljs language-json">
              {JSON.stringify({ ...createTokenData }, null, 2)}
            </code>
          </pre>
        </div>
      </div>
      <div className="token-create-data-container-footer">
        <Button onClick={() => navigate('/dashboard/tokens/list')}>
          Back To Token List
        </Button>
      </div>
    </>
  )
}
