/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ETokenStatus } from '@/core/api'
import { Icon, Popover } from '@/components/ui'

const dataGenerator = (ENUM: { [s: string]: string } | ArrayLike<string>) => {
  return Object.entries(ENUM).map(([key, value]) => ({ id: key, name: value }))
}

const statusList = dataGenerator(ETokenStatus)

interface IFilterPopoverProps {
  columnFilters: { id: string; value: string }[]
  setColumnFilters: any
}

export const FilterPopover = ({
  columnFilters,
  setColumnFilters,
}: IFilterPopoverProps) => {
  const filterStatuses =
    columnFilters.find((f) => f.id === 'status')?.value || []

  const StatusItem = ({
    status,
    setColumnFilters,
    isActive,
  }: {
    status: {
      id: string
      name: string
    }
    setColumnFilters: any
    isActive: boolean
  }) => {
    const statusItemClickHandler = () => {
      setColumnFilters((prev: any[]) => {
        const statuses = prev.find((filter) => filter.id === 'status')?.value
        console.log(statuses)
        if (!statuses) {
          return prev.concat({
            id: 'status',
            value: [status.id],
          })
        }
        console.log(columnFilters)

        return prev.map((f) =>
          f.id === 'status'
            ? {
                ...f,
                value: isActive
                  ? statuses.filter((s: string) => s !== status.id)
                  : statuses.concat(status.id),
              }
            : f,
        )
      })
    }

    return (
      <li
        className={`popover-list__item popover-list__item--${status['id']} ${
          isActive ? 'active' : ''
        }`}
        onClick={statusItemClickHandler}
      >
        {status.name === ETokenStatus.MINTED ? 'ACTIVE' : status.name}
      </li>
    )
  }

  return (
    <Popover
      headerText={'Filtered by:'}
      triggerText={'Filter'}
      triggerIcon={<Icon icon={'filter'} size="16px" />}
    >
      <>
        Status
        <ul className="popover-list">
          {statusList.map((status) => (
            <StatusItem
              status={status}
              // @ts-ignore
              isActive={filterStatuses.includes(status.id)}
              setColumnFilters={setColumnFilters}
              key={status.id}
            />
          ))}
        </ul>
      </>
    </Popover>
  )
}
