import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { useApi } from '@/core/hooks'
import { EEndpoint, EUserApiPathType } from '@/core/api'

import { Loading } from '@/components/ui'

import './style.scss'

export const VerifyPage = () => {
  const [searchParams] = useSearchParams()
  const { apiRequest } = useApi()
  const params: {
    [x: string]: string
  } = {}

  searchParams.forEach((value, key) => {
    params[key] = value
  })

  const navigate = useNavigate()

  useEffect(() => {
    const verifyUserHandler = async () => {
      setTimeout(async () => {
        try {
          await apiRequest({
            endpoint: EEndpoint.user,
            path: EUserApiPathType.verify,
            options: {
              method: 'POST',
              body: JSON.stringify({
                username: params.name,
                code: params.code,
              }),
            },
          })
          navigate('/verification', { state: { username: params.name } })
        } catch (error) {
          navigate('/verification', {
            state: { username: params.name, stepWithError: 1 },
          })
        }
      }, 2000)
    }
    verifyUserHandler()
      .then((res) => {
        console.log(res)
      })
      .catch((e) => console.log(e))
  }, [])

  return (
    <div className="verify-page">
      <Loading />
    </div>
  )
}
