/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react'
import { IToken } from '../api'

// @ts-ignore
const CreateTokenContext = createContext<{
  resetTokenData: () => void
  setCreationStep: SetStateAction<any>
  setCreateTokenData: SetStateAction<any>
  creationStep: number
  createTokenData: Partial<IToken>
}>()

const CreateTokenProvider = (props: any) => {
  const [createTokenData, setCreateTokenData] = useState<Partial<IToken>>({})
  const [creationStep, setCreationStep] = useState<number>(1)

  const resetTokenData = useCallback(() => {
    setCreateTokenData({
      tokenId: undefined,
      date: undefined,
      accountIssuer: '',
      accountId: '',
      status: 'MINTED',
      orgId: '',
      TTL: 11111111,
      description: undefined,
      type: undefined,
    })
  }, [setCreateTokenData])

  return (
    <CreateTokenContext.Provider
      value={{
        resetTokenData,
        setCreationStep,
        setCreateTokenData,
        creationStep,
        createTokenData,
      }}
      {...props}
    >
      {props.children}
    </CreateTokenContext.Provider>
  )
}

const useCreateToken = () => {
  const context = useContext(CreateTokenContext)
  if (context === undefined) {
    throw new Error('useSubmitForm must be used within a UserProvider')
  }

  return context
}

export { CreateTokenProvider, useCreateToken }
