import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react'
import { Button, EButtonType, Input } from '@/components/ui'

import { MultiSelect } from '../../MultiSelect'

interface IDuplicateRoleProps {
  permissions: string[]
  setRoleChange: Dispatch<SetStateAction<boolean>>
}

export const DuplicateRole = ({
  permissions,
  setRoleChange,
}: IDuplicateRoleProps) => {
  console.log('setRoleChange: ', setRoleChange)

  const [rolePermissions, setRolePermissions] = useState<string[]>([])
  const [input, setInput] = useState({
    roleName: '',
    description: '',
  })

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const createRoleHandler = () => {}

  return (
    <div className="duplicate-role">
      <div className="title">Duplicate role</div>
      <div className="form-wrapper">
        <form>
          <fieldset>
            <ul className="create-role-form-list">
              <li className="create-role-form-list__item">
                <label>Role Name</label>
                <Input
                  type="text"
                  name="roleName"
                  value={input.roleName}
                  onChange={onInputChange}
                />
              </li>
              <li className="create-role-form-list__item">
                <label>Role Description</label>
                <Input
                  type="text"
                  name="description"
                  value={input.description}
                  onChange={onInputChange}
                />
              </li>

              <li>
                <label>Permissions</label>

                <MultiSelect
                  listData={rolePermissions}
                  setListData={setRolePermissions}
                  data={permissions}
                />
              </li>
            </ul>
          </fieldset>
        </form>

        <Button type={EButtonType.submit} onClick={createRoleHandler}>
          Create role
        </Button>
      </div>
    </div>
  )
}
