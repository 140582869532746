/* eslint-disable @typescript-eslint/no-explicit-any */
import { v4 as uuidv4 } from 'uuid'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ColumnDef } from '@tanstack/react-table'

import { useApi, useTokenReset } from '@/core/hooks'
import { useAuth, useModal } from '@/core/context'
import { EEndpoint, EUserApiPathType, IRole } from '@/core/api'

import { Button, Icon } from '@/components/ui'
import { ConfirmationModal, EConfirmationModalType } from '@/components/smart'
import { convertCamelCaseToWords } from '@/core/utils'

import { BackArrow, IndeterminateCheckbox } from '@/components/simple'

import { CreateRole } from './CreateRole'
import { TanstackTable } from './TanstackTable'
import { DuplicateRole } from './DuplicateRole'

import './style.scss'

export const RolesManagement = () => {
  const navigate = useNavigate()
  const { user }: any = useAuth()
  const { apiRequest } = useApi()
  const { setModal } = useModal()
  const { resetToken, resettingToken } = useTokenReset()

  const [isRoleChanged, setRoleChange] = useState<boolean>(false)
  const [isLoading, setLoading] = useState<boolean>(false)
  const [isRolesLoading, setRolesLoading] = useState<boolean>(false)
  const [rolePermissions, setRolePermissions] = useState<string[]>([])

  const [columns, setColumns] = useState<ColumnDef<Pick<IRole, 'roleName'>>[]>(
    [],
  )
  const [rolesList, setRolesList] = useState<Pick<IRole, 'roleName'>[]>([])
  const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({})

  const getRolePermissions = async () => {
    try {
      setLoading(true)
      const response = await apiRequest({
        getBaseUrl: true,
        endpoint: EEndpoint.user,
        path: `${EUserApiPathType.roles}/premissions`,
        options: {
          headers: {
            'transaction-id': uuidv4(),
            Authorization: `${user.TokenType} ${user.AccessToken}`,
          },
          method: 'GET',
        },
      })
      if (response) {
        const { premissions } = response
        setRolePermissions(premissions)
      }
    } catch (error: any) {
      if (error.message.match(/502|User is not authorized/gm)) resetToken()
      else console.log(error.message)
    } finally {
      setLoading(false)
    }
  }

  const getRoles = async () => {
    try {
      setRolesLoading(true)
      const response = (await apiRequest({
        getBaseUrl: true,
        endpoint: EEndpoint.user,
        path: EUserApiPathType.roles,
        options: {
          headers: {
            'transaction-id': uuidv4(),
            Authorization: `${user.TokenType} ${user.AccessToken}`,
          },
          method: 'GET',
        },
      })) as { roles: IRole[] }
      if (response) {
        const { roles } = response

        const _roles = [...roles].map((item) => ({
          roleName: item.roleName,
          description: '', // @TODO chek
        }))
        setRolesList([..._roles])
        setColumns([
          {
            id: 'select',
            header: ({ table }) => {
              return (
                <IndeterminateCheckbox
                  {...{
                    checked: table.getIsAllPageRowsSelected(),
                    indeterminate: table.getIsSomePageRowsSelected(),
                    onChange: table.getToggleAllPageRowsSelectedHandler(),
                    name: 'select',
                  }}
                />
              )
            },
            cell: ({ row }) => {
              return (
                <IndeterminateCheckbox
                  {...{
                    checked: row.getIsSelected(),
                    disabled: !row.getCanSelect(),
                    indeterminate: row.getIsSomeSelected(),
                    onChange: row.getToggleSelectedHandler(),
                  }}
                />
              )
            },
          },
          ...Object.keys([..._roles][0]).map((item) => {
            return {
              accessorKey: item,
              header: convertCamelCaseToWords(item),
              cell: (props: any) => {
                return <>{props.getValue()}</>
              },
            }
          }),
        ])
      }
    } catch (error: any) {
      if (error.message.match(/502|User is not authorized/gm)) resetToken()
      else console.log(error.message)
    } finally {
      setRolesLoading(false)
    }
  }

  useEffect(() => {
    getRoles()
    getRolePermissions()
  }, [resettingToken])

  useEffect(() => {
    if (!isRoleChanged) return
    getRoles()
    getRolePermissions()
    setRowSelection({})
  }, [isRoleChanged])

  useEffect(() => {
    console.log(getRowSelectionData(rowSelection))
  }, [rowSelection])

  const getRowSelectionData = (rowSelection: Record<string, boolean>) => {
    return Object.keys(rowSelection).map(
      (item) => rolesList![parseInt(item)],
    )[0]
  }

  const addRoleClickHandler = () => {
    setModal(
      <CreateRole
        permissions={rolePermissions}
        setRoleChange={setRoleChange}
      />,
    )
  }
  const deleteRoleClickHandler = () => {
    setModal(
      <ConfirmationModal
        icon={
          <Icon
            icon={'consfirmationModalManagement'}
            viewBox="0 0 71 89"
            size={'71px'}
          />
        }
        type={EConfirmationModalType.delete}
        title={'Are your sure?'}
        description="Are you sure you want to delete role?"
        actionHandler={deleteRoleHandler}
      />,
    )
  }

  const deleteRoleHandler = async () => {
    const { roleName } = getRowSelectionData(rowSelection)
    try {
      const response = await apiRequest({
        getBaseUrl: true,
        endpoint: EEndpoint.user,
        path: `${EUserApiPathType.role}/delete/${encodeURIComponent(roleName)}`,
        options: {
          headers: {
            'transaction-id': uuidv4(),
            Authorization: `${user.TokenType} ${user.AccessToken}`,
          },
          method: 'DELETE',
        },
      })

      console.log(response)

      setRoleChange(true)
      setModal(null)
    } catch (error: any) {
      if (error.message.match(/403|User is not authorized/gm)) resetToken()
      else console.log(error.message)
    }
  }

  const duplicateRoleClickHandler = () => {
    setModal(
      <DuplicateRole
        permissions={rolePermissions}
        setRoleChange={setRoleChange}
      />,
    )
  }

  return (
    <div className="roles-management">
      <div className="roles-management__back">
        <Button onClick={() => navigate(-1)}>
          <BackArrow />
        </Button>
      </div>

      <div className="roles-management__title">Role Management</div>

      <div className="roles-management-controls">
        <Button
          onClick={addRoleClickHandler}
          disabled={isLoading || isRolesLoading}
        >
          +
        </Button>
        <Button
          onClick={deleteRoleClickHandler}
          disabled={!Object.keys(rowSelection).length}
        >
          Delete
        </Button>
        <Button
          onClick={duplicateRoleClickHandler}
          disabled={!Object.keys(rowSelection).length}
        >
          Duplicate
        </Button>
        <Button>Show Details</Button>
      </div>

      <TanstackTable
        isLoading={isRolesLoading}
        tableData={rolesList}
        columns={columns}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
      />
    </div>
  )
}
