/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useCallback, useEffect, useState } from 'react'
import classNames from 'classnames'

import { useModal } from '@/core/context'
import { IToken } from '@/core/api'

import { Button, Icon } from '@/components/ui'

import { Type } from './Type'
import { Description } from './Description'
import { UploadFile } from './UploadFile'
import { IOption } from '@/components/ui/RadioButton/RadioButtonGroup'

const stepList = [
  {
    index: 1,
    text: 'Choose type',
  },
  {
    index: 2,
    text: 'Choose description',
  },
  {
    index: 3,
    text: 'Upload file',
  },
]

export const CreateToken = () => {
  const { setModal } = useModal()

  const [currentStep, setCurrentStep] = useState<number>(1)

  const [createTokenData, setCreateTokenData] = useState<Partial<IToken>>({})
  const [tokensList, setTokenList] = useState<IOption[]>([])

  const backButtonHandler = useCallback(() => {
    switch (currentStep) {
      case 1:
        setModal(null)
        break
      case 2:
        setCurrentStep(currentStep - 1)
        break
      case 3:
        setCurrentStep(currentStep - 1)
        break
    }
  }, [currentStep])

  return (
    <div className="token-create">
      <div className="token-create-content">
        <div className="token-create-content__asside">
          <div className="token-create__title">Create new (Token Type):</div>
          <ul className="token-create-step-list">
            {stepList.map((item, index) => {
              return (
                <li
                  key={index}
                  className={classNames('token-create-step-list__item', {
                    isActive: currentStep === item.index,
                    isDone: currentStep > item.index,
                  })}
                >
                  <div className="token-create-step-list__index">
                    {currentStep > item.index ? (
                      <Icon icon={'check'} />
                    ) : (
                      item.index
                    )}
                  </div>
                  <div className="token-create-step-list__text">
                    {item.text}
                  </div>
                  <Icon icon={'arrowForward'} />
                </li>
              )
            })}
          </ul>

          {currentStep !== 4 && (
            <Button onClick={backButtonHandler}>
              <Icon icon={'arrowBack'} />
              Back
            </Button>
          )}
        </div>

        <div className="token-create-data-container">
          {currentStep === 1 && (
            <Type
              tokensList={tokensList}
              currentStep={currentStep}
              createTokenData={createTokenData}
              setTokenList={setTokenList}
              setCurrentStep={setCurrentStep}
              setCreateTokenData={setCreateTokenData}
            />
          )}
          {currentStep === 2 && (
            <Description
              currentStep={currentStep}
              createTokenData={createTokenData}
              setCurrentStep={setCurrentStep}
              setCreateTokenData={setCreateTokenData}
            />
          )}
          {currentStep === 3 && (
            <UploadFile
              currentStep={currentStep}
              createTokenData={createTokenData}
              setCurrentStep={setCurrentStep}
              setCreateTokenData={setCreateTokenData}
            />
          )}
        </div>
      </div>
    </div>
  )
}
