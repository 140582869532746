import './style.scss'

import {
  Info,
  RegisteredServices,
  TotalTokens,
  TransactionsActivity,
} from './components'

export const Overview = () => {
  return (
    <div className="overview">
      <div className="overview__title">Welcome back!</div>
      <Info />
      <div className="overview-token-transaction">
        <TransactionsActivity />
        <TotalTokens />
        <RegisteredServices />
      </div>
    </div>
  )
}
