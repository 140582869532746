/* eslint-disable @typescript-eslint/no-explicit-any */
export enum EServicesApiPathType {
  services = '',
  org = 'org',
  callService = 'callService',
  editConfiguration = 'callService/configuration',
  delete = 'delete',
  runServiceCreation = 'runServiceCreation',
}

export enum EServicesQueryType {
  doc = 'DOC',
}

export enum EServiceCreateStatus {
  READY = 'READY',
  PENDING = 'PENDING',
}

export interface IServices {
  account?: string
  serviceSchema?: string
  serviceId: string
  serviceName: string
  description: string
  serviceOrgRegisrationSchema: string // TODO
}

export interface IServiceInstanceConfiguration {
  orgId?: string
  serviceId?: string
  description?: string
  policyToken?: string
  secretToken?: string
  baseToken?: string
  externalId?: string
  chatGptSecretToken?: string
  twilioSecretToken?: string
  onBoardingToken?: string
  accountHashToken?: string
}
export interface IServiceInstance {
  account: string
  id: string
  serviceName: string
  description: string
  serviceSchema?: string
  serviceOrgRegisrationSchema: string
  configuration: any
}

export interface IServiceRequest {
  baseTokenId: string | number
  queryType: EServicesQueryType
  query: string
  baseService: boolean
  
}

export interface IServiceDocRequest {
  code: string 
  history: string
  query: string
  
}


export interface IServicesDocApiCallServicePayload {
  serviceId: string
  serviceRequest: IServiceDocRequest
}

export interface IServicesApiCallServicePayload {
  serviceId: string
  serviceRequest: IServiceRequest
}  

export interface IServiceCreate {
  serviceId: string
  description: string
  serviceRequest: { [x: string]: string }
}
