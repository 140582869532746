/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/display-name */
import { v4 as uuidv4 } from 'uuid'
import {
  ChangeEvent,
  forwardRef,
  useCallback,
  useEffect,
  useState,
} from 'react'

import { useApi, useDebounce, useTokenReset } from '@/core/hooks'
import { EEndpoint, ETokensApiPathType, IToken } from '@/core/api'
import { EButtonType, Icon, RadioButtonGroup } from '@/components/ui'

import { DataLoading } from '../DataLoading'
import { NoDataAvailable } from '../NoDataAvailable'
import { CreateToken } from './CreateToken'

import './style.scss'
import { useAuth, useModal } from '@/core/context'

interface ISearchTokenProps {
  radioGroupName: string
  onChange: (event: any, value: any) => any
  hasCreateToken?: boolean
  stepNumber?: number
}

// eslint-disable-next-line no-empty-pattern
export const SearchToken = forwardRef<HTMLDivElement, ISearchTokenProps>(
  ({ radioGroupName, onChange, hasCreateToken, stepNumber }, ref) => {
    const { user }: any = useAuth()
    const { apiRequest } = useApi()
    const { setModal } = useModal()
    const { resetToken } = useTokenReset()

    const [isLoading, setLoading] = useState<boolean>(false)
    const [inputSearchName, setInputValue] = useState('')
    const [tokensList, setTokenList] = useState<any[]>([])

    const debouncedValue = useDebounce<string>(inputSearchName, 500)

    const onFilterChange = async (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target
      setInputValue(value)
    }

    const radioGroupHandler = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        onChange && onChange(event, value)
      },
      [onChange],
    )

    const getTokensApiCall = async () => {
      const payload = {
        tokenName: inputSearchName,
      }

      try {
        setLoading(true)
        const response = await apiRequest({
          endpoint: EEndpoint.tokens,
          path: `${ETokensApiPathType.storageTokens}/filter`,
          params: { ...payload } as any,
          options: {
            headers: {
              'transaction-id': uuidv4(),
              Authorization: `${user.TokenType} ${user.AccessToken}`,
            },
            method: 'GET',
          },
        })
        if (response) {
          setTokenList(
            (response.results as IToken[]).map((item) => {
              return {
                value: item.tokenId,
                name: 'radio-token',
                label: `${item.tokenName} - ${item.tokenId}`,
                isChecked: false,
              }
            }),
          )
          return response
        }
      } catch (error: any) {
        if (error.message.match(/502/gm)) {
          resetToken()
          await getTokensApiCall()
        } else console.log(error)
      } finally {
        setLoading(false)
      }
    }

    const createTokenHandler = useCallback(() => {
      setModal(<CreateToken />)
    }, [])

    useEffect(() => {
      getTokensApiCall()
    }, [debouncedValue, stepNumber])

    return (
      <div className="search-token" {...{ ref }}>
        <input
          className="search"
          type="search"
          placeholder="Browse and Select token"
          value={inputSearchName}
          onInput={onFilterChange}
        />
        <div className="search-token-container">
          {hasCreateToken && (
            <button
              className="create-token"
              type={EButtonType.button}
              onClick={createTokenHandler}
            >
              <Icon icon={'addCircle'} size={'16px'} />
              <span>Create new token</span>
            </button>
          )}

          <div className="search-token-container__content">
            {!isLoading && tokensList.length > 0 && (
              <RadioButtonGroup
                label=""
                options={tokensList}
                name={radioGroupName}
                onChange={radioGroupHandler}
              />
            )}
            <DataLoading isLoading={isLoading} />
            <NoDataAvailable
              isConditional={tokensList.length === 0 && !isLoading}
              icon={null}
              text={'No tokens available please create one to step up service'}
            />
          </div>
        </div>
      </div>
    )
  },
)
