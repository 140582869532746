import { HTMLAttributes, forwardRef, useState } from 'react'

import { Icon } from '@/components/ui/Icon'
import { Button } from '@/components/ui/Button'

import './style.scss'

interface InputProps extends HTMLAttributes<HTMLInputElement> {
  type: 'text' | 'number' | 'email' | 'password' | string
  value: string | number
  name?: string
  placeholder?: string
  error?: boolean
  errorMessage?: string
  disabled?: boolean
  readOnly?: boolean
}

// eslint-disable-next-line react/display-name
export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    { type, value, name, placeholder, disabled, readOnly = false, ...rest },
    ref,
  ) => {
    const [isShowPassword, setPasswordVisibility] = useState<boolean>(false)

    return (
      <div className="input-wrapper" {...{ ...rest, ref }}>
        <input
          {...{ ...rest }}
          type={
            type === 'password' ? (isShowPassword ? 'text' : 'password') : type
          }
          value={value}
          name={name}
          placeholder={placeholder}
          disabled={disabled}
          readOnly={readOnly}
        />
        {type === 'password' && (
          <Button
            onClick={() => {
              setPasswordVisibility(!isShowPassword)
            }}
          >
            {isShowPassword ? (
              <Icon viewBox="0 0 24 24" size={'26px'} icon={'eyeHidden'} />
            ) : (
              <Icon viewBox="0 0 24 24" size={'26px'} icon={'eye'} />
            )}
          </Button>
        )}
      </div>
    )
  },
)
