import { useCallback } from 'react'

import { useModal } from '@/core/context'

import { Button } from '@/components/ui'

export enum EConfirmationModalType {
  info = 'info',
  delete = 'delete',
}
/* eslint-disable @typescript-eslint/no-explicit-any */
export const ConfirmationModal = ({
  icon,
  title,
  type = EConfirmationModalType.info,
  description,
  actionHandler,
}: {
  icon?: JSX.Element
  type?: EConfirmationModalType
  title: string
  description: string
  actionHandler: () => Promise<void> | Promise<any>
}) => {
  const { setModal } = useModal()

  const confirmHandler = useCallback(async () => {
    actionHandler && (await actionHandler())
    setModal(null)
  }, [actionHandler])
  return (
    <div className={`confirmation-modal confirmation-modal--${type}`}>
      {!!icon && <div className="confirmation-modal__icon">{icon}</div>}
      <div className="confirmation-modal__title">{title}</div>
      <div className="confirmation-modal__description">{description}</div>
      <div className="confirmation-modal-footer">
        <Button onClick={() => setModal(null)}>Cancel</Button>
        <Button onClick={confirmHandler}>Yes, I’m sure</Button>
      </div>
    </div>
  )
}
