/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react/display-name */
import { FC, ReactNode, useCallback, useEffect, useState } from 'react'
import cn from 'classnames'
import { Loading } from '@/components/ui'
import './style.scss'

export enum EButtonType {
  submit = 'submit',
  reset = 'reset',
  button = 'button',
}

interface ButtonProps {
  children: ReactNode
  type?: EButtonType
  disabled?: boolean
  isLoading?: boolean
  isLinkView?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: () => void | Promise<void>
}

export const Button: FC<ButtonProps> = ({
  type = EButtonType.button,
  onClick,
  children,
  disabled,
  isLoading,
  isLinkView,
  ...rest
}) => {
  const [showLoader, setShowLoader] = useState(false)
  const [isInternalLoading, setIsInternalLoading] = useState(false)

  useEffect(() => {
    if (isLoading || isInternalLoading) {
      setShowLoader(true)
    }
  }, [isLoading, isInternalLoading])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClick = useCallback(async () => {
    try {
      setIsInternalLoading(true)
      onClick && (await onClick())
    } catch (error) {
      setShowLoader(false)
      setIsInternalLoading(false)
    } finally {
      setShowLoader(false)
      setIsInternalLoading(false)
    }
  }, [onClick])

  return (
    <button
      className={cn('button', { 'button--link': isLinkView })}
      type={type}
      onClick={handleClick}
      disabled={showLoader || isInternalLoading || disabled}
      {...rest}
    >
      {showLoader ? <Loading /> : children}
    </button>
  )
}
