enum EUserApiPathType {
  login = 'login',
  verify = 'verify',
  forgetPassword = 'forgetPasword',
  confirmForgotPassword = 'confirmForgotPassword',
  delete = 'delete',
  signUp = 'signup',
  refresh = 'refresh',

  users = 'users',

  role = 'role',
  roles = 'roles',
  assignRole = 'assign/role',
}

enum EUserApiTokenType {
  bearer = 'Bearer',
}
type TUserApiPayload = {
  endpoint: string
  path?: EUserApiPathType | string
  options: RequestInit
  params?: URLSearchParams
  getBaseUrl?: boolean
}

export interface IUser {
  Username: string
  accountId: string
  email: string
  email_verified: string | boolean
  orgId: string
  phone_number: string
  phone_number_verified: string | boolean
  sub: string
}
export interface IUserApiRefreshPayload {
  username: string
  refreshToken: string
}

export { EUserApiPathType }
export type { TUserApiPayload }

export interface IUserLoginRequest {
  AccessToken: string
  ExpiresIn: number
  TokenType: EUserApiTokenType
  RefreshToken: string
  IdToken: string
  UserName?: string
}
export interface IUserApiCreatePayload {
  username: string
  password: string
  email: string
  accountId: string
  phone_number: string
}

export enum ERoleName {
  owner = 'OWNER',
  servicesAdmin = 'SERVICES_ADMIN',
  storageAdmin = 'STORAGE_ADMIN',
  admin = 'ADMIN',
  accountAdmin = 'ACCOUNT_ADMIN',
  userAdmin = 'USER_ADMIN',
}
export interface IRole {
  orgId: 'ALL' | string
  roleName: ERoleName | string
  description?: string
  permissions: string
}
