import { useEffect, useState } from 'react'
import { getWindowDimensions } from '@/core/utils'

const isMobileViewResolution = () => getWindowDimensions().width <= 991

export function useMobileViewResolution() {
  const [isMobile, checkMobile] = useState(isMobileViewResolution)

  useEffect(() => {
    function handleResize() {
      checkMobile(isMobileViewResolution())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return { isMobileResolution: isMobile }
}
