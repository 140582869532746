/* eslint-disable @typescript-eslint/no-explicit-any */
import { v4 as uuidv4 } from 'uuid'
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react'

import { EAnalyticsApiPathType, EEndpoint, IToken } from '@/core/api'

import { Button, Icon, Input, RadioButtonGroup } from '@/components/ui'
import { IOption } from '@/components/ui/RadioButton/RadioButtonGroup'
import { useAuth } from '@/core/context'
import { useApi, useTokenReset } from '@/core/hooks'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface IModalCreateTokenTypeProps {
  tokensList: IOption[]
  currentStep: number
  createTokenData: Partial<IToken>
  setTokenList: Dispatch<SetStateAction<IOption[]>>
  setCurrentStep: Dispatch<SetStateAction<number>>
  setCreateTokenData: Dispatch<SetStateAction<Partial<IToken>>>
}
export const Type = ({
  tokensList,
  currentStep,
  createTokenData,
  setTokenList,
  setCurrentStep,
  setCreateTokenData,
}: IModalCreateTokenTypeProps) => {
  const { user }: any = useAuth()
  const { apiRequest } = useApi()
  const { resetToken, resettingToken } = useTokenReset()
  const userContext = useAuth()

  console.log(userContext)
  const [input, setInput] = useState(
    createTokenData.type ? createTokenData.type : '',
  )
  const [tokenSelected, setTokenSelected] = useState<string | undefined>(
    undefined,
  )
  const [isLoading, setLoading] = useState<boolean>(false)
  const [isLoadingContext, setLoadingContext] = useState<boolean>(false)
  const [isButtonDisabled, setDisabledBtnState] = useState<boolean>(true)

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setInput(value)
  }

  const getGeneralTokensHandler = async () => {
    try {
      setLoading(true)
      const response = await apiRequest({
        endpoint: EEndpoint.analytics,
        path: EAnalyticsApiPathType.generalTokens,
        options: {
          headers: {
            'transaction-id': uuidv4(),
            Authorization: `${user.TokenType} ${user.AccessToken}`,
          },
          method: 'GET',
        },
      })
      if (response) {
        console.log('response: ', response)
        setTokenList(
          Object.keys(response).map((item) => {
            return {
              value: item,
              name: 'radio-token',
              label: item.split('_').join(' ').toLowerCase(),
              isChecked: false,
            }
          }),
        )
        return response
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.message.match(/403|User is not authorized/gm)) resetToken()
      else console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const radioGroupHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setTokenSelected(event.target.value)
  }

  const setTokenTypeHandler = async () => {
    try {
      if (isLoading) return

      if (tokenSelected) {
        setCreateTokenData((prev: Partial<IToken>) => ({
          ...prev,
          type: tokenSelected,
        }))

        setCurrentStep(currentStep + 1)
      } else if (input.length > 0) {
        setCreateTokenData((prev: Partial<IToken>) => ({
          ...prev,
          type: input.split(' ').join('_').toUpperCase(),
        }))

        setCurrentStep(currentStep + 1)
      }
    } catch (error: any) {
      console.log(error.message)
    }
  }

  useEffect(() => {
    console.log('tokensList: ', tokensList)
    console.log('createTokenData: ', createTokenData)
    setLoadingContext(true)
    setTimeout(() => {
      if (tokensList.find((token) => token.value === createTokenData.type)) {
        const checkedToken = (tokensList as any[]).find(
          (token) => token.value === createTokenData.type,
        )
        setTokenSelected(checkedToken.value)
        setTokenList([
          { ...checkedToken, isChecked: true },
          ...tokensList
            .filter((token) => token.value !== createTokenData.type)
            .map((item) => ({ ...item, isChecked: false })),
        ])
        setInput('')
      }
      setLoadingContext(false)
    }, 2000)
  }, [createTokenData])

  useEffect(() => {
    !tokensList.length && getGeneralTokensHandler()
  }, [tokensList, resettingToken])

  useEffect(() => {
    if (isLoading) return

    if (input.length > 0 || !!tokenSelected) {
      setDisabledBtnState(false)
    } else {
      setDisabledBtnState(true)
    }
  }, [isLoading, input, tokenSelected])

  return (
    <>
      <div className="token-create-data-container-header">
        <div className="token-create-data-container-header__title">
          Select type
        </div>
        <div className="token-create-data-container-header__desc">
          to create new token
        </div>
      </div>
      <div className="token-create-data-container-main">
        <div className="token-create-step token-create-step--type">
          <Input
            type="text"
            name="token"
            placeholder="Select token type"
            value={input}
            disabled={isLoading || !!tokenSelected}
            onInput={onInputChange}
          />

          {isLoading ? (
            <div className="transaction-activity-loading overview-component-loading">
              <Icon viewBox="0 0 24 24" icon={'cached'} size="90px" />
              <span>Data Loading</span>
            </div>
          ) : tokensList.length === 0 && !isLoading ? (
            <div className="transaction-activity-nodata">
              <Icon viewBox="0 0 24 24" icon={'cloudOff'} size="90px" />
              <span>No data available</span>
            </div>
          ) : isLoadingContext ? (
            <div className="transaction-activity-loading overview-component-loading">
              <Icon viewBox="0 0 24 24" icon={'cached'} size="90px" />
              <span>Data Loading</span>
            </div>
          ) : (
            <>
              <RadioButtonGroup
                label=""
                options={tokensList}
                onChange={radioGroupHandler}
                name="radio-token"
              />
            </>
          )}
        </div>
      </div>
      <div className="token-create-data-container-footer">
        <Button onClick={setTokenTypeHandler} disabled={isButtonDisabled}>
          Next
        </Button>
      </div>
    </>
  )
}
