/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import hljs from 'highlight.js'
import 'highlight.js/scss/github.scss'

import { useCreateService, useModal } from '@/core/context'
import { IServices } from '@/core/api'
import { convertCamelCaseToWords } from '@/core/utils'

import { SearchToken } from '@/components/smart'
import { Button, Icon } from '@/components/ui'
import { CreationProcess } from './CreationProcess'

import './style.scss'
import { BackArrow } from '@/components/simple'

export const ServiceInstanceCreate = () => {
  const navigate = useNavigate()
  const orgSchema = useRef(null)
  const { setModal } = useModal()

  const {
    creationStep,
    setCreationStep,
    createServiceData,
    setCreateServiceData,
  } = useCreateService()

  const currentService = history.state?.usr['service'] as IServices
  const [currentStep, setCurrentStep] = useState<number>(creationStep)
  const [stepList, setStepList] = useState<
    {
      index: number
      text: string
      name: string
    }[]
  >([])

  const [tokenSelected, setTokenSelected] = useState<string | undefined>(
    undefined,
  )
  const [dynamicState, setDynamicState] = useState<any>({})
  const [isButtonDisabled, setDisabledBtnState] = useState<boolean>(true)

  const onTextareaChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setDynamicState((prevState: any) => {
      const property = stepList[currentStep - 1].name
      return {
        ...prevState,
        [property]: event.target.value,
      }
    })
  }

  const searchTokenChangeHandler = useCallback(
    (_event: any, value: any) => {
      setTokenSelected(value)
    },
    [tokenSelected],
  )

  const getServiceOrgRegistrationSchema = (schema: string) => {
    return { serviceOrgRegisrationSchema: JSON.parse(schema) }
  }

  const nextHandler = () => {
    const property = stepList[currentStep - 1].name
    const payload = Object.assign(
      {},
      {
        [`${property}`]: tokenSelected,
      },
    )
    setCurrentStep(creationStep + 1)
    setCreationStep(creationStep + 1)
    setCreateServiceData((prev: any) => ({
      ...prev,
      ...payload,
    }))

    setTokenSelected(undefined)
  }

  const prevHandler = () => {
    setCurrentStep(creationStep - 1)
    setCreationStep(creationStep - 1)
    setTokenSelected(undefined)
  }

  const createServiceHandler = () => {
    setModal(
      <CreationProcess
        serviceId={currentService.serviceId}
        description={dynamicState['description'] as string}
        createServiceData={createServiceData}
      />,
    )
  }

  useEffect(() => {
    if (currentStep <= stepList.length) {
      const elements = Array.from(
        (orgSchema.current! as HTMLElement).children,
      ).filter((item: any) => {
        item.style.backgroundColor = 'transparent'
        return item.className === 'hljs-attr'
      })
      const element = elements.find(
        (el: any) =>
          el.outerText
            .toLowerCase()
            .indexOf(stepList[currentStep - 1].name.toLowerCase()) > -1,
      ) as HTMLElement

      if (element) {
        element.style.backgroundColor = 'rgba(148, 117, 255, 0.20)'
        element.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
    }
  }, [currentStep, orgSchema.current])

  useEffect(() => {
    if (currentStep !== stepList.length && !tokenSelected) {
      setDisabledBtnState(true)
    } else {
      setDisabledBtnState(false)
    }
  }, [tokenSelected])

  useEffect(() => {
    const orgSchema = getServiceOrgRegistrationSchema(
      currentService.serviceOrgRegisrationSchema,
    ) as {
      serviceOrgRegisrationSchema: {
        type: string
        properties: any
        required: string[]
      }
    }
    const _stepList = orgSchema.serviceOrgRegisrationSchema.required.map(
      (item, i) => ({
        index: i + 1,
        text: convertCamelCaseToWords(item),
        name: item,
      }),
    )
    setStepList([
      ..._stepList,
      {
        index: _stepList.length + 1,
        text: 'Description',
        name: 'description',
      },
    ])
  }, [])

  useEffect(() => {
    hljs.addPlugin({
      'after:highlightElement': ({
        el = orgSchema.current! as HTMLElement,
        result,
      }) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        el.dataset.language = result.language
      },
    })
    hljs.highlightElement(orgSchema.current!)
  })

  useEffect(() => {
    if (stepList.length) {
      const initialState = stepList.reduce((acc: any, current) => {
        if (!(current.name.toLowerCase().indexOf('token') > -1)) {
          acc[current.name] = ''
          return acc
        } else {
          return acc
        }
      }, {})

      setDynamicState(initialState)
    }
  }, [stepList.length])

  useEffect(() => {
    console.log()
    if (dynamicState[stepList[currentStep - 1]?.name]?.length > 0) {
      setDisabledBtnState(false)
    }
  }, [dynamicState[stepList[currentStep - 1]?.name]])

  return (
    <div className="service-instances">
      <div className="service-instances__back">
        <Button onClick={() => navigate(-1)}>
          <BackArrow />
        </Button>
      </div>

      <div className="service-create">
        <div className="title">
          Service: <span>{currentService.serviceName}</span>
        </div>
        <div className="description" title={currentService.description}>
          {currentService.description}
        </div>

        {
          <pre>
            <code
              className="!whitespace-pre hljs language-json"
              ref={orgSchema}
            >
              {JSON.stringify(
                getServiceOrgRegistrationSchema(
                  currentService.serviceOrgRegisrationSchema,
                ),
                null,
                2,
              )}
            </code>
          </pre>
        }

        <div className="service-create-instance">
          <div className="service-create-instance-content">
            <div className="service-create-instance-content__asside">
              <div className="service-create-instance-content__title">
                To create instance you need:
              </div>
              <ul className="service-create-instance-step-list">
                {stepList.map((item, index) => {
                  return (
                    <li
                      key={index}
                      className={classNames(
                        'service-create-instance-step-list__item',
                        {
                          isActive: currentStep === item.index,
                          isDone: currentStep > item.index,
                        },
                      )}
                      onClick={() => {
                        if (currentStep > item.index) {
                          setCurrentStep(item.index)
                          setCreationStep(item.index)
                          setTokenSelected(undefined)
                        }
                      }}
                    >
                      <div className="service-create-instance-step-list__index">
                        {currentStep > item.index ? (
                          <Icon icon={'check'} />
                        ) : (
                          item.index
                        )}
                      </div>
                      <div className="service-create-instance-step-list__text">
                        {item.text}
                      </div>
                      <Icon icon={'arrowForward'} />
                    </li>
                  )
                })}
              </ul>
            </div>

            <div className="service-create-instance-container">
              <div className="service-create-instance-container-header">
                <div className="service-create-instance-container-header__title">
                  {stepList[currentStep - 1]?.text}
                </div>
              </div>
              <div className="service-create-instance-container-content">
                {currentStep > stepList.length ? (
                  <pre>
                    <code
                      className="!whitespace-pre hljs language-json"
                      style={{
                        height: 'auto',
                        maxHeight: 'initial',
                      }}
                    >
                      {JSON.stringify(
                        Object.assign(
                          {
                            serviceId: currentService.serviceId,
                            description: dynamicState['description'],
                          },
                          {
                            serviceRequest: createServiceData,
                          },
                        ),
                        null,
                        2,
                      )}
                    </code>
                  </pre>
                ) : !(
                    stepList[currentStep - 1].name
                      .toLowerCase()
                      .indexOf('token') > -1
                  ) ? (
                  <div className="service-create-instance-container-content__description">
                    <form>
                      <fieldset>
                        <legend>{stepList[currentStep - 1].text}</legend>
                        <textarea
                          name="proposition"
                          id="proposition"
                          placeholder=""
                          value={dynamicState[stepList[currentStep - 1].name]}
                          onChange={onTextareaChange}
                        ></textarea>
                      </fieldset>
                    </form>
                  </div>
                ) : (
                  <SearchToken
                    stepNumber={currentStep}
                    hasCreateToken={true}
                    radioGroupName={'create-token'}
                    onChange={searchTokenChangeHandler}
                  />
                )}
              </div>
            </div>
          </div>

          <div className="service-create-instance-navigate">
            <Button onClick={prevHandler} disabled={currentStep === 1}>
              Prev
            </Button>

            {currentStep <= stepList.length ? (
              <Button onClick={nextHandler} disabled={isButtonDisabled}>
                Next
              </Button>
            ) : (
              <Button onClick={createServiceHandler}>Create</Button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
