/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createContext, useContext, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { IUserLoginRequest } from '../api/types'
import { useLocalStorage } from '../hooks'

// @ts-ignore
const AuthContext = createContext()

export const AuthProvider = ({ children }: any) => {
  const [user, setUser] = useLocalStorage('user', null)
  const navigate = useNavigate()

  const login = async (data: IUserLoginRequest) => {
    setUser(data)
    navigate('/dashboard/overview')
  }

  const logout = () => {
    setUser(null)
    navigate('/login', { replace: true })
  }

  const updateAuthCredentials = (data: Partial<IUserLoginRequest>) => {
    setUser(data)
  }

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
      updateAuthCredentials,
    }),
    [user],
  )
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  return useContext(AuthContext)
}
