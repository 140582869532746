/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react'

import { IToken } from '@/core/api'

import { Button } from '@/components/ui'

interface IModalCreateTokenDescriptionProps {
  currentStep: number
  createTokenData: Partial<IToken>
  setCurrentStep: Dispatch<SetStateAction<number>>
  setCreateTokenData: Dispatch<SetStateAction<Partial<IToken>>>
}
export const Description = ({
  currentStep,
  createTokenData,
  setCurrentStep,
  setCreateTokenData,
}: IModalCreateTokenDescriptionProps) => {
  const [description, setDescription] = useState<string>(
    createTokenData.description ? createTokenData.description : '',
  )

  const onTextareaChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setDescription(event.target.value)
  }

  const setTokenDescriptionHandler = () => {
    setCreateTokenData((prev: Partial<IToken>) => ({
      ...prev,
      description,
    }))
    setCurrentStep(currentStep + 1)
  }
  return (
    <>
      <div className="token-create-data-container-header">
        <div className="token-create-data-container-header__title">
          Choose description
        </div>
        <div className="token-create-data-container-header__desc">
          to create new token
        </div>
      </div>
      <div className="token-create-data-container-main">
        <div className="token-create-step token-create-step--description">
          <form>
            <fieldset>
              <legend>Type description</legend>
              <textarea
                name="proposition"
                id="proposition"
                placeholder=""
                value={description}
                onChange={onTextareaChange}
              ></textarea>
            </fieldset>
          </form>
        </div>
      </div>
      <div className="token-create-data-container-footer">
        <Button
          disabled={!description.length}
          onClick={setTokenDescriptionHandler}
        >
          Next
        </Button>
      </div>
    </>
  )
}
