import './style.scss'

export const ErrorPage = () => {
  return (
    <div className="error-page">
      <div className="error-page__layer">
        <img
          className="error-page__logo"
          src="https://s3-alpha-sig.figma.com/img/21d0/b77e/94bce4e9d8628c4d226898e7ff88992e?Expires=1698019200&Signature=abrnQQY551W4kMoXPiKdfU4MHbx7xJ-XbdtO2zGgqLYueo1fOnGoIXQOo892o1sFo6yD9H0qtl48~cefuFJN2w1ZbTBIAOEftPbZIgCcAIjYU-yqqbtGGQqmfuxSncYaijMb2NXcVspxevEqz-B-~pzAwDTBPexjo1maBW~uLccCz~CQ2bdursyFM1PhvAV9B-QUeS2Gw2EFM20zlV75FGxk~t2-fvOJc4u4K3VcK9YRJ4xCMnWA1p2GIW7HY3D01tXOHfYYrP4lzBBlhAenEzCQma29qP0oPUQ1cD3PlXFDpSdYEc2y-f6yZDWa26OxoDfZqemu6XWkG9x9by0rIA__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4"
          alt="w3"
        />
        <div className="error-page__description">
          ERROR, OOPS SOMETHING WENT WRONG
        </div>
      </div>
    </div>
  )
}
