import { HTMLProps, useRef, useEffect } from 'react'

export const IndeterminateCheckbox = ({
  indeterminate,
  name,
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) => {
  const ref = useRef<HTMLInputElement>(null!)

  useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate
    }
  }, [ref, indeterminate])

  return <input name={name} id={name} type="checkbox" ref={ref} {...rest} />
}
