/* eslint-disable @typescript-eslint/no-explicit-any */
import { v4 as uuidv4 } from 'uuid'
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useApi, useTokenReset } from '@/core/hooks'
import { useAuth, useModal } from '@/core/context'
import { Button, EButtonType } from '@/components/ui'
import {
  EEndpoint,
  EServicesApiPathType,
  IServiceCreate,
  IServices,
} from '@/core/api'
import { convertCamelCaseToWords } from '@/core/utils'

import './style.scss'
import { removeUndefinedValuesFromObject } from '@/core/utils/removeUndefinedValuesFromObject'
import { SearchToken } from '@/components/smart/SearchToken'
import { DataLoading } from '@/components/smart'
import { BackArrow } from '@/components/simple'

export const ServiceInstanceTest = () => {
  const navigate = useNavigate()
  const { user }: any = useAuth()
  const { apiRequest } = useApi()
  const { setModal } = useModal()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { resetToken, resettingToken } = useTokenReset()
  const serviceSchema = useRef(null)

  const currentService = history.state?.usr['service'] as IServices

  const [serviceList, setServiceList] = useState<any[]>([])
  const [dynamicState, setDynamicState] = useState<any>({})
  const [requiredFields, setRequiredFields] = useState<string[]>([])
  const [runServiceLoading, setRunServiceLoading] = useState<boolean>(false)
  const [tokenIdDescriptionLoading, setTokenIdDescriptionLoading] =
    useState<boolean>(false)

  const [responseTokenId, setResponseToken] = useState<string | null>(null)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [responseContent, setResponseContent] = useState<any | null>(null)
  const [curStep, setStep] = useState(1)

  const getServiceSchema = (schema: string) => {
    return { serviceSchema: JSON.parse(schema) }
  }

  const checkRequiredFields = (serviceRequest: any) => {
    const res: string[] = []

    requiredFields.forEach((item) => {
      if (Object.prototype.hasOwnProperty.call(serviceRequest, item)) {
        res.push(item)
      }
    })

    return res.length === requiredFields.length
  }

  const changeState = (key: string, value: any) => {
    setDynamicState((prev: any) => {
      return {
        ...prev,
        [key]: value,
      }
    })
  }

  const onCheckboxChangeHandler = useCallback(
    (_e: ChangeEvent<HTMLInputElement>, dStateKey: string) => {
      changeState && changeState(dStateKey, !dynamicState[dStateKey])
    },
    [changeState],
  )

  const onSelectChangeHandler = useCallback(
    (
      e: ChangeEvent<HTMLSelectElement>,
      dStateKey: string,
      selectList: string[],
    ) => {
      changeState && changeState(dStateKey, selectList[e.target.selectedIndex])
    },
    [changeState],
  )

  const onInputChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>, dStateKey: string) => {
      const { value } = event.target
      changeState && changeState(dStateKey, value)
    },
    [changeState],
  )

  const searchTokenHandler = useCallback((event: any, key: string) => {
    onInputChangeHandler(event, key)
  }, [])

  const runServiceHandler = async (
    payload: Pick<IServiceCreate, 'serviceId' | 'serviceRequest'>,
  ) => {
    const serviceRequest = removeUndefinedValuesFromObject(
      payload.serviceRequest,
    )

    const isValid = checkRequiredFields(serviceRequest)

    if (isValid) {
      const _payload = {
        serviceId: payload.serviceId,
        serviceRequest,
      }

      console.log('_payload: ', _payload)
      try {
        setStep(2)

        setRunServiceLoading(true)
        const { response } = await apiRequest({
          endpoint: EEndpoint.services,
          path: EServicesApiPathType.callService,
          options: {
            headers: {
              'transaction-id': uuidv4(),
              Authorization: `${user.TokenType} ${user.AccessToken}`,
            },
            method: 'POST',
            body: JSON.stringify({ ..._payload }),
          },
        })

        if (response) {
          const { tokenId, status } = response
          console.log({ tokenId, status })
          setResponseToken(tokenId)
        } else if (response === 'POLICY_VALIDATION') {
          console.log('Daily query limits was reached!')
        }
      } catch (error: any) {
        console.log(error)
        setStep(1)
        if (error.message.match(/403|User is not authorized/gm)) {
          resetToken()
        } else throw new Error(error.message)
      } finally {
        setRunServiceLoading(false)
      }
    } else {
      setModal(<>Need all required fields!</>)
    }
  }

  const getTokenIdDescription = async (tokenId: string) => {
    try {
      setTokenIdDescriptionLoading(true)
      const { fileContent } = await retryGetTokenIdDescription(tokenId)
      setResponseContent(fileContent)
    } catch (error) {
      console.error(error)
    } finally {
      setTokenIdDescriptionLoading(false)
    }
  }
  const retryGetTokenIdDescription = async (
    tokenId: string,
    retries = 20,
  ): Promise<any> => {
    try {
      const response = await apiRequest({
        endpoint: EEndpoint.secureStorage,
        path: tokenId,
        options: {
          headers: {
            'transaction-id': uuidv4(),
            Authorization: `${user.TokenType} ${user.AccessToken}`,
          },
          method: 'GET',
        },
      })
      return response
    } catch (error: any) {
      if (error.message.match(/403|User is not authorized/gm)) {
        resetToken()
      } else if (retries > 0) {
        return new Promise((resolve, reject) => {
          setTimeout(async () => {
            try {
              const result = await retryGetTokenIdDescription(
                tokenId,
                retries - 1,
              )
              resolve(result)
            } catch (innerError) {
              reject(innerError)
            }
          }, 5000)
        })
      } else {
        throw error
      }
    }
  }

  useEffect(() => {
    if (currentService?.serviceSchema) {
      const schema = getServiceSchema(currentService?.serviceSchema) as {
        serviceSchema: {
          type: string
          properties: any
          required: string[]
        }
      }

      const _serviceList = Object.keys(schema.serviceSchema.properties).map(
        (property) => {
          if (
            Object.prototype.hasOwnProperty.call(
              schema.serviceSchema.properties[property],
              'enum',
            )
          ) {
            schema.serviceSchema.properties[property].enum.unshift('')
          }

          return {
            key: property,
            name: convertCamelCaseToWords(property),
            isToken: property.toLowerCase().indexOf('token') > -1,
            required: schema.serviceSchema.required.some(
              (item) => item === property,
            ),
            ...schema.serviceSchema.properties[property],
          }
        },
      )

      const initialState = _serviceList.reduce((acc: any, current) => {
        acc[current.key] = undefined
        return acc
      }, {})

      setRequiredFields(schema.serviceSchema.required)
      setServiceList(_serviceList)
      setDynamicState(initialState)
    }
  }, [])

  useEffect(() => {
    if (!responseTokenId) return

    getTokenIdDescription(responseTokenId)
  }, [responseTokenId])

  return (
    <div className="service-instances">
      <div className="service-instances__back">
        <Button onClick={() => navigate(-1)}>
          <BackArrow />
        </Button>
      </div>
      {/* {JSON.stringify(dynamicState)} */}
      <div className="service-test">
        <div className="title">
          Service: <span>{currentService.serviceName}</span>
        </div>
        <div className="description" title={currentService.description}>
          {currentService.description}
        </div>

        {currentService.serviceSchema && (
          <pre>
            <code
              className="!whitespace-pre hljs language-json"
              ref={serviceSchema}
            >
              {JSON.stringify(
                getServiceSchema(currentService.serviceSchema),
                null,
                2,
              )}
            </code>
          </pre>
        )}

        {curStep === 1 && (
          <>
            <form className="form">
              <fieldset>
                <ul className="form-list">
                  {serviceList.length &&
                    serviceList.map((item) => {
                      return (
                        <li className="form-list__item" key={item.key}>
                          {Object.prototype.hasOwnProperty.call(
                            item,
                            'enum',
                          ) ? (
                            <>
                              <label>
                                {item.name} {item.required ? '*' : ''}
                              </label>
                              <select
                                onChange={(e) =>
                                  onSelectChangeHandler(e, item.key, item.enum)
                                }
                                value={dynamicState[item.key]}
                              >
                                {item.enum.map(
                                  (option: string, index: number) => {
                                    return (
                                      <option value={option} key={index}>
                                        {option}
                                      </option>
                                    )
                                  },
                                )}
                              </select>
                            </>
                          ) : item.type === 'boolean' ? (
                            <>
                              <label>
                                {item.name} {item.required ? '*' : ''}
                              </label>
                              <input
                                type="checkbox"
                                checked={dynamicState[item.key]}
                                onChange={(e) =>
                                  onCheckboxChangeHandler(e, item.key)
                                }
                              />
                            </>
                          ) : item.key.toLowerCase().indexOf('token') > -1 ? (
                            <SearchToken
                              onChange={(e) => searchTokenHandler(e, item.key)}
                              radioGroupName={`${item.key}-token`}
                            />
                          ) : (
                            <>
                              <label>
                                {item.name} {item.required ? '*' : ''}
                              </label>
                              <input
                                type="text"
                                value={dynamicState[item.key]}
                                onInput={(e: ChangeEvent<HTMLInputElement>) =>
                                  onInputChangeHandler(e, item.key)
                                }
                              />
                            </>
                          )}
                        </li>
                      )
                    })}
                </ul>
              </fieldset>
            </form>

            <div className="btn-wrapper">
              <Button
                type={EButtonType.button}
                onClick={() =>
                  navigate('..', {
                    state: {
                      service: {
                        ...currentService,
                      },
                    },
                  })
                }
              >
                Cancel
              </Button>
              <Button
                type={EButtonType.button}
                onClick={() =>
                  runServiceHandler({
                    serviceId: currentService.serviceId,
                    serviceRequest: dynamicState,
                  })
                }
              >
                Run Service
              </Button>
            </div>
          </>
        )}

        {curStep === 2 && !runServiceLoading && (
          <>
            <form className="form">
              <fieldset>
                <ul className="form-list form-list--step2">
                  <li className="form-list__item">
                    <label>Response Token ID:</label>
                    <div className="responseToken">{responseTokenId}</div>
                  </li>
                  <li className="form-list__item">
                    <label>Content:</label>
                    <div className="responseContent">
                      {responseContent}
                      <DataLoading isLoading={tokenIdDescriptionLoading} />
                    </div>
                  </li>
                </ul>
              </fieldset>
            </form>
          </>
        )}

        <DataLoading
          isLoading={runServiceLoading}
          text={'Data Generation in Progress. Please Keep This Tab Open'}
        />
      </div>
    </div>
  )
}
