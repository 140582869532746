/* eslint-disable @typescript-eslint/no-explicit-any */
import { v4 as uuidv4 } from 'uuid'
import { useEffect, useState } from 'react'
import {
  ColumnDef,
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import hljs from 'highlight.js'
import 'highlight.js/scss/github.scss'

import { useAuth, useModal } from '@/core/context'
import { useApi, useTokenReset } from '@/core/hooks'
import { EEndpoint, ETokenStatus, IToken } from '@/core/api'
import { getFormatDate } from '@/core/utils'

import { Button, Icon } from '@/components/ui'
import { IndeterminateCheckbox } from '@/components/simple'
import { FilterPopover } from './FilterPopover'

import './style.scss'

interface ITanstackTableProps {
  isLoading: boolean
  tableData: IToken[] | null
  rowSelection: any
  setRowSelection: any
}

export const TanstackTable = ({
  isLoading,
  tableData,
  rowSelection,
  setRowSelection,
}: ITanstackTableProps) => {
  const { user }: any = useAuth()
  const { apiRequest } = useApi()
  const { setModal } = useModal()
  const { resetToken } = useTokenReset()
  const [sorting, setSorting] = useState<SortingState>([])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [columnFilters, setColumnFilters] = useState<any[]>([])

  interface ITokenInformationProps {
    tokenId: string
  }
  const TokenInformation = ({ tokenId }: ITokenInformationProps) => {
    const [fileInfo, setFileInfo] = useState('')
    const [isLoading, setLoading] = useState<boolean>(false)

    const getTokenInformation = async () => {
      try {
        setLoading(true)
        const response = await apiRequest({
          endpoint: EEndpoint.secureStorage,
          path: tokenId,
          options: {
            headers: {
              'transaction-id': uuidv4(),
              Authorization: `${user.TokenType} ${user.AccessToken}`,
            },
            method: 'GET',
          },
        })
        const { fileContent } = response
        setFileInfo(fileContent)
      } catch (error: any) {
        if (error.message.match(/403|User is not authorized/gm)) {
          resetToken()
        }
      } finally {
        setLoading(false)
      }
    }

    useEffect(() => {
      hljs.highlightAll()
    })

    useEffect(() => {
      getTokenInformation()
    }, [])

    return (
      <div className="file-information">
        {isLoading ? (
          <div className="transaction-activity-loading overview-component-loading">
            <Icon viewBox="0 0 24 24" icon={'cached'} size="90px" />
            <span>Data Loading</span>
          </div>
        ) : (
          <>
            <h3 className="file-information__title">File information</h3>
            <div className="file-information__content">
              <pre>
                <code className="!whitespace-pre hljs language-json">
                  {fileInfo}
                </code>
              </pre>
            </div>
          </>
        )}
      </div>
    )
  }

  const tokenIdClickHandler = async (e: any, tokenId: string) => {
    e.preventDefault()
    setModal(<TokenInformation tokenId={tokenId} />)
  }

  const columns: ColumnDef<IToken>[] = [
    {
      id: 'select',
      // size: 50,
      header: ({ table }) => {
        return (
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllPageRowsSelected(),
              indeterminate: table.getIsSomePageRowsSelected(),
              onChange: table.getToggleAllPageRowsSelectedHandler(),
              name: 'select',
            }}
          />
        )
      },
      cell: ({ row }) => {
        return (
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        )
      },
    },
    {
      accessorKey: 'tokenId',
      header: 'Token Id',
      // size: 177,
      cell: (props: any) => {
        return Object.prototype.hasOwnProperty.call(
          props.row.original,
          'tokenIdSource',
        ) ? (
          <>{props.getValue()}</>
        ) : (
          <a href="" onClick={(e) => tokenIdClickHandler(e, props.getValue())}>
            {props.getValue()}
          </a>
        )
      },
    },
    {
      accessorKey: 'tokenIdSource',
      header: 'Token Id Source',
      // size: 177,
      cell: (props: any) => {
        return (
          <a href="" onClick={(e) => tokenIdClickHandler(e, props.getValue())}>
            {props.getValue()}
          </a>
        )
      },
    },
    {
      accessorKey: 'description',
      header: 'Token Description',
      // size: 230,
      cell: (props: any) => {
        return <>{props.getValue()}</>
      },
    },
    {
      accessorKey: 'accountIssuer',
      header: 'Assigned Account',
      // size: 180,
      cell: (props: any) => {
        return <>{props.getValue()}</>
      },
    },
    {
      accessorKey: 'accountId',
      header: 'Account id',
      // size: 180,
      cell: (props: any) => {
        return <>{props.getValue()}</>
      },
    },
    {
      accessorKey: 'date',
      header: 'Date',
      // size: 160,
      cell: (props: any) => {
        return <>{getFormatDate(props.getValue(), 'LLLL c, yyyy')}</>
      },
    },
    {
      accessorKey: 'type',
      header: 'Type',
      id: 'type',
      // size: 175,
      cell: (props: any) => {
        return <>{props.getValue()}</>
      },
    },
    {
      accessorKey: 'status',
      header: 'Status',
      id: 'status',
      enableColumnFilter: true,
      filterFn: (row, columnId, filterStatuses) => {
        if (filterStatuses.length === 0) return true
        const status = row.getValue(columnId)
        return filterStatuses.includes(status)
      },
      cell: (props: any) => {
        switch (props.getValue()) {
          case ETokenStatus.MINTED:
            return (
              <div className={'info-status info-status--active'}>Active</div>
            )
          case ETokenStatus.DEPRECATED:
            return (
              <div className={'info-status info-status--deprecated'}>
                Deprecated
              </div>
            )
          default:
            return (
              <div className={'info-status info-status--inactive'}>
                Not Active
              </div>
            )
        }
      },
    },
  ]
  const table = useReactTable({
    data: tableData ? (tableData as IToken[]) : [],
    columns,
    state: {
      sorting,
      rowSelection,
      columnFilters,
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),

    enableSorting: true,
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),

    enableRowSelection: true,
    enableMultiRowSelection: false,
    onRowSelectionChange: setRowSelection,
  })

  return (
    <>
      <FilterPopover
        columnFilters={columnFilters}
        setColumnFilters={setColumnFilters}
      />

      <div className="table-wrapper">
        <table>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      className={`${header.id}`}
                      key={header.id}
                      style={{ width: header.getSize() }}
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      {flexRender(
                        header.column.columnDef.header as string,
                        header.getContext(),
                      )}
                      {{
                        asc: ' ↑',
                        desc: ' ↓',
                      }[header.column.getIsSorted() as string] ?? null}
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>

          <tbody className={`${isLoading ? 'table-body--loading' : ''}`}>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td
                    className={`${cell.id.slice(2)}`}
                    key={cell.id}
                    // style={{ width: cell.column.getSize() }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>

        {!isLoading && !tableData && (
          <div className="tokens-list-nodata">
            <span>No data available</span>
            <Button>Create Token</Button>
          </div>
        )}

        {isLoading && (
          <div className="tokens-list-loading">
            <Icon viewBox="0 0 24 24" icon={'cached'} size="90px" />
            <span>Data Loading</span>
          </div>
        )}
      </div>
    </>
  )
}
