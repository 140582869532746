import { useEffect, useState } from 'react'

import { EButtonType, Icon } from '@/components/ui'

import './style.scss'

interface IMultiSelectProps {
  listData: string[]
  setListData: React.Dispatch<React.SetStateAction<string[]>>
  data: string[]
}

export const MultiSelect = ({
  listData,
  setListData,
  data,
}: IMultiSelectProps) => {
  const [allDataList, setAllDataList] = useState<
    {
      name: string
      isDisabled: boolean
    }[]
  >([])
  const [isDropdownOpen, setDropdownOpen] = useState<boolean>(false)

  const editUserRolesHandler = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault()
    setDropdownOpen(!isDropdownOpen)
  }

  const addDataHandler = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    name: string,
  ) => {
    e.preventDefault()

    setAllDataList([
      ...allDataList.filter((item) => item.name !== name),
      {
        name,
        isDisabled: true,
      },
    ])

    setListData([...listData, name])
  }

  const removeListItemHandler = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    name: string,
  ) => {
    e.preventDefault()
    console.log(name)
    setAllDataList([
      {
        name,
        isDisabled: false,
      },
      ...allDataList.filter((item) => item.name !== name),
    ])

    setListData([...listData.filter((item) => item !== name)])
  }

  useEffect(() => {
    if (isDropdownOpen) {
      setAllDataList(
        data.map((item) => ({
          name: item,
          isDisabled: listData.includes(item),
        })),
      )
      console.log('allRolesList:', allDataList)
    }
  }, [isDropdownOpen])

  return (
    <div className="multiselect-wrapper">
      <div className="multiselect-container">
        <button
          className="multiselect-container-button"
          onClick={editUserRolesHandler}
        >
          <Icon icon={`${isDropdownOpen ? 'close' : 'edit'}`} size={'12px'} />
        </button>

        <ul className="multiselect-list">
          {listData.map((item, index) => {
            return (
              <li className="multiselect-list__item" key={index}>
                <div
                  className="multiselect-list__remove"
                  onClick={(e) => removeListItemHandler(e, item)}
                >
                  <Icon icon={'close'} />
                </div>
                {item}
              </li>
            )
          })}
        </ul>
      </div>

      {isDropdownOpen && (
        <div className="multiselect-dropdown">
          <ul className="multiselect-dropdown-list">
            {allDataList.map((item, index) => {
              return (
                <li key={index}>
                  <button
                    type={EButtonType.button}
                    disabled={item.isDisabled}
                    onClick={(e) => addDataHandler(e, item.name)}
                  >
                    <Icon icon={'addCircle'} size={'18px'} />
                    <span>{item.name}</span>
                  </button>
                </li>
              )
            })}
          </ul>
        </div>
      )}
    </div>
  )
}
