/* eslint-disable @typescript-eslint/no-explicit-any */
import { v4 as uuidv4 } from 'uuid'
import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react'

import { useAuth, useModal } from '@/core/context'
import { useApi, useTokenReset } from '@/core/hooks'
import { EEndpoint, EUserApiPathType } from '@/core/api'

import { Button, EButtonType, Input } from '@/components/ui'

import { MultiSelect } from '../../MultiSelect'

import './style.scss'

interface ICreateRoleProps {
  permissions: string[]
  setRoleChange: Dispatch<SetStateAction<boolean>>
}

export const CreateRole = ({
  permissions,
  setRoleChange,
}: ICreateRoleProps) => {
  const { user }: any = useAuth()
  const { apiRequest } = useApi()
  const { resetToken } = useTokenReset()
  const { setModal } = useModal()

  const [rolePermissions, setRolePermissions] = useState<string[]>([])
  const [input, setInput] = useState({
    roleName: '',
    description: '',
  })

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const createRoleHandler = async () => {
    const payload = {
      roleName: input.roleName,
      description: input.description,
      permissions: rolePermissions,
    }

    try {
      const response = await apiRequest({
        getBaseUrl: true,
        endpoint: EEndpoint.user,
        path: `${EUserApiPathType.role}/create`,
        options: {
          headers: {
            'transaction-id': uuidv4(),
            Authorization: `${user.TokenType} ${user.AccessToken}`,
          },
          method: 'POST',
          body: JSON.stringify(payload),
        },
      })

      console.log(response)

      setRoleChange(true)
      setModal(null)
    } catch (error: any) {
      if (error.message.match(/403|User is not authorized/gm)) resetToken()
      else console.log(error.message)
    }
  }

  return (
    <div className="create-role">
      <div className="title">Create role</div>

      <div className="form-wrapper">
        <form>
          <fieldset>
            <ul className="create-role-form-list">
              <li className="create-role-form-list__item">
                <label>Role Name</label>
                <Input
                  type="text"
                  name="roleName"
                  value={input.roleName}
                  onChange={onInputChange}
                />
              </li>
              <li className="create-role-form-list__item">
                <label>Role Description</label>
                <Input
                  type="text"
                  name="description"
                  value={input.description}
                  onChange={onInputChange}
                />
              </li>

              <li>
                <label>Permissions</label>

                <MultiSelect
                  listData={rolePermissions}
                  setListData={setRolePermissions}
                  data={permissions}
                />
              </li>
            </ul>
          </fieldset>
        </form>

        <Button type={EButtonType.submit} onClick={createRoleHandler}>
          Create role
        </Button>
      </div>
    </div>
  )
}
