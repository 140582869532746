/* eslint-disable @typescript-eslint/no-explicit-any */
import { useNavigate } from 'react-router-dom'
import { AllowedStatus, DeniedStatus, UserIcon } from '@/components/simple'

import './style.scss'

enum EManagementListType {
  user = 'user',
  roles = 'roles',
  account = 'account',
}

enum EManagementListItemStatus {
  denied = 'denied',
  allowed = 'allowed',
}

const managementList = [
  {
    type: EManagementListType.user,
    route: './user',
    title: 'User Management',
    description: 'Mange the users inside your organizations',
    status: EManagementListItemStatus.allowed,
  },
  {
    type: EManagementListType.roles,
    route: './roles',
    title: 'Roles Management',
    description: 'Manage the roles inside your orgazations',
    status: EManagementListItemStatus.allowed,
  },
  {
    type: EManagementListType.account,
    route: './account',
    title: 'Account Management',
    description: 'Manage the accounts access of your end to end users',
    status: EManagementListItemStatus.denied,
  },
]

export const Management = () => {
  const navigate = useNavigate()

  const linkHandler = (e: any, route: string) => {
    e.preventDefault()
    navigate(route)
  }

  return (
    <div className="management">
      <div className="management__title">Management</div>
      <ul className="management-list">
        {managementList.map((item, index) => {
          return (
            <li
              className="management-list__item"
              key={index}
              onClick={(e) => linkHandler(e, item.route)}
            >
              <div className="management-list__icon">
                <UserIcon />
                <div
                  className={`management-list__icon-status management-list__icon-status--${item.status}`}
                >
                  {item.status === EManagementListItemStatus.allowed && (
                    <AllowedStatus />
                  )}
                  {item.status === EManagementListItemStatus.denied && (
                    <DeniedStatus />
                  )}
                </div>
              </div>

              <div className="management-list__info">
                <div className="management-list__title">{item.title}</div>
                <div className="management-list__description">
                  {item.description}
                </div>
              </div>
            </li>
          )
        })}
      </ul>
    </div>
  )
}
