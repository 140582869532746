/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { useAuth } from '@/core/context'
import { useApi, useTokenReset } from '@/core/hooks'
import {
  EEndpoint,
  IAnalyticsResponse,
  EAnalyticsResponseStatName,
} from '@/core/api'

import { Loading } from '@/components/ui'

import cn from 'classnames'

export const Info = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { user }: any = useAuth()
  const { apiRequest } = useApi()
  const { resetToken, resettingToken } = useTokenReset()

  const [analyticsData, setAnalyticsData] = useState<IAnalyticsResponse[]>([])
  const [isLoading, setLoading] = useState<boolean>(false)
  const infoKeys = [
    EAnalyticsResponseStatName.totalAccounts,
    EAnalyticsResponseStatName.totalTokens,
    EAnalyticsResponseStatName.totalTransactions,
  ]

  const getAnalyticsHandler = async () => {
    try {
      setLoading(true)
      const response = (await apiRequest({
        endpoint: EEndpoint.analytics,
        options: {
          headers: {
            'transaction-id': uuidv4(),
            Authorization: `${user.TokenType} ${user.AccessToken}`,
          },
          method: 'GET',
        },
      })) as IAnalyticsResponse[]
      if (response) {
        console.log('response: ', response)
        setAnalyticsData([...analyticsData, ...response])
        return response
      }
    } catch (error: any) {
      if (error.message.match(/403|User is not authorized/gm)) resetToken()
      else console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const createInfoByAnalyticsData = (
    statName: EAnalyticsResponseStatName,
    key: number | string,
  ) => {
    let title = ''

    switch (statName) {
      case EAnalyticsResponseStatName.totalAccounts:
        title = 'Total Accounts'
        break
      case EAnalyticsResponseStatName.totalTokens:
        title = 'Total Tokens'
        break
      case EAnalyticsResponseStatName.totalTransactions:
        title = 'Total Transactions'
        break
    }
    return (
      <div className="overview-info__item" key={key}>
        <div className="overview-info__title">{title}</div>
        <div className={cn('overview-info__value', { 'is-loaded': isLoading })}>
          {isLoading ? (
            <Loading />
          ) : analyticsData.length ? (
            analyticsData.filter((item) => item.statsName === statName)[0].count
          ) : (
            0
          )}
        </div>
      </div>
    )
  }

  useEffect(() => {
    getAnalyticsHandler()
  }, [resettingToken])

  return (
    <div className="overview-info">
      <div className="overview-info-container">
        {infoKeys.map((item, index) => createInfoByAnalyticsData(item, index))}
      </div>
    </div>
  )
}
