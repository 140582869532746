/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from 'react'
import { useMatch, useNavigate } from 'react-router-dom'
import { CreateTokenProvider } from '@/core/context'

import { Icon } from '@/components/ui'
import { TokensList } from './TokensList'
import { TokenCreate } from './TokenCreate'

import './style.scss'
import { AllowedStatus, DeniedStatus, UserIcon } from '@/components/simple'

enum ETokenStorageListType {
  listing = 'listing',
  analytics = 'analytics',
}
enum ETokenStorageListItemStatus {
  denied = 'denied',
  allowed = 'allowed',
}

export const Tokens = () => {
  const isTokensRender = useMatch('/dashboard/tokens')
  const isTokensListRender = useMatch('/dashboard/tokens/list')
  const isTokenCreateRender = useMatch('/dashboard/tokens/list/create')
  const navigate = useNavigate()

  const tokenStorageList = [
    {
      type: ETokenStorageListType.listing,
      title: 'Token Listing',
      description: 'short description',
      status: ETokenStorageListItemStatus.allowed,
    },
    {
      type: ETokenStorageListType.analytics,
      title: 'Analytics',
      description: 'short description',
      status: ETokenStorageListItemStatus.denied,
    },
  ]

  return (
    <>
      {isTokensListRender && <TokensList />}
      {isTokenCreateRender && <TokenCreate />}
      {isTokensRender && (
        <div className="tokens">
          <div className="tokens__title">Storage</div>
          <ul className="tokens-storage-list">
            {tokenStorageList.map((item, index) => {
              return (
                <li
                  className="tokens-storage-list__item"
                  key={index}
                  onClick={() => {
                    switch (item.type) {
                      case ETokenStorageListType.listing:
                        navigate('/dashboard/tokens/list')
                        break

                      default:
                        break
                    }
                  }}
                >
                  <div className="tokens-storage-list__icon">
                    <UserIcon />
                    <div
                      className={`tokens-storage-list__icon-status tokens-storage-list__icon-status--${item.status}`}
                    >
                      {item.status === ETokenStorageListItemStatus.allowed && (
                        <AllowedStatus />
                      )}
                      {item.status === ETokenStorageListItemStatus.denied && (
                        <DeniedStatus />
                      )}
                    </div>
                  </div>

                  <div className="tokens-storage-list__info">
                    <div className="tokens-storage-list__title">
                      {item.title}
                    </div>
                    <div className="tokens-storage-list__description">
                      {item.description}
                    </div>
                  </div>
                </li>
              )
            })}
          </ul>
        </div>
      )}
    </>
  )
}
