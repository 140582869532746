/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ChangeEvent,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { useApi } from '@/core/hooks'
import { useAuth } from '@/core/context'
import { EEndpoint, EUserApiPathType } from '@/core/api'
import { hashByCssVariable } from '@/core/utils'

import { ErrorNotification, OTPField, Timer } from '@/components/smart'
import { Button, EButtonType, Icon, Input } from '@/components/ui'

import './style.scss'

type TForgetPasswordHandlerPayload = {
  username: string
}
type TConfirmForgetPasswordHandlerPayload = {
  username: string
  code: string | number
  newPassword: string
}
type TLoginHandlerPayload = {
  username: string
  password: string
}
type TVerifyHandlerPayload = {
  username: string
  code: string
}

export const LoginPage = () => {
  const { login }: any = useAuth()
  const { apiRequest } = useApi()
  const navigate = useNavigate()

  const [step, setStep] = useState<number>(1)

  const [input, setInput] = useState({
    username: history.state?.usr
      ? (history.state?.usr['username'] as string)
      : '',
    password: '',
    confirmPassword: '',
  })

  const [currentCode, setCurrentCode] = useState<string[]>()
  const [error, setError] = useState<string>()
  const [timerError, setTimerError] = useState<boolean>(false)
  const [isCodeFilled, setCodeFilled] = useState(false)

  const [validLength, setValidLength] = useState(false)
  const [hasNumber, setHasNumber] = useState(false)
  const [upperCase, setUpperCase] = useState(false)
  const [lowerCase, setLowerCase] = useState(false)
  const [specialChar, setSpecialChar] = useState(false)
  const [match, setMatch] = useState(false)
  const [requiredLength] = useState(8)

  const checkList = [
    { text: 'At least 8 characters', isValid: validLength },
    { text: 'Contains at least 1 lowercase letter', isValid: lowerCase },
    { text: 'Contains at least 1 uppercase letter', isValid: upperCase },
    { text: 'Contains at least 1 number', isValid: hasNumber },
    { text: 'Contains at least 1 special character', isValid: specialChar },
  ]

  const [content, setContent] = useState<{
    key: string
    title?: string
    desc?: string
  }>()

  useEffect(() => {
    setValidLength(input.password.length >= requiredLength ? true : false)
    setUpperCase(input.password.toLowerCase() !== input.password)
    setLowerCase(input.password.toUpperCase() !== input.password)
    setHasNumber(/\d/.test(input.password))
    setSpecialChar(/[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/.test(input.password))
    setMatch(!!input.password && input.password === input.confirmPassword)
  }, [input, requiredLength])

  useEffect(() => {
    setCodeFilled(!!currentCode && currentCode.every((item) => item !== ''))
  }, [currentCode])

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }))
    setError(undefined)
  }

  const handleCurrentCodeChange = useCallback((value: string[]) => {
    setCurrentCode(value)
  }, [])

  const stopTimerHandler = useCallback(() => {
    setError('the code was expired')
    setTimerError(true)
  }, [])

  const [_, forceUpdate] = useReducer((x) => x + 1, 0)

  const handleTimerReset = useCallback(() => {
    console.log('handleTimerReset: ', _)
    forceUpdate()
  }, [_])

  const loginHandler = async ({ username, password }: TLoginHandlerPayload) => {
    try {
      const response = await apiRequest({
        endpoint: EEndpoint.user,
        path: EUserApiPathType.login,
        options: {
          method: 'POST',
          body: JSON.stringify({ username, password }),
        },
      })

      if (response) {
        // TODO! this step is must be added in step 2
        login({ ...response, UserName: input.username })
      }

      setStep(2)
    } catch (error: any) {
      console.log(error)
      setError(error.message)
    }
  }

  const forgetPasswordHandler = async ({
    username,
  }: TForgetPasswordHandlerPayload) => {
    try {
      await apiRequest({
        endpoint: EEndpoint.user,
        path: EUserApiPathType.forgetPassword,
        options: {
          method: 'POST',
          body: JSON.stringify({ username }),
        },
      })
    } catch (error: any) {
      setError(error.message)
    }
  }

  const confirmForgotPassword = async ({
    username,
    code,
    newPassword,
  }: TConfirmForgetPasswordHandlerPayload) => {
    try {
      await apiRequest({
        endpoint: EEndpoint.user,
        path: EUserApiPathType.confirmForgotPassword,
        options: {
          method: 'POST',
          body: JSON.stringify({ username, code, newPassword }),
        },
      })
      setStep(5)
    } catch (error: any) {
      setError(error.message)
    }
  }

  const verifyHandler = async ({ username, code }: TVerifyHandlerPayload) => {
    try {
      const response = await apiRequest({
        endpoint: EEndpoint.user,
        path: EUserApiPathType.verify,
        options: {
          method: 'POST',
          body: JSON.stringify({ username, code }),
        },
      })

      if (response.status === 200) {
        return await response.json()
      }
    } catch (error: any) {
      console.log(error)
      setError(error.message)
    }
  }

  const setContentByStep = (step: number) => {
    switch (step) {
      case 1:
        setContent({
          key: 'login',
          title: 'Welcome Back',
          desc: 'Please enter your details',
        })
        break
      case 2:
        setContent({
          key: 'login-verify',
          title: 'Verify account',
          desc: 'We sent you a six digit code to the email linked to your account, Please enter the code below to verify your account.',
        })
        break
      case 3:
        setContent({
          key: 'forgot',
          title: 'Forgot Password',
          desc: 'Please enter the user name attached to your account',
        })
        break
      case 4:
        setContent({
          key: 'set-new-password',
          title: 'Set New Password',
          desc: 'Enter the 6 digit code we sent you and choose a new password',
        })
        break
      case 5:
        setContent({
          key: 'password-reset-success',
          title: 'Password reset successful!',
          desc: 'Great, you’ve successfully updated your password.',
        })
        break

      default:
        break
    }
  }

  useEffect(() => {
    setContentByStep(step)
  }, [step])

  const sendNewCodeHandler = useCallback(
    async (username: string) => {
      try {
        const response = await forgetPasswordHandler({ username })
        setTimerError(false)
        handleTimerReset()
        return response
      } catch (error: any) {
        alert(error.message)
      }
    },
    [timerError],
  )

  return (
    <div className="login-page">
      <div className="login-page__layer">
        <div className="login-page__content">
          <div className="login-page-title">
            <div className="login-page-title__logo">
              <Icon viewBox="0 0 84 51" icon={'w3'} />
              Platform
            </div>
            <h2 className="login-page-title__title">
              Innovation Accelerator for The Future of Finance.
            </h2>
            <h2 className="login-page-title__subtitle">
              Build your products and services faster, better and smarter.
            </h2>
          </div>

          <div className="login-page-form">
            <form className="login-form">
              <div className="login-form__title">{content?.title}</div>
              <div className="login-form__desc">{content?.desc}</div>

              <div className="login-form__content">
                {step === 1 && (
                  <div className="login-form-content">
                    <fieldset>
                      <Input
                        type="text"
                        name="username"
                        placeholder="User name"
                        value={input.username}
                        onChange={onInputChange}
                      />
                      <Input
                        type="password"
                        name="password"
                        placeholder="Password"
                        value={input.password}
                        onChange={onInputChange}
                      />
                    </fieldset>

                    <Button isLinkView onClick={() => setStep(3)}>
                      Forgot password?
                    </Button>
                  </div>
                )}
                {step === 2 && (
                  <div className="login-form-verify-content">
                    <OTPField onChange={handleCurrentCodeChange} />
                    <div className="login-form__controller">
                      <div className="login-form__timer">
                        time remaining:&nbsp;
                        <Timer
                          onReset={handleTimerReset}
                          onEndTimer={stopTimerHandler}
                        />
                      </div>
                      <Button
                        isLinkView
                        disabled={!timerError}
                        onClick={() => sendNewCodeHandler(input.username)}
                      >
                        Send new code
                      </Button>
                    </div>
                  </div>
                )}
                {step === 3 && (
                  <div className="forgot-password-content">
                    <fieldset>
                      <Input
                        type="text"
                        name="username"
                        placeholder="User name"
                        value={input.username}
                        onChange={onInputChange}
                      />
                    </fieldset>
                  </div>
                )}
                {step === 4 && (
                  <div className="set-new-password-content">
                    <OTPField onChange={handleCurrentCodeChange} />
                    <div className="login-form__controller">
                      <div className="login-form__timer">
                        time remaining:&nbsp;
                        <Timer
                          onReset={handleTimerReset}
                          onEndTimer={stopTimerHandler}
                        />
                      </div>
                      <Button
                        isLinkView
                        disabled={!timerError}
                        onClick={() => sendNewCodeHandler(input.username)}
                      >
                        Send new code
                      </Button>
                    </div>

                    <fieldset>
                      <Input
                        type="password"
                        name="password"
                        placeholder="New password"
                        value={input.password}
                        onChange={onInputChange}
                      />
                      <Input
                        type="password"
                        name="confirmPassword"
                        placeholder="Confirm password"
                        value={input.confirmPassword}
                        onChange={onInputChange}
                      />
                      {input.password.length > 0 &&
                        input.confirmPassword.length > 0 &&
                        !match && (
                          <span className="error">
                            Password and Confirm Password does not match.
                          </span>
                        )}
                    </fieldset>

                    <ul>
                      {checkList.map((list, index) => (
                        <li
                          style={{
                            color: list.isValid
                              ? hashByCssVariable('--status-success')
                              : hashByCssVariable('--status-error'),
                          }}
                          key={index}
                        >
                          {list.isValid ? (
                            <Icon
                              viewBox="0 0 24 24"
                              size={'24px'}
                              icon={'check'}
                            />
                          ) : (
                            <Icon
                              viewBox="0 0 24 24"
                              size={'24px'}
                              icon={'close'}
                            />
                          )}
                          <span>{list.text}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
              {error && (
                <div className="login-form__error">
                  <ErrorNotification errorText={error} />
                </div>
              )}

              {step === 1 && (
                <Button
                  type={EButtonType.submit}
                  disabled={!input.username || !input.password}
                  onClick={() =>
                    loginHandler({
                      username: input.username,
                      password: input.password,
                    })
                  }
                >
                  Log in
                </Button>
              )}
              {step === 2 && (
                <Button
                  type={EButtonType.submit}
                  disabled={timerError || !isCodeFilled}
                  onClick={() =>
                    verifyHandler({
                      username: input.username,
                      code: (currentCode && currentCode.join('')) || '',
                    })
                  }
                >
                  Verify
                </Button>
              )}
              {step === 3 && (
                <Button
                  type={EButtonType.submit}
                  disabled={!input.username}
                  onClick={() => {
                    const response = sendNewCodeHandler(input.username)
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    if (response) {
                      setStep(4)
                    }
                  }}
                >
                  Continue
                </Button>
              )}
              {step === 4 && (
                <Button
                  type={EButtonType.button}
                  disabled={
                    timerError ||
                    !isCodeFilled ||
                    !specialChar ||
                    !hasNumber ||
                    !upperCase ||
                    !lowerCase ||
                    !validLength ||
                    !match
                  }
                  onClick={() =>
                    confirmForgotPassword({
                      username: input.username,
                      code: (currentCode && currentCode.join('')) || '',
                      newPassword: input.password || input.confirmPassword,
                    })
                  }
                >
                  Continue
                </Button>
              )}
              {step === 5 && (
                <Button
                  type={EButtonType.submit}
                  onClick={() => navigate('/login', { replace: true })}
                >
                  Thank you
                </Button>
              )}
            </form>
          </div>

          <Link to="/" className="login-page__sponsor">
            <Icon viewBox="0 0 321 93" icon={'logo'} />
          </Link>
        </div>
      </div>
    </div>
  )
}
