/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { useAuth } from '@/core/context'
import { useApi, useTokenReset } from '@/core/hooks'
import {
  EEndpoint,
  EAnalyticsApiPathType,
  TAnalyticsGeneralTokensResponse,
} from '@/core/api'

import { Icon } from '@/components/ui'

import './style.scss'
import { PieChart } from '../PieChart'

interface IChartData {
  labels: string[]
  data: number[]
}

export const TotalTokens = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { user }: any = useAuth()
  const { apiRequest } = useApi()
  const { resetToken, resettingToken } = useTokenReset()

  const [isLoading, setLoading] = useState<boolean>(false)
  const [generalTokens, setGeneralTokens] =
    useState<TAnalyticsGeneralTokensResponse | null>(null)

  const [chartData, setChartData] = useState<IChartData>({
    labels: [],
    data: [],
  })

  useEffect(() => {
    if (!generalTokens) return

    setChartData({
      labels: Object.keys(generalTokens),
      data: Object.values(generalTokens),
    })
  }, [generalTokens])

  const getGeneralTokensHandler = async () => {
    try {
      setLoading(true)
      const response = (await apiRequest({
        endpoint: EEndpoint.analytics,
        path: EAnalyticsApiPathType.generalTokens,
        options: {
          headers: {
            'transaction-id': uuidv4(),
            Authorization: `${user.TokenType} ${user.AccessToken}`,
          },
          method: 'GET',
        },
      })) as TAnalyticsGeneralTokensResponse
      if (response) {
        console.log('response: ', response)
        setGeneralTokens({ ...response })
        return response
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.message.match(/403|User is not authorized/gm)) resetToken()
      else console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getGeneralTokensHandler()
  }, [resettingToken])

  console.log(generalTokens)
  return (
    <div className="total-tokens">
      <div className="total-tokens-header">
        <div className="total-tokens__title">Total Tokens</div>
      </div>
      <div className="total-tokens-content">
        {isLoading ? (
          <div className="transaction-activity-loading overview-component-loading">
            <Icon viewBox="0 0 24 24" icon={'cached'} size="90px" />
            <span>Data Loading</span>
          </div>
        ) : !generalTokens ? (
          <div className="transaction-activity-nodata">
            <Icon viewBox="0 0 24 24" icon={'cloudOff'} size="90px" />
            <span>No data available</span>
          </div>
        ) : (
          <PieChart labels={chartData.labels} data={chartData.data} />
        )}
      </div>
    </div>
  )
}
