/* eslint-disable @typescript-eslint/no-explicit-any */
import { v4 as uuidv4 } from 'uuid'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ColumnDef } from '@tanstack/react-table'

import { useAuth, useModal } from '@/core/context'
import { useApi } from '@/core/hooks'
import { convertCamelCaseToWords } from '@/core/utils'
import {
  EEndpoint,
  EServiceCreateStatus,
  EServicesApiPathType,
  IServices,
} from '@/core/api'
import { Button, Icon } from '@/components/ui'

import { InstanceTable } from './InstanceTable'
import { ServiceInstanceEdit } from './Edit'

import './style.scss'
import { BackArrow, IndeterminateCheckbox } from '@/components/simple'

export const ServicesInstance = () => {
  const navigate = useNavigate()
  const { apiRequest } = useApi()
  const { user }: any = useAuth()
  const { setModal } = useModal()

  const currentService = history.state?.usr['service'] as IServices

  const [isLoading, setLoading] = useState<boolean>(false)
  const [tableData, setTableData] = useState<any[] | null>([])
  const [columns, setColumns] = useState<ColumnDef<any>[]>([])
  const [modifiedSelection, setModifiedSelection] = useState<any[]>([])
  const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({})

  const orgServiceApiCall = async () => {
    try {
      setLoading(true)
      const response = await apiRequest({
        endpoint: EEndpoint.services,
        path: `${EServicesApiPathType.org}/${encodeURIComponent(
          currentService.serviceName,
        )}`,
        options: {
          headers: {
            'transaction-id': uuidv4(),
            Authorization: `${user.TokenType} ${user.AccessToken}`,
          },
          method: 'GET',
        },
      }) // as IServiceInstance[]
      console.log('response: ', response)
      if (response) {
        const _tableData = [...response].reduce(
          (
            acc: any[],
            cur: { configuration: any; description: string; status: string },
          ) => {
            if (
              Object.prototype.hasOwnProperty.call(cur, 'configuration') &&
              Object.prototype.hasOwnProperty.call(cur, 'status')
            ) {
              const configuration = {
                ...cur.configuration,
                description: cur.description,
                status: cur.status,
              }
              delete configuration.orgId
              acc.push(configuration)
              return acc
            } else {
              return acc
            }
          },
          [],
        )

        if (_tableData.length > 0) {
          setTableData(_tableData)
          setColumns([
            {
              id: 'select',
              header: ({ table }) => {
                return (
                  <IndeterminateCheckbox
                    {...{
                      checked: table.getIsAllPageRowsSelected(),
                      indeterminate: table.getIsSomePageRowsSelected(),
                      onChange: table.getToggleAllPageRowsSelectedHandler(),
                      name: 'select',
                    }}
                  />
                )
              },
              cell: ({ row }) => {
                return (
                  <IndeterminateCheckbox
                    {...{
                      checked: row.getIsSelected(),
                      disabled: !row.getCanSelect(),
                      indeterminate: row.getIsSomeSelected(),
                      onChange: row.getToggleSelectedHandler(),
                    }}
                  />
                )
              },
            },

            ...Object.keys([..._tableData][0]).map((item) => {
              if (item !== 'status') {
                return {
                  accessorKey: item,
                  header: convertCamelCaseToWords(item),
                  cell: (props: any) => {
                    return <>{props.getValue()}</>
                  },
                }
              } else {
                return {
                  accessorKey: item,
                  header: convertCamelCaseToWords(item),
                  cell: (props: any) => {
                    switch (props.getValue()) {
                      case EServiceCreateStatus.PENDING:
                        return (
                          <div className={'info-status info-status--pending'}>
                            Pending
                          </div>
                        )
                      case EServiceCreateStatus.READY:
                        return (
                          <div className={'info-status info-status--ready'}>
                            Ready
                          </div>
                        )
                      default:
                        return (
                          <div className={'info-status info-status--pending'}>
                            Pending
                          </div>
                        )
                    }
                  },
                }
              }
            }),
          ])
        } else {
          setTableData(null)
          setColumns([])
        }
      }
    } catch (error: any) {
      console.log(error)
      navigate('../')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    orgServiceApiCall()
  }, [])

  useEffect(() => {
    // console.log(rowSelection)
    // console.log(Object.keys(rowSelection)[0])

    setModifiedSelection(
      Object.keys(rowSelection).map((item) => tableData![parseInt(item)]),
    )
  }, [rowSelection])

  const createServiceHandler = () => {
    navigate('./create', {
      state: {
        service: {
          ...currentService,
        },
      },
    })
  }

  const testServiceHandler = () => {
    console.log(modifiedSelection)
    navigate('./test', {
      state: {
        service: {
          ...currentService,
          serviceId: modifiedSelection[0].serviceId,
        },
      },
    })
  }

  const editServiceInstanceHandler = () => {
    setModal(
      <ServiceInstanceEdit
        data={modifiedSelection[0]}
        orgSchema={currentService.serviceOrgRegisrationSchema}
      />,
    )
  }

  const deleteServiceInstanceHandler = async () => {
    const serviceId = modifiedSelection[0].serviceId
    try {
      const response = await apiRequest({
        endpoint: EEndpoint.services,
        path: `${EServicesApiPathType.delete}/${serviceId}`,
        options: {
          headers: {
            'transaction-id': uuidv4(),
            Authorization: `${user.TokenType} ${user.AccessToken}`,
          },
          method: 'DELETE',
        },
      })
      if (response) {
        setModal(null)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const ConfirmationModal = () => {
    const confirmHandler = async () => {
      await deleteServiceInstanceHandler()
      setModal(null)
    }

    return (
      <div className="confirmation-modal">
        <Icon icon="folderClose" viewBox="0 0 79 79" size={'79px'} />
        <div className="confirmation-modal__title">
          Are you sure you wanna delete this instance?
        </div>
        <div className="confirmation-modal__description">
          If you leave this tab all changes will be lost
        </div>
        <div className="confirmation-modal-footer">
          <Button onClick={() => setModal(null)}>Cancel</Button>
          <Button onClick={confirmHandler}>Yes, I’m sure</Button>
        </div>
      </div>
    )
  }

  return (
    <div className="service-instances">
      <div className="service-instances__back">
        <Button onClick={() => navigate(-1)}>
          <BackArrow />
        </Button>
      </div>

      <div className="service">
        <Icon icon={'services'} size="60px" />
        <div className="service-info">
          <div className="title">{currentService.serviceName}</div>
          <div className="description" title={currentService.description}>
            {currentService.description}
          </div>
        </div>
      </div>

      <div className="service-instances__title">Instances</div>
      <div className="service-instances-controls">
        <Button onClick={createServiceHandler}>+</Button>
        <Button
          onClick={editServiceInstanceHandler}
          disabled={!modifiedSelection.length}
        >
          Edit
        </Button>
        <Button
          onClick={() => setModal(<ConfirmationModal />)}
          disabled={!modifiedSelection.length}
        >
          Delete
        </Button>
        <Button
          onClick={testServiceHandler}
          disabled={!modifiedSelection.length}
        >
          Test
        </Button>
      </div>

      <InstanceTable
        isLoading={isLoading}
        tableData={tableData}
        columns={columns}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
      />
    </div>
  )
}
