/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'

import { useCreateToken, useModal } from '@/core/context'

import { Button, Icon } from '@/components/ui'

import { SetType } from './SetType'
import { SetDescription } from './SetDescription'
import { UploadFile } from './UploadFile'
import { ReviewInformation } from './ReviewInformation'

import './style.scss'

const stepList = [
  {
    index: 1,
    text: 'Choose type',
  },
  {
    index: 2,
    text: 'Choose description',
  },
  {
    index: 3,
    text: 'Upload file',
  },
  {
    index: 4,
    text: 'Create Token',
  },
]

export const TokenCreate = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const navigate = useNavigate()
  const { setModal } = useModal()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { creationStep, setCreationStep, resetTokenData } = useCreateToken()

  const [currentStep, setCurrentStep] = useState<number>(creationStep)
  const [tokensList, setTokenList] = useState<any[]>([])

  const ConfirmationModal = () => {
    const confirmHandler = () => {
      resetTokenData()
      navigate('/dashboard/tokens/list')
      setModal(null)
    }

    return (
      <div className="confirmation-modal">
        <Icon icon="folderClose" viewBox="0 0 79 79" size={'79px'} />
        <div className="confirmation-modal__title">
          Are you sure you wanna leave this tab?
        </div>
        <div className="confirmation-modal__description">
          If you leave this tab all changes will be lost
        </div>
        <div className="confirmation-modal-footer">
          <Button onClick={() => setModal(null)}>Cancel</Button>
          <Button onClick={confirmHandler}>Yes, I’m sure</Button>
        </div>
      </div>
    )
  }

  const backButtonHandler = () => {
    switch (currentStep) {
      case 1:
        setModal(<ConfirmationModal />)
        break
      case 2:
        setCreationStep(currentStep - 1)
        break
      case 3:
        setCreationStep(currentStep - 1)
        break
    }
  }

  useEffect(() => {
    setCurrentStep(creationStep)
  }, [creationStep])

  return (
    <div className="token-create">
      <div className="token-create-content">
        <div className="token-create-content__asside">
          <div className="token-create__title">Let’s create a new Token</div>

          <ul className="token-create-step-list">
            {stepList.map((item, index) => {
              return (
                <li
                  key={index}
                  className={classNames('token-create-step-list__item', {
                    isActive: currentStep === item.index,
                  })}
                >
                  <div className="token-create-step-list__index">
                    {item.index}
                  </div>
                  <div className="token-create-step-list__text">
                    {item.text}
                  </div>
                  <Icon icon={'arrowForward'} />
                </li>
              )
            })}
          </ul>

          {currentStep !== 4 && (
            <Button onClick={backButtonHandler}>
              <Icon icon={'arrowBack'} />
              Back
            </Button>
          )}
        </div>

        <div className="token-create-data-container">
          {currentStep === 1 && (
            <SetType tokensList={tokensList} setTokenList={setTokenList} />
          )}
          {currentStep === 2 && <SetDescription />}
          {currentStep === 3 && <UploadFile />}
          {currentStep === 4 && <ReviewInformation />}
        </div>
      </div>
    </div>
  )
}
