/* eslint-disable @typescript-eslint/no-explicit-any */
import cn from 'classnames'
import { Loading } from '@/components/ui'

export enum EMessagePosition {
  left = 'left',
  right = 'right',
}
export enum EMessageType {
  text = 'text',
}
export interface IMessage {
  id: string
  position: EMessagePosition
  type: EMessageType
  text: string
  date: Date
}
export interface IMessagesListProps {
  dataSource: IMessage[]
  isServerLoading?: boolean
}

export const MessagesList = ({
  dataSource,
  isServerLoading,
}: IMessagesListProps) => {
  console.log(dataSource)
  return (
    <div className={cn('messages-list', {})}>
      {dataSource.map((message) => (
        <div className="message-wrapper" key={message.id}>
          <div
            className={cn('message', {
              'message--right': message.position === 'right',
              'message--left': message.position === 'left',
            })}
          >
            <div className="message__content">{message.text}</div>
          </div>
        </div>
      ))}
      {isServerLoading && <Loading />}
    </div>
  )
}
