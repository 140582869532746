/* eslint-disable @typescript-eslint/no-explicit-any */
import { Icon } from '@/components/ui'

interface IFilterProps {
  columnFilters: { id: string; value: string }[]
  setColumnFilters: any
}

export const Filter = ({ columnFilters, setColumnFilters }: IFilterProps) => {
  const inputSearchName =
    columnFilters.find((f) => f.id === 'serviceName')?.value || ''

  const onFilterChange = (id: string, value: string) => {
    setColumnFilters((prev: any) =>
      prev
        .filter((f: { id: string; value: string }) => f.id !== id)
        .concat({ id, value }),
    )
  }

  return (
    <div className="search-input-group">
      <input
        type="search"
        placeholder="Search"
        value={inputSearchName}
        onChange={(e) => onFilterChange('serviceName', e.target.value)}
      />
      <Icon viewBox="0 0 20 20" icon={'search'} size="20px" />
    </div>
  )
}
