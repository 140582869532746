export const UserIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="46"
      viewBox="0 0 32 46"
      fill="none"
    >
      <path
        d="M29.3333 13.3333C29.3333 9.65067 27.84 6.31733 25.4293 3.90667C23.016 1.49333 19.6826 0 16 0C12.3173 0 8.98396 1.49333 6.57063 3.90667C4.15996 6.31733 2.66663 9.65067 2.66663 13.3333C2.66663 17.016 4.15996 20.3493 6.57063 22.76C8.98396 25.1733 12.3173 26.6667 16 26.6667C19.6826 26.6667 23.016 25.1733 25.4293 22.76C27.84 20.3493 29.3333 17.016 29.3333 13.3333Z"
        fill="url(#paint0_linear_1106_9012)"
      />
      <path
        d="M0 40.0007C0 42.6673 6 45.334 16 45.334C25.3813 45.334 32 42.6673 32 40.0007C32 34.6673 25.7227 29.334 16 29.334C6 29.334 0 34.6673 0 40.0007Z"
        fill="url(#paint1_linear_1106_9012)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1106_9012"
          x1="16"
          y1="0"
          x2="16"
          y2="26.6667"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6071FF" />
          <stop offset="1" stopColor="#9475FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1106_9012"
          x1="16"
          y1="29.334"
          x2="16"
          y2="45.334"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6071FF" />
          <stop offset="1" stopColor="#9475FF" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export const AllowedStatus = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="20"
      viewBox="0 0 24 20"
      fill="none"
    >
      <line
        x1="2"
        y1="10"
        x2="22"
        y2="10"
        stroke="url(#paint0_linear_3002_7031)"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <line
        x1="5"
        y1="2"
        x2="19"
        y2="2"
        stroke="url(#paint1_linear_3002_7031)"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <line
        x1="5"
        y1="18"
        x2="19"
        y2="18"
        stroke="url(#paint2_linear_3002_7031)"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3002_7031"
          x1="12"
          y1="12"
          x2="12"
          y2="13"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6071FF" />
          <stop offset="1" stopColor="#9475FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3002_7031"
          x1="12"
          y1="4"
          x2="12"
          y2="5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6071FF" />
          <stop offset="1" stopColor="#9475FF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_3002_7031"
          x1="12"
          y1="20"
          x2="12"
          y2="21"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6071FF" />
          <stop offset="1" stopColor="#9475FF" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export const DeniedStatus = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="25"
      viewBox="0 0 19 25"
      fill="none"
    >
      <path
        d="M16.625 8.33333H15.4375V5.95238C15.4375 2.66667 12.7775 0 9.5 0C6.2225 0 3.5625 2.66667 3.5625 5.95238V8.33333H2.375C1.06281 8.33333 0 9.39881 0 10.7143V22.619C0 23.9345 1.06281 25 2.375 25H16.625C17.9372 25 19 23.9345 19 22.619V10.7143C19 9.39881 17.9372 8.33333 16.625 8.33333ZM9.5 19.0476C8.18781 19.0476 7.125 17.9821 7.125 16.6667C7.125 15.3512 8.18781 14.2857 9.5 14.2857C10.8122 14.2857 11.875 15.3512 11.875 16.6667C11.875 17.9821 10.8122 19.0476 9.5 19.0476ZM13.1813 8.33333H5.81875V5.95238C5.81875 3.91667 7.46938 2.2619 9.5 2.2619C11.5306 2.2619 13.1813 3.91667 13.1813 5.95238V8.33333Z"
        fill="url(#paint0_linear_1106_9018)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1106_9018"
          x1="9.5"
          y1="0"
          x2="9.5"
          y2="25"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6071FF" />
          <stop offset="1" stopColor="#9475FF" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export const BackArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="20"
      viewBox="0 0 28 20"
      fill="none"
    >
      <path
        d="M26 8H6.8L11.42 3.38C11.814 2.986 12 2.516 12 2C12 1.016 11.187 0 10 0C9.469 0 9.006 0.193 8.62 0.58L0.662 8.538C0.334 8.866 0 9.271 0 10C0 10.729 0.279 11.08 0.646 11.447L8.62 19.42C9.006 19.807 9.469 20 10 20C11.188 20 12 18.984 12 18C12 17.484 11.814 17.014 11.42 16.62L6.8 12H26C27.104 12 28 11.104 28 10C28 8.896 27.104 8 26 8Z"
        fill="#6071FF"
      />
    </svg>
  )
}
