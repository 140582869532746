import { FC, useEffect, useState } from 'react'

import './style.scss'

interface StepperProps {
  currentStep: number
  className?: string
}

export const Stepper: FC<StepperProps> = ({ currentStep = 1, className }) => {
  const [step, setStep] = useState<number>(currentStep)

  useEffect(() => {
    setStep(currentStep)
  }, [currentStep])

  return (
    <div className={`stepper ${className ? className : ''}`}>
      <ul className="stepper-list stepper__list">
        {Array.from({ length: 3 }, (_, i) => i + 1).map((step, index) => {
          return (
            <li
              className={`stepper-list__item ${
                step <= currentStep ? 'active' : ''
              }`}
              key={index}
            ></li>
          )
        })}
      </ul>
      <div className="stepper__info">{step} of 3</div>
    </div>
  )
}
