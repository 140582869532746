// eslint-disable-next-line @typescript-eslint/no-unused-vars
export enum ETokensApiPathType {
  storageTokens = 'storage/tokens',
  assignToken = 'assignToken',
  deprecateToken = 'deprecateToken',
  uploadContent = 'uploadContent',
  delete = 'storage/tokens/delete',
}

export enum ETokenStatus {
  MINTED = 'MINTED',
  DEPRECATED = 'DEPRECATED',
}
export enum ETokenType {
  SECRET = 'SECRET',
  QUERY_CHAT_RESULT = 'QUERY_CHAT_RESULT',
  POLICY = 'POLICY',
}

export interface IToken {
  tokenId: string
  tokenIdSource?: string
  date: number
  accountIssuer: string
  accountId: string
  status: string | ETokenStatus
  orgId: string
  TTL: number
  description: string
  tokenName: string
  type: string | ETokenType
}

export interface IDeprecateTokenPayload {
  tokenId: string
}
export interface IDeleteTokenPayload {
  tokenId: string
}

export interface IAssignTokenPayload {
  // fromAccount: string
  assignAccountId: string
  requestTokenId: string
}

export interface IStorageTokensNextQueryParams {
  accountId: string
  accountIssuer: string
  date: number
  tokenId: string
}
export interface IStorageTokensResponse {
  results: {
    items: IToken[]
    next?: IStorageTokensNextQueryParams
    back?: IStorageTokensNextQueryParams
  }
  total: number
}
