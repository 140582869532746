import { Icon } from '@/components/ui'
import './style.scss'

type TErrorNotificationProps = {
  errorText?: string
  className?: string
}

export const ErrorNotification = ({
  errorText = 'the code is invalid',
  className,
}: TErrorNotificationProps) => {
  return (
    <div className={`error-notification ${className ? className : ''}`}>
      <Icon viewBox="0 0 36 36" size={'36px'} icon={'error'} />
      <div className="error-notification__info">
        <div className="error-notification__title">Error</div>
        <div className="error-notification__description">{errorText}</div>
      </div>
    </div>
  )
}
