export enum EEndpoint {
  user = 'user',
  accounts = 'accounts',
  analytics = 'analitics',
  services = 'services',
  secureStorage = 'secureStorage',
  tokens = 'tokens',
}

export const apiEndpoints = [
  {
    key: EEndpoint.user,
    baseUrl: process.env.REACT_APP_USER_API_ENDPOINT,
    getUrl: function () {
      return `${this.baseUrl}/${this.key}`
    },
  },
  {
    key: EEndpoint.accounts,
    baseUrl: process.env.REACT_APP_ACCOUNTS_API_ENDPOINT,
    getUrl: function () {
      return `${this.baseUrl}/${this.key}`
    },
  },
  {
    key: EEndpoint.analytics,
    baseUrl: process.env.REACT_APP_ANALYTICS_API_ENDPOINT,
    getUrl: function () {
      return `${this.baseUrl}/${this.key}`
    },
  },
  {
    key: EEndpoint.services,
    baseUrl: process.env.REACT_APP_SERVICES_API_ENDPOINT,
    getUrl: function () {
      return `${this.baseUrl}/${this.key}`
    },
  },
  {
    key: EEndpoint.secureStorage,
    baseUrl: process.env.REACT_APP_SECURE_STORAGE_API_ENDPOINT,
    getUrl: function () {
      return `${this.baseUrl}/${this.key}`
    },
  },
  {
    key: EEndpoint.tokens,
    baseUrl: process.env.REACT_APP_TOKENS_API_ENDPOINT,
    getUrl: function () {
      return `${this.baseUrl}/secureStorage`
    },
  },
]
