/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { v4 as uuidv4 } from 'uuid'
import { FormEvent, useCallback, useEffect, useState } from 'react'

import { convertCamelCaseToWords } from '@/core/utils'
import { EEndpoint, EServicesApiPathType, IServiceCreate } from '@/core/api'

import { Button, EButtonType, Icon } from '@/components/ui'

import './style.scss'
import { SearchToken } from '@/components/smart/SearchToken'
import { useAuth, useModal } from '@/core/context'
import { useApi } from '@/core/hooks'

interface IServiceInstanceEditProps {
  data: any
  orgSchema: any
}

export const ServiceInstanceEdit = ({
  data,
  orgSchema,
}: IServiceInstanceEditProps) => {
  const { user }: any = useAuth()
  const { apiRequest } = useApi()
  const { setModal } = useModal()

  const [modifiedData, setModifiedData] = useState<any[]>([])

  const instanceData = () => {
    const _instanceData = Object.keys(data)
      .map((item, index) => {
        return {
          key: item,
          name: convertCamelCaseToWords(item),
          value: Object.values(data)[index],
          isEdit: [...JSON.parse(orgSchema).required, 'description'].some(
            (el) => el === item,
          ),
        }
      })
      .sort((a, b) => {
        if (a.key < b.key) {
          return -1
        }
        if (a.key > b.key) {
          return 1
        }
        return 0
      })

    return _instanceData
  }

  const onInputChange = (event: FormEvent<HTMLInputElement>, key: string) => {
    // @ts-ignore
    const { value } = event.target

    setModifiedData((prev: any[]) => {
      return [
        ...prev.filter((item) => item.key !== key),
        {
          key,
          name: convertCamelCaseToWords(key),
          value,
          isEdit: [...JSON.parse(orgSchema).required, 'description'].some(
            (el) => el === key,
          ),
        },
      ].sort((a, b) => {
        if (a.key < b.key) {
          return -1
        }
        if (a.key > b.key) {
          return 1
        }
        return 0
      })
    })
  }

  const onEditHandler = (e: any, key: string) => {
    e.preventDefault()
    const input = e.target.closest('button').previousSibling
    input.removeAttribute('readonly')

    setModifiedData(
      [...modifiedData].map((item) => {
        if (item.key === key && key.toLowerCase().indexOf('token') > -1) {
          item.hasSearchToken = true
        }
        return item
      }),
    )
  }

  const EditInstanceHandler = async () => {
    const serviceRequest = [...modifiedData]
      .filter((item) =>
        [...JSON.parse(orgSchema).required].some((el) => el === item.key),
      )
      .reduce((acc, cur) => {
        acc = Object.assign(
          { ...acc },
          {
            [cur.key]: cur.value,
          },
        )
        return acc
      }, {})

    const payload: IServiceCreate = {
      serviceId: modifiedData.find((item) => item.key === 'serviceId').value,
      description: modifiedData.find((item) => item.key === 'description')
        .value,
      serviceRequest,
    }

    try {
      const response = await apiRequest({
        endpoint: EEndpoint.services,
        path: EServicesApiPathType.editConfiguration,
        options: {
          headers: {
            'transaction-id': uuidv4(),
            Authorization: `${user.TokenType} ${user.AccessToken}`,
          },
          method: 'POST',
          body: JSON.stringify(payload),
        },
      })
      if (response) {
        setModal(null)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    setModifiedData(instanceData())
  }, [])

  useEffect(() => {
    console.log('modifiedData: ', modifiedData)
  }, [modifiedData])

  const searchTokenHandler = useCallback((event: any, key: string) => {
    onInputChange(event, key)
  }, [])

  return (
    <div className="service-instance-modal service-instance-modal--edit">
      <div className="title">Instance description:</div>
      <form className="form">
        <fieldset>
          {modifiedData.map((item) => (
            <div className="form__item" key={item.key}>
              <label className="label">{item.name}</label>

              {item.hasSearchToken ? (
                <>
                  <div>{item.value}</div>
                  <SearchToken
                    onChange={(e) => searchTokenHandler(e, item.key)}
                    hasCreateToken={false}
                    radioGroupName={`${item.key}-token`}
                  />
                </>
              ) : (
                <div className="input-wrapper">
                  {
                    <input
                      type="text"
                      name={item.key}
                      readOnly
                      onInput={(event) => onInputChange(event, item.key)}
                      value={item.value}
                      className="input"
                    />
                  }

                  {item.isEdit && (
                    <button onClick={(e) => onEditHandler(e, item.key)}>
                      <Icon icon={'create'} size="24px" />
                    </button>
                  )}
                </div>
              )}
            </div>
          ))}
        </fieldset>

        <Button type={EButtonType.button} onClick={EditInstanceHandler}>
          Edit
        </Button>
      </form>
    </div>
  )
}
