/* eslint-disable quotes */
import { FC, useCallback } from 'react'

import './style.scss'
import { Button } from '@/components/ui'

interface VerifyStatusInfoProps {
  hasError?: boolean
  onSendNewCode?: () => Promise<void>
}

export const VerifyStatusInfo: FC<VerifyStatusInfoProps> = ({
  hasError,
  onSendNewCode,
}) => {
  const sendNewCodeHandler = useCallback(async () => {
    onSendNewCode && (await onSendNewCode())
  }, [onSendNewCode])

  return (
    <div className="verify-status-info">
      <div className="verify-status-info-wrapper">
        <div className="verify-status-info__title">
          {!hasError ? 'Account verified successfully!' : 'Code expired'}
        </div>
        <div className="verify-status-info__description">
          {!hasError
            ? "Your account has been successfully verified. You're all set!"
            : 'Seems like your access code expired'}
        </div>
        {hasError && (
          <>
            <Button isLinkView onClick={sendNewCodeHandler}>
              Send new code
            </Button>
          </>
        )}
      </div>
    </div>
  )
}
