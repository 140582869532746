import { RadioButton } from './RadioButton'
import './style.scss'

export interface IOption {
  label: string
  value: string
  isChecked: boolean
  name?: string
  disabled?: boolean
}

interface IRadioButtonGroupProps {
  label: string
  name: string
  options: IOption[]
  hasFullWidth?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export const RadioButtonGroup = ({
  label,
  name,
  options,
  onChange,
}: IRadioButtonGroupProps) => {
  const renderOptions = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return options.map(({ label, value, disabled, isChecked }: IOption) => {
      const shortenedOptionLabel = label.replace(/\s+/g, '')
      const optionId = `radio-option-${shortenedOptionLabel}-${name}`

      return (
        <RadioButton
          value={value}
          label={label}
          key={optionId}
          id={optionId}
          name={name}
          disabled={disabled}
          defaultChecked={isChecked}
          onChange={onChange}
        />
      )
    })
  }
  return (
    <fieldset className="radio-group">
      <legend className="radio-group__legend">{label}</legend>
      <div className="radio-group__wrapper">{renderOptions()}</div>
    </fieldset>
  )
}
