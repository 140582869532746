/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { v4 as uuidv4 } from 'uuid'
import parsePhoneNumberFromString, { ParseError } from 'libphonenumber-js'
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react'
import cn from 'classnames'

import { Button, EButtonType, Input } from '@/components/ui'
import { useAuth, useModal } from '@/core/context'
import { useApi, useTokenReset } from '@/core/hooks'
import { EEndpoint, EUserApiPathType, IUserApiCreatePayload } from '@/core/api'
import { DataLoading } from '@/components/smart'

import './style.scss'

interface IRegisterNewUserProps {
  setUsersChange: Dispatch<SetStateAction<boolean>>
  users: {
    username: string
    email: string
    phone_number: string
  }[]
}

export const RegisterNewUser = ({
  users,
  setUsersChange,
}: IRegisterNewUserProps) => {
  const { user }: any = useAuth()
  const { apiRequest } = useApi()
  const { resetToken, resettingToken } = useTokenReset()
  const { setModal } = useModal()

  const [accountId, setAccountId] = useState<string | null>(null)
  const [isLoading, setLoading] = useState<boolean>(false)
  const [input, setInput] = useState({
    username: '',
    email: '',
    phone_number: '',
    password: '',
  })

  const [validLength, setValidLength] = useState(false)
  const [hasNumber, setHasNumber] = useState(false)
  const [upperCase, setUpperCase] = useState(false)
  const [lowerCase, setLowerCase] = useState(false)
  const [specialChar, setSpecialChar] = useState(false)
  const [requiredLength] = useState(8)
  const checkList = [
    { text: 'Password is required field', isValid: input.password.length > 0 },
    { text: 'At least 8 characters', isValid: validLength },
    { text: 'Contains at least 1 lowercase letter', isValid: lowerCase },
    { text: 'Contains at least 1 uppercase letter', isValid: upperCase },
    { text: 'Contains at least 1 number', isValid: hasNumber },
    { text: 'Contains at least 1 special character', isValid: specialChar },
  ]

  const [isUsernameExist, setIsUsernameExist] = useState(false)
  const checkUsername = [
    { text: 'Username is required field', isValid: input.username.length > 0 },
    {
      text: 'Username must be more than 1 character',
      isValid: input.username.length > 1,
    },
    { text: 'User name already in use', isValid: !isUsernameExist },
  ]

  const [isEmailExist, setIsEmailExist] = useState(false)
  const emailRegex = new RegExp(
    /^[A-Za-z0-9_!#$%&'*+\\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/,
    'gm',
  )
  const checkEmail = [
    {
      text: 'Email format not acceptable',
      isValid: emailRegex.test(input.email),
    },
    { text: 'Email already in use', isValid: !isEmailExist },
  ]

  const [isPhoneNumberExist, setIsPhoneNumberExist] = useState(false)
  const isNumberValid = (number: string) => {
    try {
      const phoneNumberObject = parsePhoneNumberFromString(number, 'IL')
      return phoneNumberObject && phoneNumberObject.isValid()
    } catch (error) {
      if (error instanceof ParseError) {
        return false
      } else {
        return false
      }
    }
  }
  const checkPhoneNumber = [
    {
      text: 'Phone number format is not acceptable',
      isValid: isNumberValid(input.phone_number),
    },
    { text: 'Phone number already in use', isValid: !isPhoneNumberExist },
  ]
  //

  const formItemList = [
    {
      label: 'Username',
      name: 'username',
      type: 'text',
      isRequired: true,
    },
    {
      label: 'Email',
      name: 'email',
      type: 'text',
      isRequired: true,
    },
    {
      label: 'Phone number',
      name: 'phone_number',
      type: 'text',
      isRequired: true,
    },
    {
      label: 'Password',
      name: 'password',
      type: 'password',
      isRequired: true,
    },
  ]

  const error = {
    username: {
      hasError: !!checkUsername.find((itemList) => !itemList.isValid),
      errorText: checkUsername.find((itemList) => !itemList.isValid)?.text,
    },
    email: {
      hasError: !!checkEmail.find((itemList) => !itemList.isValid),
      errorText: checkEmail.find((itemList) => !itemList.isValid)?.text,
    },
    phone_number: {
      hasError: !!checkPhoneNumber.find((itemList) => !itemList.isValid),
      errorText: checkPhoneNumber.find((itemList) => !itemList.isValid)?.text,
    },
    password: {
      hasError: !!checkList.find((itemList) => !itemList.isValid) as boolean,
      errorText: checkList.find((itemList) => !itemList.isValid)?.text,
    },
  }

  useEffect(() => {
    setValidLength(input.password.length >= requiredLength ? true : false)
    setUpperCase(input.password.toLowerCase() !== input.password)
    setLowerCase(input.password.toUpperCase() !== input.password)
    setHasNumber(/\d/.test(input.password))
    setSpecialChar(/[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/.test(input.password))

    console.log(error)
  }, [input.password, requiredLength, checkList])

  useEffect(() => {
    setIsUsernameExist(
      users.some(
        (item) => item.username.toLowerCase() === input.username.toLowerCase(),
      ),
    )
  }, [input.username, checkUsername])

  useEffect(() => {
    setIsEmailExist(
      users.some(
        (item) => item.email.toLowerCase() === input.email.toLowerCase(),
      ),
    )
  }, [input.email, checkEmail])

  useEffect(() => {
    setIsPhoneNumberExist(
      users.some(
        (item) =>
          item.phone_number.toLowerCase() === input.phone_number.toLowerCase(),
      ),
    )
  }, [input.phone_number, checkPhoneNumber])

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const getAccountIdApiCall = async () => {
    try {
      setLoading(true)
      const response = await apiRequest({
        endpoint: EEndpoint.accounts,
        options: {
          headers: {
            'transaction-id': uuidv4(),
            Authorization: `${user.TokenType} ${user.AccessToken}`,
          },
          method: 'POST',
        },
      })
      if (response) {
        const { accountId } = response
        setAccountId(accountId)
      }
    } catch (error: any) {
      if (error.message.match(/403|User is not authorized/gm)) resetToken()
      else console.log(error.message)
    } finally {
      setLoading(false)
    }
  }

  const registerHandler = async () => {
    const payload: IUserApiCreatePayload = {
      username: input.username,
      password: input.password,
      email: input.email,
      accountId: accountId as string,
      phone_number: input.phone_number,
    }
    console.log(payload)
    try {
      const response = await apiRequest({
        endpoint: EEndpoint.user,
        path: EUserApiPathType.signUp,
        options: {
          headers: {
            'transaction-id': uuidv4(),
            Authorization: `${user.TokenType} ${user.AccessToken}`,
          },
          method: 'POST',
          body: JSON.stringify({ ...payload }),
        },
      })
      if (response) {
        setUsersChange(true)
        setModal(null)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    console.log(users)
    getAccountIdApiCall()
  }, [resettingToken])

  return (
    <div className="register-user-modal">
      <div className="title">Register user</div>

      <div
        className={cn('form-wrapper', {
          'form-wrapper--loading': !accountId && isLoading,
        })}
      >
        <DataLoading
          isLoading={!accountId && isLoading}
          text={'Generating accountId'}
        />

        <form>
          <fieldset>
            <ul>
              {formItemList.map(({ label, name, type, isRequired }, index) => {
                return (
                  <li
                    key={index}
                    // @ts-ignore
                    className={cn({ 'has-error': error[name].hasError })}
                  >
                    <label>
                      {label}
                      {isRequired ? '*' : ''}
                    </label>
                    <Input
                      type={type}
                      name={name}
                      aria-required={isRequired}
                      // @ts-ignore
                      value={input[name]}
                      onChange={onInputChange}
                    />
                    {/* @ts-ignore */}
                    {error[name].hasError && (
                      <span className="error-description">
                        {/* @ts-ignore */}
                        {error[name].errorText}
                      </span>
                    )}
                  </li>
                )
              })}
            </ul>
          </fieldset>
        </form>
        <Button
          type={EButtonType.submit}
          onClick={registerHandler}
          disabled={
            error.email.hasError ||
            error.username.hasError ||
            error.phone_number.hasError ||
            error.password.hasError
          }
        >
          Register
        </Button>
      </div>
    </div>
  )
}
