import { Link } from 'react-router-dom'
import './index.scss'
import { Icon } from '@/components/ui'
import cn from 'classnames'

interface IMenuProps {
  activeIndex: number
  onChangeMenu: (index: number) => void
}
export const Menu = ({ activeIndex, onChangeMenu }: IMenuProps) => {
  const menuList = [
    {
      name: 'Overview',
      icon: 'apps',
    },
    {
      name: 'Management',
      icon: 'person',
    },
    {
      name: 'Tokens',
      icon: 'cloud',
    },
    {
      name: 'Services',
      icon: 'services',
    },
  ]

  return (
    <div className="menu">
      <Link to="/dashboard/overview" className="menu__logo">
        <img src={require('@/assets/images/dashboard/logo.png')} alt="" />
      </Link>
      <ul className="menu-list">
        {menuList.map(({ name, icon }, index) => {
          return (
            <li
              className={cn('menu-list__item', {
                active: activeIndex === index,
              })}
              key={index}
              onClick={() => index !== activeIndex && onChangeMenu(index)}
            >
              <div className="menu-list__name">{name}</div>
              <Icon viewBox="0 0 24 24" size={'26px'} icon={icon} />
            </li>
          )
        })}
      </ul>
      <div className="menu-footer">
        <Icon viewBox="0 0 321 93" icon={'logo'} />
        <div className="menu-footer__build">v. 0.0.1 build 010</div>
      </div>
    </div>
  )
}
