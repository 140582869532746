/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { v4 as uuidv4 } from 'uuid'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useAuth, useModal } from '@/core/context'
import { useApi, useTokenReset } from '@/core/hooks'
import {
  EEndpoint,
  ETokensApiPathType,
  IAssignTokenPayload,
  IDeleteTokenPayload,
  IDeprecateTokenPayload,
  IStorageTokensNextQueryParams,
  IStorageTokensResponse,
  IToken,
} from '@/core/api'

import { Button, Icon } from '@/components/ui'
import { ConfirmationModal } from '@/components/smart'

import { TanstackTable } from './TanstackTable'

import './style.scss'

function filterUniqueTokens(array: IStorageTokensNextQueryParams[]) {
  const uniqueTokens = new Set()
  return array.filter((obj) => {
    if (!uniqueTokens.has(obj!.tokenId)) {
      uniqueTokens.add(obj!.tokenId)
      return true
    }
    return false
  })
}

export const TokensList = () => {
  const { user }: any = useAuth()
  const { apiRequest } = useApi()
  const { setModal } = useModal()
  const navigate = useNavigate()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { resetToken, resettingToken } = useTokenReset()

  const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({})
  const [modifiedSelection, setModifiedSelection] = useState<IToken[]>([])
  const [tokensData, setTokensData] = useState<IToken[] | null>([])
  const [isLoading, setLoading] = useState<boolean>(false)

  const [limit] = useState<number>(10)
  const [total, setTotal] = useState<number | null>(null)
  const [page, setPage] = useState<number>(1)
  const [next, setNext] = useState<IStorageTokensNextQueryParams[]>([])

  const getTokenStorageDataQuery = async (params?: any) => {
    try {
      setLoading(true)
      const response = (await apiRequest({
        endpoint: EEndpoint.tokens,
        path: ETokensApiPathType.storageTokens,
        params: { ...params },
        options: {
          headers: {
            'transaction-id': uuidv4(),
            Authorization: `${user.TokenType} ${user.AccessToken}`,
          },
          method: 'GET',
        },
      })) as IStorageTokensResponse

      if (response) {
        const { results, total } = response
        const { items } = results

        setNext((prev) => {
          const inputArray = [
            ...prev,
            {
              accountId: items[items.length - 1].accountId,
              accountIssuer: items[items.length - 1].accountIssuer,
              date: items[items.length - 1].date,
              tokenId: items[items.length - 1].tokenId,
            },
          ]

          const filteredArray = filterUniqueTokens(inputArray)

          return [...filteredArray]
        })

        setTotal(total)
        setTokensData([...results.items])
      }
    } catch (error: any) {
      if (error.message.match(/403|User is not authorized/gm)) resetToken()
      else setTokensData(null)
    } finally {
      setLoading(false)
    }
  }

  const createTokenHandler = () => {
    navigate('/dashboard/tokens/list/create')
  }

  const deprecateTokenQuery = async () => {
    const data: IDeprecateTokenPayload[] = modifiedSelection.map((item) => ({
      tokenId: item.tokenId,
    }))
    for (let index = 0; index < data.length; index++) {
      const element = data[index]
      try {
        const response = await apiRequest({
          endpoint: EEndpoint.tokens,
          path: ETokensApiPathType.deprecateToken,
          options: {
            headers: {
              'transaction-id': uuidv4(),
              Authorization: `${user.TokenType} ${user.AccessToken}`,
            },
            method: 'POST',
            body: JSON.stringify(element),
          },
        })
        console.log(response)
      } catch (error: any) {
        if (error.message.match(/403|User is not authorized/gm)) resetToken()
        else console.log(error.message)
      }
    }
    await getTokenStorageDataQuery()
    setRowSelection({})
  }
  const deleteTokenHandler = async () => {
    const data: IDeleteTokenPayload[] = modifiedSelection.map((item) => ({
      tokenId: item.tokenId,
    }))

    for (let index = 0; index < data.length; index++) {
      const element = data[index]
      try {
        const { tokenId } = element
        const response = await apiRequest({
          endpoint: EEndpoint.tokens,
          path: `${ETokensApiPathType.delete}/Token/${tokenId}`,
          options: {
            headers: {
              'transaction-id': uuidv4(),
              Authorization: `${user.TokenType} ${user.AccessToken}`,
            },
            method: 'DELETE',
          },
        })
        console.log(response)
      } catch (error: any) {
        if (error.message.match(/403|User is not authorized/gm)) resetToken()
        else console.log(error.message)
      }
    }

    await getTokenStorageDataQuery()
    setRowSelection({})
  }
  const assignTokenQuery = async () => {
    const data: IAssignTokenPayload[] = modifiedSelection.map((item) => ({
      // fromAccount: item.accountIssuer,
      assignAccountId: item.accountId,
      requestTokenId: item.tokenId,
    }))

    for (let index = 0; index < data.length; index++) {
      const element = data[index]
      try {
        const response = await apiRequest({
          endpoint: EEndpoint.tokens,
          path: ETokensApiPathType.assignToken,
          options: {
            headers: {
              'transaction-id': uuidv4(),
              Authorization: `${user.TokenType} ${user.AccessToken}`,
            },
            method: 'POST',
            body: JSON.stringify(element),
          },
        })
        console.log(response)
      } catch (error: any) {
        if (error.message.match(/403|User is not authorized/gm)) resetToken()
        else console.log(error.message)
      }
    }

    await getTokenStorageDataQuery()
    setRowSelection({})
  }

  useEffect(() => {
    getTokenStorageDataQuery()
  }, [])

  useEffect(() => {
    setModifiedSelection(
      Object.keys(rowSelection).map((item) => tokensData![parseInt(item)]),
    )
  }, [rowSelection])

  // useEffect(() => {
  //   console.log(resettingToken)
  //   getTokenStorageDataQuery(ETokenStorageListType.listing)
  // }, [resettingToken])

  const previousPage = async () => {
    if (page === 2) {
      await getTokenStorageDataQuery()
    } else {
      const payload = {
        lastTokenId: next[page - 3].tokenId,
        date: next[page - 3].date,
        tokenAccountId: next[page - 3].accountId,
      }

      await getTokenStorageDataQuery(payload)
    }

    setPage(page - 1)
  }

  const nextPage = async () => {
    const payload = {
      lastTokenId: next[page - 1].tokenId,
      date: next[page - 1].date,
      tokenAccountId: next[page - 1].accountId,
    }

    await getTokenStorageDataQuery(payload)

    setPage(page + 1)
  }

  return (
    <div className="tokens-list">
      <div className="tokens-list__title">Tokens List</div>
      <div className="tokens-list-controls">
        <Button onClick={createTokenHandler}>+</Button>
        <Button
          onClick={() =>
            setModal(
              <ConfirmationModal
                title={'Are you sure you wanna mark this token as deprecate?'}
                description={'This token will be deprecate'}
                actionHandler={deprecateTokenQuery}
              />,
            )
          }
          disabled={!Object.keys(rowSelection).length}
        >
          Deprecate
        </Button>
        <Button
          onClick={() =>
            setModal(
              <ConfirmationModal
                title={'Are you sure you wanna delete this token?'}
                description={'This token will be delete'}
                actionHandler={deleteTokenHandler}
              />,
            )
          }
          disabled={!Object.keys(rowSelection).length}
        >
          Delete
        </Button>
        <Button
          onClick={() => assignTokenQuery()}
          disabled={!Object.keys(rowSelection).length}
        >
          Assign
        </Button>
      </div>

      <TanstackTable
        isLoading={isLoading}
        tableData={tokensData}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
      />

      {total && (
        <div className="table-pagination">
          <div className="table-pagination-info">
            Showing {page * limit - limit + 1} to{' '}
            {page * limit <= total ? page * limit : total} of {total} entries
          </div>
          <div>
            <button
              className="table-pagination-btn__prev"
              onClick={previousPage}
              disabled={page === 1}
            >
              <Icon icon={'arrowBack'} viewBox="0 0 24 24" size="12px" />
            </button>
            <div className="table-pagination-info">
              {page} of {Math.ceil(total / limit)}
            </div>
            <button
              className="table-pagination-btn__next"
              onClick={nextPage}
              disabled={page === Math.ceil(total / limit)}
            >
              <Icon icon={'arrowForward'} viewBox="0 0 24 24" size="12px" />
            </button>
          </div>
        </div>
      )}
    </div>
  )
}
