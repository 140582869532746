/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createContext, SetStateAction, useContext, useState } from 'react'

// @ts-ignore
const CreateServiceContext = createContext<{
  setCreationStep: SetStateAction<any>
  setCreateServiceData: SetStateAction<any>
  creationStep: number
  createServiceData: any
}>()

const CreateServiceProvider = (props: any) => {
  const [createServiceData, setCreateServiceData] = useState<any>({})
  const [creationStep, setCreationStep] = useState<number>(1)

  return (
    <CreateServiceContext.Provider
      value={{
        creationStep,
        createServiceData,
        setCreationStep,
        setCreateServiceData,
      }}
      {...props}
    >
      {props.children}
    </CreateServiceContext.Provider>
  )
}

const useCreateService = () => {
  const context = useContext(CreateServiceContext)
  if (context === undefined) {
    throw new Error('useSubmitForm must be used within a UserProvider')
  }

  return context
}

export { CreateServiceProvider, useCreateService }
