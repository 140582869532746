enum EAnalyticsApiPathType {
  analytics = '',
  generalTokens = 'general/tokens',
  generalTransactions = 'general/transactions',
  generalServices = 'general/services',
}

enum EAnalyticsResponseStatName {
  totalTokens = 'TOTAL_NUMBER_OF_TOKENS',
  totalTransactions = 'TOTAL_NUMBER_OF_TRANSACTIONS',
  totalAccounts = 'TOTAL_NUMBER_OF_ACCOUNTS',

  tokensByLastDay = 'NUMBER_OF_TOKEN_LAST_DAY',
  tokensByLastWeek = 'TOTAL_TOKENS_LAST_WEEK',
  transactionsByLastDay = 'TOTAL_TRANSACTIONS_LAST_DAY',
  transactionsByLastWeek = 'TOTAL_TRANSACTIONS_LAST_WEEK',
}

export interface IAnalyticsResponse {
  statsName: EAnalyticsResponseStatName
  count: number
}
export interface IAnalyticsGeneralTokensResponse {
  TAX_ID_LIST: number
  BANK_ACCOUNT_LIST: number
  SECRET: number
  POLICY: number
}

interface IAnalyticsGeneralTransactionsResponse {
  dailyStats: { date: string | Date; count: number }[]
  weeklyStats: { date: string | Date; count: number }[]
  monthlyStats: { date: string | Date; count: number }[]
}

interface IAnalyticsGeneralServicesResponse {
  serviceId: string
  serviceName: string
  desc: string
  stats: {
    totalNumberOfRequest: number
    failedRequests: number
    succsesRequests: number
  }
}

export type TAnalyticsGeneralServicesResponse =
  IAnalyticsGeneralServicesResponse
export type TAnalyticsGeneralTokensResponse = IAnalyticsGeneralTokensResponse
export type TAnalyticsGeneralTransactionsResponse =
  IAnalyticsGeneralTransactionsResponse
export { EAnalyticsApiPathType, EAnalyticsResponseStatName }
