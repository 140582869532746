import { FC } from 'react'
import './style.scss'

interface LoadingProps {
  color?: string
}

export const Loading: FC<LoadingProps> = ({ color = 'currentColor' }) => {
  return (
    <span className="loading" style={{ color }}>
      <span className="loading__dot"></span>
      <span className="loading__dot"></span>
      <span className="loading__dot"></span>
    </span>
  )
}
