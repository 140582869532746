/* eslint-disable @typescript-eslint/no-explicit-any */
import { v4 as uuidv4 } from 'uuid'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import cn from 'classnames'

import { useAuth, useModal } from '@/core/context'
import { useApi, useTokenReset } from '@/core/hooks'
import { EEndpoint, EUserApiPathType, IRole, IUser } from '@/core/api'

import { Button, EButtonType, Input } from '@/components/ui'
import { DataLoading } from '@/components/smart'

import { MultiSelect } from '../../MultiSelect'

import './style.scss'

interface IAssignUserRoleProps {
  user: IUser
  roles: IRole[]
  setUsersChange: Dispatch<SetStateAction<boolean>>
}

export const AssignUserRole = ({
  user: userData,
  roles,
  setUsersChange,
}: IAssignUserRoleProps) => {
  const { user }: any = useAuth()
  const { apiRequest } = useApi()
  const { resetToken, resettingToken } = useTokenReset()
  const { setModal } = useModal()

  const [isLoading, setLoading] = useState<boolean>(false)
  const [userRoles, setUserRoles] = useState<string[]>([])
  const [originalUserRoles, setOriginalUserRoles] = useState<string[]>([])

  const getUserRoles = async () => {
    try {
      setLoading(true)
      const response = await apiRequest({
        endpoint: EEndpoint.user,
        path: `${EUserApiPathType.roles}/${userData.sub}`,
        options: {
          headers: {
            'transaction-id': uuidv4(),
            Authorization: `${user.TokenType} ${user.AccessToken}`,
          },
          method: 'GET',
        },
      })
      if (response) {
        const { roles: rolesResponse } = response
        console.log(rolesResponse)
        setOriginalUserRoles(rolesResponse)
        setUserRoles(rolesResponse)
      }
    } catch (error: any) {
      if (error.message.match(/403|User is not authorized/gm)) resetToken()
      else console.log(error.message)
    } finally {
      setLoading(false)
    }
  }

  const saveChangeHandler = async () => {
    console.log(`originalRoles: ${originalUserRoles}`)
    console.log(`modifiedRoles: ${userRoles}`)
    const compareRoles = (originalRoles: string[], modifiedRoles: string[]) => {
      // const changes: IRoleChange[] = []

      const addArray = modifiedRoles
        .filter((item) => !originalRoles.includes(item))
        .map((item) => ({
          roleName: item,
          userId: userData.sub,
          operation: 'ADD',
        }))

      const removeArray = originalRoles
        .filter((item) => !modifiedRoles.includes(item))
        .map((item) => ({
          roleName: item,
          userId: userData.sub,
          operation: 'REMOVE',
        }))

      console.log('addArray: ', addArray)
      console.log('removeArray: ', removeArray)
      return addArray.concat(removeArray)
    }

    const roleChanges = compareRoles(originalUserRoles, userRoles)
    console.log(roleChanges)

    for (let index = 0; index < roleChanges.length; index++) {
      const element = roleChanges[index]
      try {
        const response = await apiRequest({
          endpoint: EEndpoint.user,
          path: EUserApiPathType.assignRole,
          options: {
            headers: {
              'transaction-id': uuidv4(),
              Authorization: `${user.TokenType} ${user.AccessToken}`,
            },
            method: 'POST',
            body: JSON.stringify(element),
          },
        })
        console.log(`response ${index}: ${response}`)
      } catch (error: any) {
        if (error.message.match(/403|User is not authorized/gm)) resetToken()
        else console.log(error.message)
      }
    }

    setUsersChange(true)
    setModal(null)
  }

  useEffect(() => {
    getUserRoles()
  }, [resettingToken])

  return (
    <div className="assign-user-role">
      <div className="title">{userData.Username}</div>

      <div
        className={cn('form-wrapper', {
          // 'form-wrapper--loading': !accountId && isLoading,
          'form-wrapper--loading': isLoading,
        })}
      >
        <DataLoading
          // isLoading={!accountId && isLoading}
          isLoading={isLoading}
          text={'Get user roles...'}
        />

        <form>
          <fieldset>
            <ul className="user-role-list">
              <li className="user-role-list__item">
                <label>Account ID</label>
                <Input
                  type="text"
                  name="accountId"
                  value={userData.sub}
                  readOnly={true}
                />
              </li>

              <li>
                <label>Roles Assigned</label>

                <MultiSelect
                  listData={userRoles}
                  setListData={setUserRoles}
                  data={roles.map((item) => item.roleName)}
                />
              </li>
            </ul>
          </fieldset>
        </form>

        <Button type={EButtonType.submit} onClick={saveChangeHandler}>
          Save Changes
        </Button>
      </div>
    </div>
  )
}
