/* eslint-disable @typescript-eslint/no-explicit-any */
import { Navigate, useOutlet } from 'react-router-dom'
import { useAuth } from '@/core/context'

export const HomeLayout = () => {
  const { user }: any = useAuth()
  const outlet = useOutlet()

  if (user) {
    return <Navigate to="/dashboard/overview" />
  }

  return <>{outlet}</>
}
