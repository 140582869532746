/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useState } from 'react'
import { ErrorCode, useDropzone } from 'react-dropzone'
import { v4 as uuidv4 } from 'uuid'
import axios, { AxiosResponse } from 'axios'

import { useTokenReset } from '@/core/hooks'
import { useAuth, useCreateToken } from '@/core/context'
import {
  EEndpoint,
  ETokenStatus,
  ETokensApiPathType,
  IToken,
  apiEndpoints,
} from '@/core/api'

import { Button, EButtonType, Icon } from '@/components/ui'

import './style.scss'

enum EFileUploadStatus {
  init = 'Init',
  done = 'Done',
  error = 'Error',
}

interface IProgressRenderProps {
  status: EFileUploadStatus
  className: string
}

export const UploadFile = () => {
  const { user }: any = useAuth()
  const { resetToken } = useTokenReset()
  const { setCreationStep, setCreateTokenData, creationStep, createTokenData } =
    useCreateToken()

  const [file, setFile] = useState<File | null>(null)
  const [uploadFileStatus, setUploadingStatus] =
    useState<EFileUploadStatus | null>(null)
  const [progress, setProgress] = useState(0)

  const maxFileSizeValidator = (file: File) => {
    if (file.size > 1048576) {
      console.log({
        code: ErrorCode.FileTooLarge,
        message: 'File size is larger than 1Mb',
      })
      return {
        code: 'file-too-large',
        message: 'File size is larger than 1Mb',
      }
    }

    return null
  }
  const formatBytes = (bytes: number, decimals = 2) => {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = [
      'Bytes',
      'KiB',
      'MiB',
      'GiB',
      'TiB',
      'PiB',
      'EiB',
      'ZiB',
      'YiB',
    ]

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }
  const reduceFileName = (originalFileName: string): string => {
    const parts = originalFileName.split('.')
    const extension = `...${parts[parts.length - 1]}`
    const fileNameWithoutExtension = parts.slice(0, -1).join('.')

    const truncatedFileName =
      fileNameWithoutExtension.length > 3
        ? fileNameWithoutExtension.slice(0, 3)
        : fileNameWithoutExtension

    return truncatedFileName + extension
  }

  const ProgressRender = ({ status, className }: IProgressRenderProps) => {
    useEffect(() => {
      console.log('uploadFileStatus: ', status)
    }, [status])
    return (
      <div className={`progressbar progressbar--${className}`}>
        <Icon
          className={`progressbar__icon progressbar__icon--${status.toLowerCase()}`}
          icon={`progress${status}`}
        />
        <div className="progressbar-content">
          {file && (
            <span>
              {reduceFileName(file.name)} {formatBytes(file.size)}
            </span>
          )}
          <div className="progress">
            <progress max="100" value={progress}></progress>
            <div className="progress-value"></div>
            <div className="progress-bg">
              <div className="progress-bar"></div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  async function convertFileToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()

      reader.onload = function (e) {
        if (e.target) {
          const base64File = (e.target as FileReader).result
            ?.toString()
            .split(',')[1]
          console.log(base64File)
          resolve(base64File ? base64File : '')
        } else {
          reject(new Error('Failed to read the file.'))
        }
      }

      reader.onerror = function (error) {
        reject(error)
      }

      reader.readAsDataURL(file)
    })
  }

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const reader = new FileReader()

    reader.addEventListener('progress', (event) => {
      setUploadingStatus(EFileUploadStatus.init)

      const percent = Math.round((event.loaded / event.total) * 100)
      const loadingBar = Array(10)
        .fill('▒')
        .map((_, index) => (Math.round(percent / 10) > index ? '█' : '▒'))
        .join('')

      console.log(`${loadingBar}(${percent}%)`)

      setProgress(percent)

      if (percent === 100) {
        setUploadingStatus(EFileUploadStatus.done)
      }
    })
    reader.readAsText(acceptedFiles[0])
  }, [])

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    accept: {
      'application/json': ['.json'],
      'text/plain': ['.txt'],
    },
    maxFiles: 1,

    validator: maxFileSizeValidator,
  })

  const uploadFileQuery = async () => {
    if (file) {
      try {
        const base64String = await convertFileToBase64(file)

        const bodyData = {
          type: createTokenData.type,
          file: {
            filename: file!.name,
            data: base64String,
            description: createTokenData.description,
          },
          TTL: '111111',
        }

        try {
          const response: AxiosResponse = (await axios.post(
            `${apiEndpoints
              .find((item) => item.key === EEndpoint.tokens)
              ?.getUrl()}/${ETokensApiPathType.uploadContent}`,
            { ...bodyData },
            {
              headers: {
                'transaction-id': uuidv4(),
                Authorization: `${user.TokenType} ${user.AccessToken}`,
              },
            },
          )) as unknown as AxiosResponse

          const { tokenId }: { tokenId: string } = response.data

          if (tokenId) {
            setCreateTokenData((prev: IToken) => ({
              ...prev,
              tokenId,
              // tokenIdSource: '17037668267871312',
              date: Date.now(),
              accountIssuer: '46f39cfe-9cd2-4349-9498-25351258a850',
              accountId: 'e4c8a548-df7e-496c-9ba0-1d574dd09c98',
              status: ETokenStatus.MINTED,
              orgId: 'b0033e12-431b-4c36-8842-8f507dcaeb57',
              TTL: 11111111,
            }))
            setCreationStep(creationStep + 1)
          }
        } catch (error: any) {
          if (axios.isAxiosError(error)) {
            console.error('Axios error:', error.message)
            if (error.message.match(/403|User is not authorized/gm))
              await resetToken()
          } else {
            console.error('General error:', error.message)
          }
        }
      } catch (error) {
        console.error('Error converting file to Base64:', error)
      }
    }
  }

  useEffect(() => {
    setFile(acceptedFiles.length ? acceptedFiles[0] : null)
    setProgress(0)
    setUploadingStatus(null)
  }, [acceptedFiles])

  return (
    <>
      <div className="token-create-data-container-header">
        <div className="token-create-data-container-header__title">
          Upload file
        </div>
        <div className="token-create-data-container-header__desc">
          to create new token
        </div>
      </div>

      <div className="token-create-data-container-main">
        <div className="token-create-step token-create-step--upload">
          <div {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} id="fileUploadRef" />
            <Icon
              className="dropzone__icon"
              icon={'dropzoneDownload'}
              viewBox="0 0 84 54"
            />
            <div className="dropzone__title">Drag file to upload</div>
            <div className="dropzone__or">or</div>
            <Button type={EButtonType.button} onClick={open}>
              Browse File
            </Button>
          </div>
          {uploadFileStatus && (
            <ProgressRender
              status={uploadFileStatus}
              className={uploadFileStatus.toLowerCase()}
            />
          )}
        </div>
      </div>

      <div className="token-create-data-container-footer">
        <Button
          onClick={uploadFileQuery}
          disabled={uploadFileStatus !== EFileUploadStatus.done}
        >
          Next
        </Button>
      </div>
    </>
  )
}
