import { Icon } from '@/components/ui'
import './style.scss'

interface IDataLoadingProps {
  isLoading: boolean
  icon?: JSX.Element
  text?: JSX.Element | string
}

export const DataLoading = ({
  isLoading,
  icon = (
    <>
      <Icon viewBox="0 0 24 24" icon={'cached'} size="90px" />
    </>
  ),
  text = 'Data Loading',
}: IDataLoadingProps) => {
  return (
    <>
      {isLoading && (
        <div className="is-data-loading">
          {icon}
          <span>{text}</span>
        </div>
      )}
    </>
  )
}
